import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from '../../../contexts/Auth';

import { formatSchedule } from '../../../utils/functions';
import { constants } from '../../../utils/constants';
import StorageService from '../../../utils/storage';
import GeneralApi from '../../../utils/generalApi';

import LoadingSpinner from '../../../components/LoadingSpinner';
import SolidButton from '../../../components/SolidButton';
import { showErrorToast } from '../../../components/Toast';
import InfoLine from '../../../components/InfoLine';

import pagoEfectivoImg from '../../../resources/img/logos/pago_efectivo.png';
import cardsImg from '../../../resources/img/logos/cards.png';
import bcpImg from '../../../resources/img/logos/bcp.png';
import bbvaImg from '../../../resources/img/logos/bbva.png';
import interbankImg from '../../../resources/img/logos/interbank.png';
import yapeImg from '../../../resources/img/logos/yape.png';

export default function PendingPayment() {
  const history = useHistory();
  const auth = useAuth();

  let { courseId } = useParams();

  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [amount, setAmount] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getCourseData = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/course/enroll_cost`, {
      course_id: courseId,
      student_id: StorageService.get('chosenStudent'),
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/report`);
      return;
    }
    if (result.data.amount <= 0) {
      history.replace(`/my_courses/${courseId}/report`);
    }
    setAmount(result.data.enrollCost);
    setPaymentAmount(result.data.paymentsCost);
    setCourse(result.data.course);
    setLoading(false);
  };

  return (
    <div className="mx-4 my-8 gap-6 rounded-2xl bg-white md:mx-16 md:my-12 xl:flex">
      <div className="max-w-4xl flex-1 xl:py-10 xl:pl-12">
        {course && (
          <div className="rounded-2xl border border-gray-400 bg-white p-8">
            {course?.school?.name && (
              <div className="mb-8 flex items-center gap-4">
                <img
                  src={constants.SCHOOLS_FORMAT[course.school.name].logo}
                  className="h-16 bg-no-repeat sm:h-16"
                  alt="Logo"
                />
                <p className="text-3xl font-bold text-primary-light">
                  {course?.classroom?.name || '-'}
                </p>
              </div>
            )}
            <div className="flex flex-col justify-around md:hidden">
              <InfoLine
                gap="4"
                fields={[
                  {
                    name: 'Matrícula',
                    value: `S/ ${amount}`,
                  },
                  {
                    name: 'Derecho enseñanza',
                    value: `S/ ${paymentAmount}`,
                  },
                  {
                    name: 'Turno',
                    value: course.classroom_turn
                      ? constants.TURNS[course.classroom_turn]
                      : course.classroom_number?.name || '-',
                  },
                  {
                    name: 'Horario',
                    value: formatSchedule(course?.schedule?.name) || '-',
                  },
                  {
                    name: 'Aula',
                    value: course?.classroom_number?.name || '-',
                  },
                  {
                    name: 'Vacantes',
                    value: course.vacants
                      ? `${course.vacants - course.students.length} vacantes`
                      : '-',
                  },
                ]}
              />
            </div>

            <div className="hidden flex-row flex-wrap items-center justify-around gap-y-4 md:flex">
              <InfoLine
                gap="4"
                fields={[
                  {
                    name: 'Matrícula',
                    value: `S/ ${amount}`,
                  },
                  {
                    name: 'Derecho enseñanza',
                    value: `S/ ${paymentAmount}`,
                  },
                ]}
              />

              <div className="h-12 w-0.5 bg-gray-300" />

              <InfoLine
                gap="4"
                fields={[
                  {
                    name: 'Turno',
                    value: course.classroom_turn
                      ? constants.TURNS[course.classroom_turn]
                      : course.classroom_number?.name || '-',
                  },
                  {
                    name: 'Horario',
                    value: formatSchedule(course?.schedule?.name) || '-',
                  },
                ]}
              />

              <div className="h-12 w-0.5 bg-gray-300" />

              <InfoLine
                gap="4"
                fields={[
                  {
                    name: 'Aula',
                    value: course?.classroom_number?.name || '-',
                  },
                  {
                    name: 'Vacantes',
                    value: course.vacants
                      ? `${course.vacants - course.students.length} vacantes`
                      : '-',
                  },
                ]}
              />
            </div>
          </div>
        )}

        <div className="px-4 xl:pr-0 xl:pl-8">
          <p className="mt-12 text-lg font-bold leading-5">FORMAS DE PAGO</p>
          <div className="py-6">
            <p className="text-lg font-bold">Tarjetas de crédito y débito</p>
            <img
              className="mt-2 block w-auto sm:h-16"
              src={cardsImg}
              alt="Tarjetas de Crédito"
            />
          </div>
          <div className="h-px w-full bg-gray-300" />
          <div className="py-6">
            <p className="text-lg font-bold">
              Pago en Agencias y Banca por Internet
            </p>
            <div className="flex flex-col gap-6 md:flex-row">
              <div className="md:w-1/2">
                <div className="mt-2 items-center gap-x-4 gap-y-4 sm:flex sm:gap-y-0">
                  <img
                    className="block h-16 w-auto"
                    src={bcpImg}
                    alt="Tarjetas de Crédito"
                  />
                  <img
                    className="block h-12 w-auto"
                    src={pagoEfectivoImg}
                    alt="Tarjetas de Crédito"
                  />
                </div>
                <p className="mt-4 font-bold">Cuenta corriente BCP:</p>
                <p>191 2664764 006</p>

                <p className="mt-4 font-bold">
                  Cuenta corriente interbancaria BCP:
                </p>
                <p>00219100 266476400654</p>

                <div className="mt-6 flex items-center gap-4">
                  <img className="block h-14 w-auto" src={yapeImg} alt="Yape" />
                  <p className="mt-1">992 7613 37</p>
                </div>
              </div>

              <div>
                <img
                  className="block h-16 w-auto"
                  src={interbankImg}
                  alt="Interbank"
                />
                <p className="mt-4 font-bold">Cuenta corriente Interbank:</p>
                <p>200 3004176535</p>

                <img
                  className="mt-10 block h-10 w-auto"
                  src={bbvaImg}
                  alt="BBVA"
                />
                <p className="mt-4 font-bold">Cuenta corriente BBVA:</p>
                <p>0011 0342 0100035065</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 h-fit rounded-2xl bg-primary p-8 xl:mr-12 xl:mt-10 xl:w-1/3 xl:px-12">
        <p className="text-2xl font-medium text-white">Resumen</p>

        <div className="mt-8 mb-4 flex justify-between">
          <p className="text-xl font-medium text-white">Subtotal</p>
          <p className="text-xl text-white">S/ {amount + paymentAmount}</p>
        </div>

        <div className="h-px w-full bg-gray-300" />

        <div className="mt-4 flex justify-between">
          <p className="text-xl font-medium text-white">Total</p>
          <p className="text-xl text-white">S/ {amount + paymentAmount}</p>
        </div>

        <div className="mt-8 w-full">
          {loading || amount <= 0 ? (
            <LoadingSpinner />
          ) : (
            <SolidButton
              color="golden"
              textColor="primary"
              href={`/shopping_cart/${courseId}`}
            >
              CONTINUAR
            </SolidButton>
          )}
        </div>
      </div>
    </div>
  );
}
