import { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';

import { validateFile } from '../../../utils/fileUtils';

import OutlineButton from '../../../components/OutlineButton';
import ErrorMessage from '../../../components/ErrorMessage';
import SolidButton from '../../../components/SolidButton';
import { showErrorToast } from '../../../components/Toast';

export default function StudentForm({
  index,
  amount,
  register,
  getValues,
  setValue,
  errors,
  onRemove,
}) {
  const [imagePreview, setImagePreview] = useState(null);

  let checkImage = async (event) => {
    let isValid = validateFile(event.target, 'img');
    if (!isValid) {
      setImagePreview(null);
      showErrorToast(
        'Formato de imagen no válido, debe ser un archivo .png, .jpg o .jpeg'
      );
      return;
    }
    setValue(`students.${index}.image`, event.target.files);
    await loadPreviewImage(event.target.files[0]);
  };

  const loadPreviewImage = async (data) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    await reader.readAsDataURL(data);
  };

  useEffect(() => {
    const image = getValues(`students.${index}.image`);
    if (image?.length > 0) loadPreviewImage(image[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mb-6 gap-4 space-y-2 rounded-xl border border-gray-200 p-6 sm:grid sm:grid-cols-4 sm:space-y-0 lg:grid-cols-6">
      <div className="col-span-2 lg:col-span-2">
        <label htmlFor={`inp_student_doc_type_${index}`}>
          Tipo de documento
        </label>
        <div className="mt-1">
          <select
            id={`inp_student_doc_type_${index}`}
            name={`student_doc_type_${index}`}
            placeholder="Tipo de documento"
            className="inp-base"
            {...register(`students.${index}.docType`, {
              required: true,
            })}
          >
            <option value="">Elegir tipo de documento</option>
            <option>DNI</option>
            <option>Pasaporte</option>
            <option>Carnet de extranjería</option>
            <option>RUC</option>
          </select>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.docType`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_doc_number_${index}`}>
          Número de documento
        </label>
        <div className="mt-1">
          <input
            id={`inp_student_doc_number_${index}`}
            name={`student_doc_number_${index}`}
            type="text"
            placeholder="Número de documento"
            className="inp-base"
            {...register(`students.${index}.docNumber`, {
              required: true,
            })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.docNumber`} />
      </div>
      <div className="col-span-2 flex items-start justify-end">
        {amount > 1 && (
          <SolidButton
            className="flex max-w-xs items-center justify-center px-6"
            widthFull={false}
            size="sm"
            color="red"
            onClick={() => onRemove()}
          >
            Borrar
            <TrashIcon className="ml-2 h-6 w-6 text-white" aria-hidden="true" />
          </SolidButton>
        )}
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_last_name_p_${index}`}>
          Apellido Paterno
        </label>
        <div className="mt-1">
          <input
            id={`inp_student_last_name_p_${index}`}
            name={`student_last_name_p_${index}`}
            type="text"
            placeholder="Apellido Paterno"
            className="inp-base"
            {...register(`students.${index}.lastNameP`, {
              required: true,
            })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.lastNameP`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_last_name_m_${index}`}>
          Apellido Materno
        </label>
        <div className="mt-1">
          <input
            id={`inp_student_last_name_m_${index}`}
            name={`student_last_name_m_${index}`}
            type="text"
            placeholder="Apellido Materno"
            className="inp-base"
            {...register(`students.${index}.lastNameM`, {
              required: true,
            })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.lastNameM`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_name_${index}`}>Nombres</label>
        <div className="mt-1">
          <input
            id={`inp_student_name_${index}`}
            name={`student_name_${index}`}
            type="text"
            placeholder="Nombres"
            className="inp-base"
            {...register(`students.${index}.name`, { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.name`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_relation_${index}`}>Sexo</label>
        <div className="mt-1">
          <select
            id={`inp_student_relation_${index}`}
            name={`student_relation_${index}`}
            placeholder="Sexo"
            className="inp-base"
            {...register(`students.${index}.genre`, { required: true })}
          >
            <option value="">Elegir sexo</option>
            <option>Masculino</option>
            <option>Femenino</option>
          </select>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.genre`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_age_${index}`}>Edad</label>
        <div className="mt-1">
          <input
            id={`inp_student_age_${index}`}
            name={`student_age_${index}`}
            type="number"
            placeholder="Edad"
            className="inp-base"
            {...register(`students.${index}.age`, { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.age`} />
      </div>
      <div className="col-span-2"></div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_level_${index}`}>Nivel</label>
        <div className="mt-1">
          <select
            id={`inp_student_level_${index}`}
            name={`student_level_${index}`}
            className="inp-base"
            {...register(`students.${index}.level`, { required: true })}
          >
            <option value="">Elegir nivel</option>
            <option>I</option>
            <option>II</option>
            <option>III</option>
          </select>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.level`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_grade_${index}`}>Grado/Año</label>
        <div className="mt-1">
          <select
            id={`inp_student_grade_${index}`}
            name={`student_grade_${index}`}
            className="inp-base"
            {...register(`students.${index}.grade`, { required: true })}
          >
            <option value="">Elegir grado/año</option>
            <option>1° Primaria</option>
            <option>2° Primaria</option>
            <option>3° Primaria</option>
            <option>4° Primaria</option>
            <option>5° Primaria</option>
            <option>6° Primaria</option>
            <option>1° Secundaria</option>
            <option>2° Secundaria</option>
            <option>3° Secundaria</option>
            <option>4° Secundaria</option>
            <option>5° Secundaria</option>
          </select>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.grade`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_section_${index}`}>Sección</label>
        <div className="mt-1">
          <input
            id={`inp_student_section_${index}`}
            name={`student_section_${index}`}
            type="text"
            placeholder="Sección"
            className="inp-base"
            {...register(`students.${index}.section`)}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.section`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_email_${index}`}>Email</label>
        <div className="mt-1">
          <input
            id={`inp_student_email_${index}`}
            name={`student_email_${index}`}
            type="email"
            placeholder="Email"
            className="inp-base"
            {...register(`students.${index}.email`)}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.email`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_phone_${index}`}>Teléfono fijo</label>
        <div className="mt-1">
          <input
            id={`inp_student_phone_${index}`}
            name={`student_phone_${index}`}
            type="text"
            placeholder="Teléfono fijo"
            className="inp-base"
            {...register(`students.${index}.phone`)}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.phone`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_student_cellphone_${index}`}>Celular</label>
        <div className="mt-1">
          <input
            id={`inp_student_cellphone_${index}`}
            name={`student_cellphone_${index}`}
            type="number"
            placeholder="Celular"
            className="inp-base"
            {...register(`students.${index}.cellphone`)}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`students.${index}.cellphone`} />
      </div>

      <div className="flex flex-col items-center space-y-2 sm:col-span-6">
        <div className="flex max-w-sm items-center">
          <OutlineButton
            color="light-blue"
            htmlFor={`inp_student_image_${index}`}
            className="cursor-pointer"
          >
            <svg
              className="text-blue mr-2 h-7 w-7 group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            {imagePreview ? 'CAMBIAR IMAGEN DE DNI' : 'SUBIR IMAGEN DE DNI'}
          </OutlineButton>
          <input
            id={`inp_student_image_${index}`}
            className="hidden"
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={(e) => {
              checkImage(e);
            }}
          />
        </div>
        {imagePreview ? (
          <img
            src={imagePreview}
            className="h-24 max-w-full rounded-lg border-2 border-light-blue sm:h-36 xl:max-w-lg"
            alt="DNI del alumno"
          ></img>
        ) : null}
        <ErrorMessage errors={errors} field={`students.${index}.image`} />
      </div>
    </div>
  );
}
