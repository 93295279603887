import { Route, Redirect, Link } from 'react-router-dom';
import StorageService from '../../utils/storage';

import { useAuth } from '../../contexts/Auth';

import Footer from '../Footer';
import logo from '../../resources/img/logo.png';

export default function PublicOnlyRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.isAuthenticated ? (
          <div className="font-gotham">
            <header className="bg-white shadow-sm lg:static lg:overflow-y-visible">
              <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
                <div className="relative flex justify-between">
                  <div className="flex md:inset-y-0 md:left-0 lg:static xl:col-span-2">
                    <div className="flex flex-shrink-0 items-center">
                      <Link to="/" className="flex items-center">
                        <img
                          className="block h-12 w-auto"
                          src={logo}
                          alt="Workflow"
                        ></img>
                        <span className="ml-4 text-3xl text-primary">
                          EDT.LAB
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {children}
            <Footer />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: StorageService.get('userData').redirect || '/profile',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
