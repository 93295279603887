import { Link } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

const sizeClasses = {
  sm: 'py-2 px-2',
  md: 'py-3 px-4',
};

export default function OutlineButton({
  children,
  className = '',
  isLoading = false,
  disabled = false,
  color = 'primary',
  textColor = color,
  altTextColor = 'white',
  size = 'md',
  type = 'button',
  href,
  htmlFor,
  onClick,
  style,
}) {
  return href ? (
    <Link
      to={disabled ? '' : href}
      className={`${className} ${sizeClasses[size]} ${
        disabled && 'pointer-events-none'
      } flex w-full justify-center rounded-xl border-2 text-xl font-bold shadow-sm text-${textColor} border-${color} hover:border-${color}-dark hover:bg-${color} focus:bg-${color} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-light focus:text-${altTextColor} hover:text-${altTextColor}`}
    >
      {children}
    </Link>
  ) : htmlFor ? (
    <label
      htmlFor={htmlFor}
      disabled={disabled || isLoading}
      className={`${className} ${sizeClasses[size]} ${
        disabled
          ? 'border-gray-500 text-gray-500'
          : `border-${color} hover:border-${color}-dark hover:bg-${color} text-${textColor} hover:text-${altTextColor}`
      } flex w-full justify-center rounded-xl border-2 text-xl font-bold shadow-sm focus:bg-${color} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-light focus:text-${altTextColor}`}
    >
      {isLoading ? <LoadingSpinner color={color} /> : children}
    </label>
  ) : (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`${className} ${sizeClasses[size]} ${
        disabled
          ? 'border-gray-500 text-gray-500'
          : `border-${color} hover:border-${color}-dark hover:bg-${color} text-${textColor} hover:text-${altTextColor}`
      } flex w-full justify-center rounded-xl border-2 text-xl font-bold shadow-sm focus:bg-${color} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-light focus:text-${altTextColor}`}
      style={style}
    >
      {isLoading ? <LoadingSpinner color={color} /> : children}
    </button>
  );
}
