import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../../contexts/Auth';
import Sidebar from '../../components/Sidebar';

export default function PrivateRoute({ children, title, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isAuthenticated ? (
          <Sidebar title={title}>{children}</Sidebar>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
