import { useHistory } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { checkFileType, validateFile } from '../../../utils/fileUtils';
import { formatDate } from '../../../utils/functions';

import ProfileIcon from '../../../resources/img/icons/profile@2x.png';
import OutlineButton from '../../../components/OutlineButton';
import { showErrorToast, showSuccessToast } from '../../../components/Toast';
import SolidButton from '../../../components/SolidButton';
import StorageService from '../../../utils/storage';
import { useAuth } from '../../../contexts/Auth';
import GeneralApi from '../../../utils/generalApi';

export default function HomeworkCard({
  openAtStart = false,
  courseId,
  homework,
  attachments: attachmentsList,
  onImageClick,
  onUpdate,
}) {
  const history = useHistory();
  const auth = useAuth();
  const { week, name, date, grade, teacher } = homework;
  const [isContentItemOpen, setIsContentItemOpen] = useState(openAtStart);
  const [attachments, setAttachments] = useState(attachmentsList);
  const [newFiles, setNewFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const imagesRef = useRef(null);

  const generalApi = new GeneralApi(auth, history);

  let checkImage = async (event) => {
    let isValid = validateFile(event.target, 'homework');
    if (!isValid) {
      showErrorToast(
        'Formato de archivo no válido, debe ser un archivo .png, .jpg, .jpeg o .pdf'
      );
      return;
    }
    var reader = new FileReader();
    reader.onload = (e) => {
      const splitted = event.target.files[0].name.split('.');
      const extension = splitted[splitted.length - 1];
      const repeatedAmount = newFiles.filter(
        (item) => item.name === event.target.files[0].name
      ).length;
      const finalName =
        repeatedAmount > 0
          ? `${event.target.files[0].name} (${repeatedAmount}).${extension}`
          : event.target.files[0].name;

      setNewFiles([
        ...newFiles,
        {
          file: event.target.files[0],
          key: finalName,
          name: event.target.files[0].name,
          preview: e.target.result,
        },
      ]);
      imagesRef.current.scrollLeft = imagesRef.current.scrollWidth;
    };
    await reader.readAsDataURL(event.target.files[0]);
  };

  const deleteFile = (key) => {
    const attachmentsIndex = attachments.findIndex(
      (item) => item.key === key || item._id === key
    );
    if (attachmentsIndex !== -1) {
      setDeletedFiles([...deletedFiles, attachments[attachmentsIndex]._id]);
      const attachmentsAux = [...attachments];
      attachmentsAux.splice(attachmentsIndex, 1);
      setAttachments(attachmentsAux);
    }

    const newFilesIndex = newFiles.findIndex(
      (item) => item.key === key || item._id === key
    );
    if (newFilesIndex !== -1) {
      const newFilesAux = [...newFiles];
      newFilesAux.splice(newFilesIndex, 1);
      setNewFiles(newFilesAux);
    }
  };

  let submitUpdate = async () => {
    console.log(deletedFiles.join(','));
    if (newFiles.length === 0 && deletedFiles.length === 0)
      return showErrorToast('No se han elegido archivos para subir o borrar');

    let updateData = new FormData();
    updateData.append('student_id', StorageService.get('chosenStudent'));
    updateData.append('course_id', courseId);
    updateData.append('week', week);
    updateData.append('deleted', deletedFiles.join(','));
    if (homework._id) updateData.append('homework_id', homework._id);

    newFiles.forEach((file) => {
      updateData.append('attachments', file.file);
    });

    setLoading(true);
    const result = await generalApi.put(`/classroom/homeworks`, updateData);
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    showSuccessToast('Cambios guardados con éxito');
    setLoading(false);
    setNewFiles([]);
    onUpdate?.();
  };

  const fullList = useMemo(() => {
    return [...attachments, ...newFiles];
  }, [attachments, newFiles]);

  useEffect(() => {
    setAttachments(attachmentsList);
  }, [attachmentsList]);

  return (
    <div className="mt-4 rounded-xl border border-gray-300">
      <div
        className={`${
          isContentItemOpen
            ? 'rounded-t-xl bg-primary text-white'
            : 'rounded-xl'
        } space-between flex items-center px-6 py-4 text-xl transition-all`}
      >
        <div className="flex-1 justify-between sm:flex sm:pr-8">
          <div className="flex items-center justify-between sm:gap-x-6">
            <dt className="text-lg font-normal">Semana {week}</dt>
            {name && name !== '-' && (
              <>
                <div
                  className={`${
                    isContentItemOpen ? 'bg-white' : 'bg-primary'
                  } h-full w-px`}
                />
                <dd className="mt-1 text-lg sm:mt-0">{name}</dd>
              </>
            )}
          </div>
          <div className="text-lg sm:mt-0">
            {formatDate(date, 'dd/MM/yyyy')}
          </div>
        </div>
        <div
          className="cursor-pointer justify-self-end rounded-full p-1 transition-colors hover:bg-gray-200 hover:text-light-blue sm:col-span-1"
          onClick={() => setIsContentItemOpen(!isContentItemOpen)}
        >
          <svg
            className="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {isContentItemOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 15l7-7 7 7"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            )}
          </svg>
        </div>
      </div>

      <Transition
        show={isContentItemOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="rounded-b-xl bg-white text-xl md:px-12"
      >
        {fullList && fullList.length > 0 && (
          <div className="px-1 pt-8 pb-2 md:px-6">
            <div
              ref={imagesRef}
              className="mt-2 flex gap-4 overflow-x-scroll pb-2"
              style={{
                scrollSnapAlign: 'start',
                scrollBehavior: 'smooth',
                WebkitOverflowScrolling: 'touch',
              }}
            >
              {fullList.map((obj, index) =>
                checkFileType(obj.name) === 'IMAGEN' ? (
                  <div
                    key={obj.key || obj._id}
                    className="group relative flex flex-shrink-0 flex-col items-center gap-1 p-1"
                    onClick={() => {
                      if (obj.url || obj.preview)
                        onImageClick?.(obj.url || obj.preview);
                    }}
                  >
                    <img
                      src={obj.preview || obj.url}
                      alt={obj.name}
                      className="h-40 rounded-xl border object-contain p-1"
                    />
                    <p className="truncate text-center text-sm transition-all group-hover:text-primary-light">
                      {obj.name}
                    </p>
                    <div className="absolute inset-0 flex cursor-pointer items-center justify-center rounded-xl bg-gray-500 opacity-0 transition-all group-hover:opacity-50">
                      <button
                        className="absolute top-2 right-2 z-10 rounded-full bg-white p-0.5 transition-colors hover:bg-gray-800"
                        onClick={(event) => {
                          deleteFile(obj.key || obj._id);
                          event.stopPropagation();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-4 w-4 transition-colors hover:text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-8 w-8 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <div
                    key={obj.key || obj._id}
                    className="group relative flex flex-shrink-0 flex-col items-center gap-1 p-1"
                  >
                    {checkFileType(obj.name) === 'PDF' && obj.url ? (
                      <Document
                        file={obj.url}
                        className="border-primary-200 h-40 max-w-full overflow-hidden rounded-xl border p-1"
                        renderMode="svg"
                      >
                        <Page pageNumber={1} width={150} className="mx-auto" />
                      </Document>
                    ) : (
                      <div className="border-primary-200 flex h-40 min-w-2/7 items-center justify-center overflow-hidden rounded-xl border-2 p-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-24 w-24 text-primary-light"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>
                      </div>
                    )}
                    <p className="truncate text-center text-sm transition-all group-hover:text-primary-light">
                      {obj.name}
                    </p>
                    <a
                      className={`${
                        obj.url ? 'cursor-pointer' : ''
                      } absolute inset-0 z-10 flex items-center justify-center rounded-xl bg-gray-500 opacity-0 transition-all group-hover:opacity-50`}
                      href={obj.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-8 w-8 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>
                    </a>
                    <button
                      className="absolute top-2 right-2 z-20 rounded-full bg-white p-0.5 opacity-0 transition-all hover:bg-gray-800 group-hover:opacity-100"
                      onClick={(event) => {
                        deleteFile(obj.key || obj._id);
                        event.stopPropagation();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-4 w-4 opacity-50 transition-colors hover:text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        )}

        {fullList && fullList.length === 0 && (
          <div className="flex justify-center px-6 pt-8 pb-2">
            <p className="text-lg text-primary">
              Aún no se han subido archivos esta semana.
            </p>
          </div>
        )}

        <div className="my-6 h-px w-full bg-gray-200" />

        <div className="flex flex-col gap-8 px-8 md:flex-row">
          <div className="flex items-center gap-2">
            <p className="text-lg font-semibold text-primary">CALIFICACIÓN</p>

            <svg
              className="h-8 w-8 text-primary"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>

          {teacher && (
            <div className="flex flex-1 items-center gap-2 md:gap-4">
              <img className="mr-4 h-12 md:h-16" src={ProfileIcon} alt="" />
              <p className="font-semibold">
                {`${teacher.name} ${teacher.last_name_p || ''} ${
                  teacher.last_name_m || ''
                }`}
              </p>
            </div>
          )}

          {!!grade && (
            <div className="flex items-center gap-2">
              <p className="font-semibold">Nota: </p>
              <p
                className="text-3xl font-semibold"
                style={{ color: '#41C384' }}
              >
                {grade}
              </p>
            </div>
          )}

          {!grade && !teacher && (
            <p className="text-lg font-semibold text-primary">SIN CALIFICAR</p>
          )}
        </div>

        <div className="my-6 h-px w-full bg-gray-200" />

        <div className="flex flex-col gap-8 px-8 md:flex-row">
          <div className="flex items-center gap-11">
            <p className="text-lg font-semibold text-primary">RECURSOS</p>

            <svg
              className="h-8 w-8 text-primary"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>

          <div className="flex flex-1 flex-col items-center justify-start gap-4 md:flex-row">
            <OutlineButton
              htmlFor={`image_file_${week}`}
              className="max-w-xs cursor-pointer rounded-full"
            >
              Subir archivos
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="ml-2 h-6 w-6 group-hover:text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>
            </OutlineButton>
            <p className="text-sm text-gray-400">
              JPG, PNG, PDF. Tamaño menor a 5mb
            </p>
            <input
              id={`image_file_${week}`}
              className="hidden"
              type="file"
              name="image"
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={checkImage}
            />
          </div>
        </div>

        <div className="my-6 h-px w-full bg-gray-200" />

        <div className="mb-8 flex flex-col items-center gap-4 md:flex-row md:justify-end">
          <SolidButton
            size="sm"
            className="max-w-xs rounded-full"
            style={{
              width: '200px',
            }}
            isLoading={loading}
            onClick={() => submitUpdate()}
          >
            GUARDAR
          </SolidButton>
          <OutlineButton
            size="sm"
            className="max-w-xs rounded-full"
            onClick={() => setNewFiles([])}
            style={{
              width: '200px',
            }}
            disabled={loading}
          >
            CANCELAR
          </OutlineButton>
        </div>
      </Transition>
    </div>
  );
}
