import { useEffect, useState } from 'react';

export default function Calendar({ events, onMonthEventsChange }) {
  const MONTH_NAMES = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SETIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
  ];
  const DAYS = ['DOM', 'LUN', 'MAR', 'MIÉ', 'JUE', 'VIE', 'SÁB'];
  const DAYS_SHORT = ['Dom', 'Lun', 'Mar', 'Miér', 'Jue', 'Vier', 'Sáb'];

  const now = new Date();
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(now.getMonth());

  const [blankDays, setBlankDays] = useState([]);
  const [calDays, setCalDays] = useState([]);
  const [lastDays, setLastDays] = useState([]);

  const [monthEvents, setMonthEvents] = useState([]);

  function isToday(date) {
    const today = new Date();
    const d = new Date(year, month, date);

    return today.toDateString() === d.toDateString() ? true : false;
  }

  useEffect(() => {
    let daysInMonth = new Date(year, month + 1, 0).getDate();

    let dayOfWeek = new Date(year, month).getDay();
    let blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    let daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    let lastDaysArray = [];
    for (let i = 1; i <= 35 - daysInMonth - dayOfWeek; i++) {
      lastDaysArray.push(i);
    }

    setBlankDays(blankdaysArray);
    setCalDays(daysArray);
    setLastDays(lastDaysArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month]);

  useEffect(() => {
    const filteredEvents = [...events].filter(
      (obj) =>
        new Date(obj.date).getFullYear() === year &&
        new Date(obj.date).getMonth() === month
    );
    setMonthEvents(filteredEvents);
    onMonthEventsChange(filteredEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, events]);

  function previousMonth() {
    if (month === 0) {
      setYear(year - 1);
      setMonth(11);
      return;
    }
    setMonth(month - 1);
  }

  function nextMonth() {
    if (month === 11) {
      setYear(year + 1);
      setMonth(0);
      return;
    }
    setMonth(month + 1);
  }

  return (
    <div>
      <div className="overflow-hidden">
        <div className="flex items-center justify-center space-x-4 px-6 py-4">
          <div
            className="cursor-pointer justify-self-end rounded-full bg-light-blue p-0.5 text-white transition-all hover:bg-gray-200 hover:text-light-blue sm:col-span-1"
            onClick={() => previousMonth()}
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          <div className="w-60 text-center font-normal">
            <span className="text-2xl">{MONTH_NAMES[month]}</span>
            <span x-text="year" className="ml-2 text-2xl">
              {year}
            </span>
          </div>
          <div
            className="cursor-pointer justify-self-end rounded-full bg-light-blue p-0.5 text-white transition-all hover:bg-gray-200 hover:text-light-blue sm:col-span-1"
            onClick={() => nextMonth()}
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
        <div className="-mx-1 -mb-1 p-1 sm:p-6">
          <div className="flex flex-wrap">
            {DAYS.map((day, index) => (
              <div key={index} className="w-1/7 px-2 py-2">
                <div className="text-center text-base font-normal tracking-wide text-gray-700">
                  <span className="hidden xl:inline">{day}</span>
                  <span className="lg:hidden">{day[0]}</span>
                  <span className="hidden lg:inline xl:hidden">
                    {DAYS_SHORT[index]}
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-wrap border-t border-l border-gray-500">
            {blankDays.map((_, index) => (
              <div
                key={index}
                className={`${
                  index % 7 === 0 ? 'bg-gray-body' : 'bg-white'
                } h-12 w-1/7 border-r border-b border-gray-500 px-4 pt-2 text-center sm:h-16 lg:h-24`}
              />
            ))}
            {calDays.map((date, index) => {
              let eventIndex = monthEvents.findIndex(
                (obj) => date === new Date(obj.date).getDate()
              );
              return (
                <div
                  key={index}
                  className={`${
                    (index + blankDays.length) % 7 === 0
                      ? 'bg-gray-body'
                      : eventIndex > -1
                      ? `bg-${
                          monthEvents[eventIndex].color || 'light-blue'
                        } text-${
                          monthEvents[eventIndex].textColor || 'white'
                        } font-normal`
                      : 'bg-white'
                  } relative h-12 w-1/7 border-r border-b border-gray-500 px-1 pt-2 sm:h-16 sm:px-2 lg:h-24`}
                >
                  <div
                    x-text="date"
                    className={`${
                      isToday(date) ? 'bg-blue-500 text-white' : ''
                    } inline-flex h-8 w-8 items-center justify-center rounded-full text-center leading-none transition duration-100 ease-in-out`}
                  >
                    {date.toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                  </div>
                </div>
              );
            })}
            {lastDays.map((_, index) => (
              <div
                key={index}
                className={`${
                  index % 7 === 0 ? 'bg-gray-body' : 'bg-white'
                } h-12 w-1/7 border-r border-b border-gray-500 px-4 pt-2 text-center sm:h-16 lg:h-24`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
