import SolidButton from '../../components/SolidButton';

import image from '../../resources/img/illustrations/404.svg';

export default function NotFound() {
  return (
    <section className="body-font text-gray-700">
      <div className="container mx-auto flex flex-col items-center px-5 py-24 md:flex-row">
        <div className="mb-10 w-5/6 md:mb-0 md:w-1/2 lg:w-full lg:max-w-lg">
          <img
            className="rounded object-cover object-center"
            alt="hero"
            src={image}
          />
        </div>
        <div className="flex flex-col items-center text-center md:w-1/2 md:items-start md:pl-16 md:text-left lg:flex-grow lg:pl-24">
          <h1 className="title-font mb-4 text-3xl font-medium text-gray-900 sm:text-4xl">
            Error
            <br className="hidden lg:inline-block" />
            <span className="ml-2 sm:ml-0">404</span>
          </h1>
          <p className="mb-8 leading-relaxed">
            Ups! La página que busca no existe
          </p>
          <div className="flex justify-center">
            <SolidButton href="/profile" type="button">
              REGRESAR
            </SolidButton>
          </div>
        </div>
      </div>
    </section>
  );
}
