import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { useAuth } from '../../../../contexts/Auth';

import { secondsToTime, submitLog } from '../../../../utils/functions';
import StorageService from '../../../../utils/storage';
import GeneralApi from '../../../../utils/generalApi';

import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import ConfirmModalContent from '../../../../components/ConfirmModalContent';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import SolidButton from '../../../../components/SolidButton';
import OptionsQuestionItem from './OptionsQuestionItem';
import OpenQuestionItem from './OpenQuestionItem';
import Modal from '../../../../components/Modal';

export default function NewFormatExamPage() {
  let { examId } = useParams();
  const { search } = useLocation();

  const textStrings = useMemo(
    () =>
      new URLSearchParams(search)?.get('type') === 'assignment'
        ? {
            variable: 'assignment',
            id: 'assignment_id',
            title: 'Tarea',
            name: 'tarea',
            nameFull: 'la tarea',
            ongoingMessage: 'Ya hay otra tarea en curso',
            successMessage: 'Tarea entregada!',
          }
        : {
            variable: 'exam',
            id: 'exam_id',
            title: 'Examen',
            name: 'examen',
            nameFull: 'el examen',
            ongoingMessage: 'Ya hay otro examen en curso',
            successMessage: 'Examen completado!',
          },
    [search]
  );

  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState(null);
  const [sections, setSections] = useState([]);
  const [questionsTotal, setQuestionsTotal] = useState(0);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);

  const [timerCounting, setTimerCounting] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const [emptyText, setEmptyText] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isConfirmFinishOpen, setIsConfirmFinishOpen] = useState(false);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getExamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timerCounting) {
      const interval = setInterval(() => {
        setTimerValue((value) => {
          if (value > 0) return value - 1000;
          else {
            finishExam();
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerCounting]);

  const getExamData = async () => {
    setLoading(true);
    const result = await generalApi.post(
      `/classroom/${textStrings.variable}/details`,
      {
        student_id: StorageService.get('chosenStudent'),
        [textStrings.id]: examId,
      }
    );
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    setLoading(false);
    if (result.data.currentExam) {
      showErrorToast(textStrings.ongoingMessage);
      history.replace(
        result.data.currentExam.new_format
          ? `/exam/${result.data.currentExam._id}`
          : `/exam/legacy/${result.data.currentExam._id}`
      );
    }
    setExam(result.data);
    setSections(result.data.sections || []);
    setQuestionsTotal(
      result.data.sections.reduce((a, b) => a + b.questions.length, 0)
    );
    setSectionIndex(result.data.currentSection || 0);
    setQuestionIndex(result.data.currentQuestion || 0);
    setQuestionCount(result.data.questionCount || 0);
    setTimerValue(result.data.timerValue);
    submitLog(generalApi, {
      student_id: StorageService.get('chosenStudent'),
      kind: 'examDuration',
      data: {
        exam_duration: result.data.duration,
        exam_duration_milli: result.data.duration * 1000 * 60,
        now: new Date().getTime(),
        started_at: new Date(result.data.started_at).getTime(),
        timer_value: result.data.timerValue,
        minutes:
          (result.data.duration * 1000 * 60 -
            (new Date().getTime() -
              new Date(result.data.started_at).getTime())) /
          (1000 * 60),
      },
      text: `${result.data.duration} / ${
        result.data.duration * 1000 * 60
      } / ${new Date().getTime()} / ${new Date(
        result.data.started_at
      ).getTime()} / ${result.data.timerValue} / ${(
        (result.data.duration * 1000 * 60 -
          (new Date().getTime() - new Date(result.data.started_at).getTime())) /
        (1000 * 60)
      ).toFixed(0)}`,
    });
    setTimerCounting(true);
  };

  const setAnswer = (questionId, value) => {
    const questionIndex = sections[sectionIndex].questions.findIndex(
      (obj) => obj._id === questionId
    );
    if (
      sections[sectionIndex].questions[questionIndex].mode === 'Alternativas' &&
      sections[sectionIndex].questions[questionIndex].chosenAnswer !== ''
    )
      return;
    sections[sectionIndex].questions[questionIndex].chosenAnswer = value;

    setSections([...sections]);
  };

  const setAnswerImage = (questionId, value) => {
    console.log('setAnswerImage', questionId, value);
    const questionIndex = sections[sectionIndex].questions.findIndex(
      (obj) => obj._id === questionId
    );
    sections[sectionIndex].questions[questionIndex].chosenImages = value
      ? [value]
      : [];

    setSections([...sections]);
  };

  const saveExamProgress = async (
    sectionIdx,
    questionIdx,
    auxQuestionCount
  ) => {
    const result = await generalApi.post(
      `/classroom/${textStrings.variable}/save`,
      {
        student_id: StorageService.get('chosenStudent'),
        [textStrings.id]: examId,
        sections,
        currentSection: sectionIdx,
        currentQuestion: questionIdx,
        questionCount: auxQuestionCount,
      }
    );
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
  };

  const previousQuestion = () => {
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
      setQuestionCount(questionCount - 1);
      saveExamProgress(sectionIndex, questionIndex - 1, questionCount - 1);
    } else if (sectionIndex > 0) {
      setQuestionIndex(sections[sectionIndex - 1].questions.length - 1);
      setSectionIndex(sectionIndex - 1);
      setQuestionCount(questionCount - 1);
      saveExamProgress(
        sectionIndex - 1,
        sections[sectionIndex - 1].questions.length - 1,
        questionCount - 1
      );
    }
  };

  const nextQuestion = () => {
    if (
      sections[sectionIndex].questions[questionIndex].mandatory &&
      (sections[sectionIndex].questions[questionIndex].chosenAnswer ===
        undefined ||
        sections[sectionIndex].questions[questionIndex].chosenAnswer === '')
    ) {
      return showErrorToast('Debe ingresar su respuesta para continuar');
    }
    if (questionIndex < sections[sectionIndex].questions.length - 1) {
      setQuestionIndex(questionIndex + 1);
      setQuestionCount(questionCount + 1);
      saveExamProgress(sectionIndex, questionIndex + 1, questionCount + 1);
    } else if (sectionIndex < sections.length - 1) {
      setQuestionIndex(0);
      setSectionIndex(sectionIndex + 1);
      setQuestionCount(questionCount + 1);
      saveExamProgress(sectionIndex + 1, 0, questionCount + 1);
    }
  };

  const validateExam = () => {
    const emptyAmount = sections.reduce(
      (a, b) =>
        a +
        b.questions.reduce(
          (a, b) =>
            b.chosenAnswer === undefined || b.chosenAnswer === '' ? a + 1 : a,
          0
        ),
      0
    );
    setEmptyText(
      emptyAmount > 0
        ? `Tiene ${emptyAmount} ${
            emptyAmount > 1 ? 'preguntas' : 'pregunta'
          } sin responder.`
        : ''
    );
    setIsConfirmFinishOpen(true);
  };

  const finishExam = async () => {
    if (submitLoading) return;
    setSubmitLoading(true);
    const result = await generalApi.post(
      `/classroom/${textStrings.variable}/finish`,
      {
        student_id: StorageService.get('chosenStudent'),
        [textStrings.id]: examId,
        sections,
      }
    );
    if (!result.success) {
      setSubmitLoading(false);
      showErrorToast(result.message);
      return;
    }
    showSuccessToast(textStrings.successMessage);
    setIsConfirmFinishOpen(false);
    history.replace(`/my_courses/${exam.course._id}/${textStrings.variable}s`);
  };

  return (
    <div>
      <Modal
        isOpen={isConfirmFinishOpen}
        onClose={() => {
          setIsConfirmFinishOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmFinishOpen(false);
          }}
          onConfirm={finishExam}
          title={`Finalizar ${textStrings.name}`}
          content={`¿Está seguro que desea finalizar ${textStrings.nameFull}? ${emptyText}`}
          buttonLoading={submitLoading}
        />
      </Modal>

      <div className="items-center justify-between space-y-2 border-b-2 border-gray-300 bg-gray-body px-6 py-4 sm:z-10 sm:flex sm:space-x-6 sm:space-y-0 sm:px-12">
        <div className="flex items-center">
          <svg
            className="mr-2 h-12 w-12 text-primary group-hover:text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 14l9-5-9-5-9 5 9 5z" />
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            />
          </svg>
          <span className="text-2xl font-light text-primary">
            {textStrings.title}
          </span>
        </div>
        {exam && (
          <div className="text-center">
            <p className="mx-auto text-xl font-bold">
              Sección {sectionIndex + 1} de {sections?.length}
            </p>
            <p className="mx-auto text-xl font-bold">
              {sections[sectionIndex]?.course}
            </p>
          </div>
        )}
        <div>
          <p>Una vez marque su respuesta no podrá cambiarla.</p>
          {exam?.block_return && (
            <p>Una vez avance a la siguiente pregunta no podrá retroceder</p>
          )}
        </div>
      </div>
      <div className="px-4 py-4 md:px-12">
        <div className="sm:flex">
          {loading ? (
            <LoadingSpinner
              content="Buscando preguntas"
              className="flex-1 justify-center py-6"
              color="primary"
            />
          ) : sections.length === 0 ? (
            <p>No se encontraron preguntas</p>
          ) : (
            <div className="mb-6 h-fit flex-1 bg-white px-4 py-4 shadow-md sm:flex sm:space-x-4 sm:px-6">
              <div className="flex-1">
                {sections[sectionIndex].reading_title &&
                  sections[sectionIndex].reading_content && (
                    <div className="mb-4 text-center">
                      <p className="mx-auto text-xl font-bold">
                        {sections[sectionIndex].reading_title
                          ? `Lectura: ${sections[sectionIndex].reading_title}`
                          : 'Lectura'}
                      </p>
                      <p
                        className="mt-2 rounded-xl bg-gray-200 px-4 py-2 text-xl"
                        dangerouslySetInnerHTML={{
                          __html: sections[sectionIndex].reading_content || '',
                        }}
                      />
                    </div>
                  )}
                <div className="flex h-9 w-9 items-center justify-self-end rounded-full bg-gray-200 text-light-blue">
                  <p className="mx-auto text-xl font-bold">
                    {questionIndex + 1}
                  </p>
                </div>
                {sections[sectionIndex].questions[questionIndex].mode ===
                'Alternativas' ? (
                  <OptionsQuestionItem
                    answers={
                      sections[sectionIndex].questions[questionIndex].answers
                    }
                    content={
                      sections[sectionIndex].questions[questionIndex].content
                    }
                    imageUrl={
                      sections[sectionIndex].questions[questionIndex].image_url
                    }
                    chosenAnswer={
                      sections[sectionIndex].questions[questionIndex]
                        .chosenAnswer
                    }
                    onAnswerChange={(value) =>
                      setAnswer(
                        sections[sectionIndex].questions[questionIndex]._id,
                        value
                      )
                    }
                  ></OptionsQuestionItem>
                ) : (
                  <OpenQuestionItem
                    questionId={sections[sectionIndex].questions[questionIndex]}
                    content={
                      sections[sectionIndex].questions[questionIndex].content
                    }
                    imageUrl={
                      sections[sectionIndex].questions[questionIndex].image_url
                    }
                    chosenAnswer={
                      sections[sectionIndex].questions[questionIndex]
                        .chosenAnswer
                    }
                    onAnswerChange={(value) =>
                      setAnswer(
                        sections[sectionIndex].questions[questionIndex]._id,
                        value
                      )
                    }
                    chosenImage={
                      sections[sectionIndex].questions[questionIndex]
                        .chosenImages?.[0]
                    }
                    onImageChange={(value) =>
                      setAnswerImage(
                        sections[sectionIndex].questions[questionIndex]._id,
                        value
                      )
                    }
                  ></OpenQuestionItem>
                )}
              </div>
              <div className="relative mt-14 w-14 sm:mt-0">
                {!exam.block_return &&
                (questionIndex > 0 || sectionIndex > 0) ? (
                  <button
                    className="absolute top-0 flex h-14 w-14 cursor-pointer items-center justify-center justify-self-end rounded-full bg-gray-200 text-light-blue transition-colors hover:bg-gray-300"
                    onClick={() => previousQuestion()}
                  >
                    <svg
                      className="hidden h-10 w-10 sm:block"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-11 w-11 sm:hidden"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>
                ) : (
                  <div></div>
                )}
                {questionIndex < sections[sectionIndex].questions.length - 1 ||
                sectionIndex < sections.length - 1 ? (
                  <button
                    className="absolute bottom-0 flex h-14 w-14 cursor-pointer items-center justify-center justify-self-end rounded-full bg-gray-200 text-light-blue transition-colors hover:bg-gray-300"
                    onClick={() => nextQuestion()}
                  >
                    <svg
                      className="hidden h-10 w-10 sm:block"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-11 w-11 sm:hidden"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                ) : null}
              </div>
            </div>
          )}
          {exam && (
            <div className="sm:max-w-2/7 sm:pl-12">
              <p className="text-2xl font-bold text-primary">
                {exam.course?.classroom?.name} {exam.course?.name?.name}
              </p>
              <p className="text-2xl font-bold text-light-blue">{exam.title}</p>

              {timerCounting && timerValue > 0 ? (
                <>
                  <p className="mt-8 text-xl font-bold">Tiempo Restante</p>
                  <p className="w-60 text-5xl font-bold text-primary">
                    {secondsToTime(timerValue / 1000)}
                  </p>
                </>
              ) : timerCounting ? (
                <p className="mt-8 text-xl font-bold text-primary">
                  Examen finalizado
                </p>
              ) : null}

              <p className="mt-8 text-xl font-bold">Barra de progreso</p>
              <div className="flex items-end justify-center space-x-4">
                <div className="mt-4 flex h-64 w-16 flex-col justify-end border-2 border-primary">
                  <div
                    className="h-1/2 w-full bg-primary transition-all"
                    style={{
                      height: `${
                        (questionCount / (questionsTotal - 1)) * 100
                      }%`,
                    }}
                  ></div>
                </div>
                <span className="w-16 text-xl font-bold">
                  {((questionCount / (questionsTotal - 1)) * 100).toFixed(0)}%
                </span>
              </div>
              <div className="mt-12">
                <SolidButton onClick={validateExam} isLoading={submitLoading}>
                  {`TERMINAR ${textStrings.name.toUpperCase()}`}
                </SolidButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
