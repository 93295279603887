import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { useAuth } from '../../../contexts/Auth';

import { showErrorToast, showSuccessToast } from '../../../components/Toast';
import LoadingSpinner from '../../../components/LoadingSpinner';
import OutlineButton from '../../../components/OutlineButton';
import SolidButton from '../../../components/SolidButton';
import Modal from '../../../components/Modal';

import { validateFile } from '../../../utils/fileUtils';
import { formatDate, scrollToTop } from '../../../utils/functions';
import { constants } from '../../../utils/constants';
import StorageService from '../../../utils/storage';
import GeneralApi from '../../../utils/generalApi';

import StarsPicker from '../../../components/StarsPicker';
import StarsStat from '../../../components/StarsStat';
import AnswerItem from './AnswerItem';

export default function QuestionDetails() {
  const history = useHistory();
  const auth = useAuth();
  const editorRef = useRef(null);

  let { courseId, questionId } = useParams();

  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [question, setQuestion] = useState({});
  const [displayContent, setDisplayContent] = useState({});

  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [userHasRated, setUserHasRated] = useState(false);
  const [rating, setRating] = useState(3);
  const [totalRatings, setTotalRatings] = useState(0);
  const [userRating, setUserRating] = useState(3);
  const [chosenAnswerId, setChosenAnswerId] = useState('');
  const [rateLoading, setRateLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const generalApi = new GeneralApi(auth, history);

  let checkImage = async (event) => {
    let isValid = validateFile(event.target, 'img');
    if (!isValid) {
      setImage(null);
      setImagePreview(null);
      showErrorToast(
        'Formato de imagen no válido, debe ser un archivo .png, .jpg o .jpeg'
      );
      return;
    }
    setImage(event.target.files[0]);
    var reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    await reader.readAsDataURL(event.target.files[0]);
  };

  useEffect(() => {
    getQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getQuestion = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/question/details`, {
      question_id: questionId,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    setQuestion(result.data);
    setDisplayContent(result.data);
    setShowForm(result.data.answers.length === 0);
    setLoading(false);

    if (displayContent?.isAnswer) {
      const index = result.data.answers.findIndex(
        (obj) => obj._id === displayContent?._id
      );
      setDisplayContent({ ...result.data.answers[index], isAnswer: true });
    }
  };

  let validateAnswer = () => {
    if (editorRef.current.getContent() === '')
      return 'Ingrese el contenido de su respuesta';
  };

  let submitAnswer = async () => {
    let error = validateAnswer();
    console.log(error);
    if (error !== undefined) return showErrorToast(error);

    let answerData = new FormData();
    answerData.append('student_id', StorageService.get('chosenStudent'));
    answerData.append('question_id', questionId);
    answerData.append('content', editorRef.current.getContent());
    if (image !== null) answerData.append('image', image);

    setFormLoading(true);
    const result = await generalApi.post(`/classroom/answer`, answerData);
    if (!result.success) {
      setFormLoading(false);
      showErrorToast(result.message);
      return;
    }
    showSuccessToast('Respuesta enviada!');
    getQuestion();
    editorRef.current.setContent('');
    setImage(null);
    setImagePreview(null);
    setFormLoading(false);
    setShowForm(false);
  };

  let validateRate = () => {
    if (chosenAnswerId === '' || chosenAnswerId === null)
      return 'Ocurrió un error, actualice la página';
    if (userRating <= 0 || userRating === null)
      return 'Elija entre 1 y 5 estrellas';
  };

  let submitRate = async () => {
    let error = validateRate();
    if (error !== undefined) return showErrorToast(error);

    setRateLoading(true);
    const result = await generalApi.post(`/classroom/answer/rate`, {
      student_id: StorageService.get('chosenStudent'),
      question_id: questionId,
      answer_id: chosenAnswerId,
      rating: userRating,
    });
    if (!result.success) {
      setRateLoading(false);
      showErrorToast(result.message);
      return;
    }
    showSuccessToast('Calificación enviada, gracias por compartir tu opinión!');
    getQuestion();
    setChosenAnswerId('');
    setRateLoading(false);
    setIsRatingOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={isRatingOpen}
        onClose={() => {
          setIsRatingOpen(false);
        }}
      >
        <div className="bg-white">
          <div className="px-4 pt-5 pb-4 sm:mr-10 sm:flex sm:items-start sm:p-6 sm:pb-4">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-12 sm:w-12">
              {/* Heroicon name: exclamation */}
              <svg
                className="h-8 w-8 text-yellow-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-3 sm:flex-1 sm:text-left">
              <h3
                className="text-xl font-medium leading-6 text-gray-900"
                id="modal-headline"
              >
                Calificación: {rating} ({totalRatings}{' '}
                {totalRatings !== 1 ? 'calificaciones' : 'calificación'})
              </h3>
              <div className="my-4 flex justify-center">
                <div className="flex rounded-full bg-gray-200 px-4 py-2">
                  <StarsStat rating={rating} size={8} />
                </div>
              </div>
              <div className="mt-6">
                <p className="text-xl font-medium leading-6 text-gray-900">
                  Mi Calificación
                </p>
                <div className="my-2 flex justify-center">
                  <StarsPicker
                    rating={userRating}
                    size={8}
                    onRate={
                      userHasRated
                        ? null
                        : (newValue) => setUserRating(newValue)
                    }
                  />
                </div>
                {!userHasRated ? (
                  <SolidButton
                    size="sm"
                    className="mt-4 px-4"
                    onClick={submitRate}
                    isLoading={rateLoading}
                    color="primary"
                  >
                    CALIFICAR!
                  </SolidButton>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="top-0 justify-between space-y-2 border-b-2 border-gray-300 bg-gray-body px-6 py-6 sm:sticky sm:z-10 sm:flex sm:space-x-6 sm:space-y-0 sm:px-12">
        <div className="flex flex-1 items-center ">
          <svg
            className="mr-2 h-12 w-12 text-primary group-hover:text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            />
          </svg>
          <span className="text-2xl font-light text-primary">
            {question?.topic || '-'}
          </span>
        </div>
        <div className="flex max-w-xs items-center">
          <SolidButton href={`/forum/${courseId}`}>FORO ACADÉMICO</SolidButton>
        </div>
        <div className="flex max-w-xs items-center">
          <SolidButton color="light-blue" href={`/forum/${courseId}/question`}>
            CREAR PREGUNTA
          </SolidButton>
        </div>
        <div className="flex max-w-xs items-center">
          <SolidButton
            href={!displayContent?.isAnswer && `/forum/${courseId}`}
            onClick={
              displayContent?.isAnswer
                ? () => setDisplayContent(question)
                : null
            }
          >
            REGRESAR
          </SolidButton>
        </div>
      </div>
      <div className="p-4 sm:py-8 md:px-12">
        {loading ? (
          <LoadingSpinner content="Buscando pregunta" color="primary" />
        ) : !displayContent ? (
          <p>No se encontro la pregunta</p>
        ) : (
          <div className="relative mt-4 space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <div className="top-36 h-fit flex-1 border-b-2 border-gray-300 bg-white px-4 py-6 shadow-lg sm:sticky sm:px-8">
              <div className="flex-1">
                <div className="justify-between space-y-2 sm:flex sm:space-y-0">
                  <div className="flex flex-row items-center">
                    <span className="hidden text-2xl text-primary-light">
                      {displayContent.content}
                    </span>
                    <p className="mt-2">
                      <span className="text-xl font-bold">
                        {displayContent.teacher
                          ? `${displayContent.teacher.name} ${displayContent.teacher.last_name_p}`
                          : `${displayContent.student.name} ${displayContent.student.last_name_p}`}{' '}
                        -{' '}
                      </span>{' '}
                      {formatDate(
                        displayContent.created_at,
                        'dd/MM/yyyy',
                        false
                      )}
                      <span className="ml-4">
                        {formatDate(
                          displayContent.created_at,
                          'hh:mm aa',
                          false
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                {displayContent?.isAnswer && (
                  <div className="mt-2 flex">
                    <span className="mr-2 hidden sm:block">Calificación:</span>
                    <button
                      className="flex cursor-pointer items-center rounded-lg px-2 hover:bg-gray-300"
                      title="Calificar"
                      onClick={() => {
                        if (displayContent.ratings !== null) {
                          const ratingIndex = displayContent.ratings.findIndex(
                            (obj) =>
                              obj.student ===
                              StorageService.get('chosenStudent')
                          );
                          if (ratingIndex < 0) {
                            setUserRating(3);
                            setUserHasRated(false);
                          } else {
                            setUserHasRated(true);
                            setUserRating(
                              displayContent.ratings[ratingIndex].value
                            );
                          }
                          setTotalRatings(displayContent.ratings.length);
                        } else {
                          setUserRating(3);
                          setTotalRatings(0);
                          setUserHasRated(false);
                        }
                        setChosenAnswerId(displayContent._id);
                        setRating(displayContent.rating || 3);
                        setIsRatingOpen(true);
                      }}
                    >
                      <StarsStat rating={displayContent.rating} />
                    </button>
                  </div>
                )}
                {displayContent.title && (
                  <p className="mt-4 -mb-2 list-inside text-2xl text-primary-light">
                    {displayContent.title}
                  </p>
                )}
                <p
                  className="mt-4 list-inside text-2xl"
                  dangerouslySetInnerHTML={{ __html: displayContent.content }}
                />
                {displayContent.image_url ? (
                  <img
                    src={displayContent.image_url}
                    className="w-full"
                    alt="Imagen"
                  />
                ) : null}
              </div>
            </div>
            {!showForm ? (
              <div className="h-fit bg-white px-4 py-6 shadow-lg sm:w-2/5 sm:p-8">
                <div className="mb-4 justify-end space-y-2 sm:flex sm:space-y-0 sm:space-x-4">
                  <OutlineButton
                    color="light-blue"
                    onClick={() => setShowForm(true)}
                  >
                    RESPONDER
                  </OutlineButton>
                  <p className="flex items-center justify-center rounded-md border-2 border-primary bg-gray-200 px-3 pt-1 text-lg text-gray-700 sm:w-60">
                    {question.answers.length !== 1
                      ? `${question.answers.length} respuestas`
                      : '1 respuesta'}
                  </p>
                </div>
                <div className="space-y-3">
                  {question.answers.map((answer) => (
                    <AnswerItem
                      key={answer._id}
                      isTeacher={answer.teacher !== undefined}
                      userName={
                        answer.teacher
                          ? `${answer.teacher.name} ${answer.teacher.last_name_p}`
                          : `${answer.student.name} ${answer.student.last_name_p}`
                      }
                      date={formatDate(answer.created_at, 'dd/MM/yyyy', false)}
                      time={formatDate(answer.created_at, 'hh:mm aa', false)}
                      content={answer.content}
                      imageUrl={answer.image_url}
                      rating={answer.rating || 3}
                      avatarUrl={
                        answer.teacher
                          ? answer.teacher.avatar_url
                          : answer.student.avatar_url
                      }
                      onShowClick={() => {
                        setDisplayContent({ ...answer, isAnswer: true });
                        scrollToTop();
                      }}
                      onRateClick={() => {
                        if (answer.ratings !== null) {
                          const ratingIndex = answer.ratings.findIndex(
                            (obj) =>
                              obj.student ===
                              StorageService.get('chosenStudent')
                          );
                          if (ratingIndex < 0) {
                            setUserRating(3);
                            setUserHasRated(false);
                          } else {
                            setUserHasRated(true);
                            setUserRating(answer.ratings[ratingIndex].value);
                          }
                          setTotalRatings(answer.ratings.length);
                        } else {
                          setUserRating(3);
                          setTotalRatings(0);
                          setUserHasRated(false);
                        }
                        setChosenAnswerId(answer._id);
                        setRating(answer.rating || 3);
                        setIsRatingOpen(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            ) : null}
            {showForm ? (
              <div className="h-fit bg-white px-4 py-6 shadow-lg sm:w-2/5 sm:p-8">
                <div className="mb-6 space-y-2">
                  <div className="flex max-w-xs items-center">
                    <OutlineButton
                      color="light-blue"
                      htmlFor="image_file"
                      className="cursor-pointer"
                    >
                      <svg
                        className="text-blue mr-2 h-7 w-7 group-hover:text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                      {image ? 'CAMBIAR IMAGEN' : 'SUBIR IMAGEN'}
                    </OutlineButton>
                    <input
                      id="image_file"
                      className="hidden"
                      type="file"
                      name="image"
                      accept=".png, .jpg, .jpeg"
                      onChange={checkImage}
                    />
                  </div>
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      className="h-24 max-w-full rounded-lg border-2 border-light-blue sm:h-36"
                      alt="Imagen de la pregunta"
                    ></img>
                  ) : null}
                </div>
                <Editor
                  apiKey="8com3pmw9v05b20vk3t04cgt6hxoi372hxj01v155q05grdk"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  init={constants.TINY_INIT}
                />
                <div className="mt-6 justify-end space-y-2 sm:flex sm:justify-between sm:space-y-0 sm:space-x-4">
                  <div className="flex max-w-xs items-center">
                    <SolidButton
                      className="px-12"
                      onClick={submitAnswer}
                      isLoading={formLoading}
                    >
                      ENVIAR RESPUESTA
                    </SolidButton>
                  </div>
                  <div className="flex max-w-xs items-center">
                    <OutlineButton
                      className="px-12"
                      color="gray"
                      disabled={formLoading}
                      onClick={() => setShowForm(false)}
                    >
                      CANCELAR
                    </OutlineButton>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
