import { useState, useEffect, useMemo } from 'react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { useHistory, useParams } from 'react-router-dom';

import GeneralApi from '../../../../utils/generalApi';
import { useAuth } from '../../../../contexts/Auth';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import OutlineButton from '../../../../components/OutlineButton';
import { showErrorToast } from '../../../../components/Toast';

import AssignmentResultsIcon from '../../../../resources/img/icons/data-analytics.png';
import DateIcon from '../../../../resources/img/icons/date.png';
import TimeIcon from '../../../../resources/img/icons/time.png';
import SchoolIcon from '../../../../resources/img/icons/school.png';
import KindIcon from '../../../../resources/img/icons/kind.png';
import DocsIcon from '../../../../resources/img/icons/docs.png';
import BookIcon from '../../../../resources/img/icons/book.png';
import { formatDate } from '../../../../utils/functions';
import StorageService from '../../../../utils/storage';
import Modal from '../../../../components/Modal';
import AssignmentReport from '../GradesReport/ExamReport';

const courseNames = [
  'Aritmética',
  'Álgebra',
  'Geometría',
  'Trigonometría',
  'Física',
  'Química',
  'Biología',
  'Raz. Matemático',
  'Raz. Verbal',
  'Historia',
  'Psicología',
  'Cívica',
  'Economía',
  'Lenguaje',
  'Literatura',
  'Filosofía',
  'Geografía',
  'Ingles',
  'Historia Universal',
  'Historia del Perú',
  'Anatomía',
  'Hab. Verbal',
  'Hab. Lógico Matemático',
  'Estadística',
  'Números y Operaciones',
  'Vocabulario',
  'Lectura Comprensiva e Interpretativa',
  'Lectura Critica',
  'Ortografía y Puntuación',
  'Matemáticas',
  'Ciencias',
  'Humanidades',
];
const courseColors = {
  Aritmética: '#2000FF',
  Álgebra: '#FF0000',
  Geometría: '#00FF00',
  Trigonometría: '#6A0DAD',
  Física: '#555555',
  Química: '#E40078',
  Biología: '#007E8B',
  'Raz. Matemático': '#9400D3',
  'Raz. Verbal': '#02611B',
  Historia: '#FF7E00',
  Psicología: '#FFBA00',
  Cívica: '#001A57',
  Economía: '#3EAEB1',
  Lenguaje: '#520E45',
  Literatura: '#7E9F2E',
  Filosofía: '#9DFFD0',
  Geografía: '#51D1F6',
  Ingles: '#FF00FF',
  'Historia Universal': '#3841f2',
  'Historia del Perú': '#5d2687',
  Anatomía: '#7b268c',
  'Hab. Verbal': '#02611B',
  'Hab. Lógico Matemático': '#9400D3',
  Estadística: '#2000FF',
  'Números y Operaciones': '#FF0000',
  Vocabulario: '#520E45',
  'Lectura Comprensiva e Interpretativa': '#7E9F2E',
  'Lectura Critica': '#FF7E00',
  'Ortografía y Puntuación': '#FF00FF',
  Matemáticas: '#FF0000',
  Ciencias: '#2000FF',
  Humanidades: '#00FF00',
};

const EffectivenessChart = ({ data }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    colors={['rgb(103,22,144)', 'rgb(54, 162, 235)', '#afafaf']}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    enableArcLinkLabels={false}
    arcLabel={function (e) {
      return e.value + '%';
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor={{ from: 'color' }}
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{ from: 'color', modifiers: [['brighter', 6]] }}
    legends={[
      {
        anchor: 'bottom',
        direction: 'column',
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: 12,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]}
  />
);

const GradesChart = ({ data }) => (
  <ResponsiveBar
    data={data}
    keys={['Nota']}
    indexBy="section"
    margin={{ top: 50, right: 20, bottom: 120, left: 60 }}
    padding={0.4}
    groupMode="grouped"
    valueScale={{ type: 'linear' }}
    valueFormat={(value) => value.toFixed(2)}
    indexScale={{ type: 'band', round: true }}
    colorBy="indexValue"
    colors={(d) => d?.data?.color}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    theme={{
      axis: {
        domain: {
          line: {
            stroke: 'black',
            strokeWidth: 1,
          },
        },
      },
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 10,
      tickPadding: 10,
      tickRotation: -90
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Notas',
      legendPosition: 'middle',
      legendOffset: -40,
    }}
    enableGridY={false}
    labelSkipWidth={16}
    labelSkipHeight={16}
    labelTextColor="white"
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    maxValue={data.reduce((a, b) => (a < b.Nota ? b.Nota : a), 0)}
    minValue={data.reduce((a, b) => (a < b.Nota ? a : b.Nota), 0)}
  />
);

export default function AssignmentResult() {
  let { courseId, assignmentId } = useParams();

  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [courseLoading, setCourseLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [assignment, setAssignment] = useState(null);

  const [currentSection, setCurrentSection] = useState(null);
  const [isSectionResultOpen, setIsSectionResultOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const generalApi = new GeneralApi(auth, history);

  const { questionsTotal, maxGrade, avgGrade, minGrade, gradePosition, grade } =
    useMemo(() => {
      if (!assignment) {
        return { questionsTotal: 0, maxGrade: 0 };
      }
      const maxGrade =
        assignment.mode === 'Desarrollado'
          ? 20
          : assignment.sections.reduce(
              (a, b) => a + b.questions.reduce((c, d) => c + d.value, 0),
              0
            );
      return {
        questionsTotal: assignment.sections.reduce(
          (a, b) => a + b.questions.length,
          0
        ),
        maxGrade: assignment.results.reduce(
          (a, b) => (a < b.total ? b.total : a),
          0
        ).toFixed(1),
        avgGrade: (
          assignment.results.reduce((a, b) => a + b.total, 0) /
          assignment.results.length
        ).toFixed(1),
        minGrade: assignment.results.reduce(
          (a, b) => (a < b.total ? a : b.total),
          0
        ).toFixed(1),
        gradePosition:
          assignment.results
            .sort((a, b) => b.total - a.total)
            .findIndex(
              (e) => e.student._id === StorageService.get('chosenStudent')
            ) + 1,
        grade: ((assignment.result.total / maxGrade) * 20).toFixed(1),
      };
    }, [assignment]);

  useEffect(() => {
    getCourseData();
    getAssignmentResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getCourseData = async () => {
    setCourseLoading(true);
    const result = await generalApi.post(`/classroom/course/details`, {
      course_id: courseId,
    });
    if (!result.success) {
      setCourseLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/home`);
      return;
    }
    setCourse(result.data.course);
    setCourseLoading(false);
  };

  let getAssignmentResult = async () => {
    const result = await generalApi.post(`/classroom/assignment/result`, {
      student_id: StorageService.get('chosenStudent'),
      assignment_id: assignmentId,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    setLoading(false);
    setAssignment(result.data.assignment);
  };

  const getSummaryMessage = (result) => {
    const { sections } = result;
    const sectionsToImprove = sections
      .map((section, index) => ({
        ...section,
        course: assignment.sections[index].course,
      }))
      .filter((section) => {
        return (
          (section.total / section.questions.reduce((a, b) => a + b.value, 0)) *
            100 <
          50
        );
      })
      .map((section) => ({
        course: section.course,
        percentage:
          (section.total / section.questions.reduce((a, b) => a + b.value, 0)) *
          100,
      }))
      .sort((a, b) => a.percentage - b.percentage)
      .slice(0, 2);

    if (sectionsToImprove.length === 0) {
      return '¡Excelente! No hay cursos que mejorar.';
    }
    const sectionsToImproveNames = sectionsToImprove.map(
      (section) => section.course
    );

    if (sectionsToImprove.length === 1) {
      return `El estudiante debe reforzar más el curso de ${sectionsToImproveNames[0]}.`;
    }
    return `El estudiante debe reforzar más los cursos ${sectionsToImproveNames
      .join(', ')
      .replace(/, ([^,]*)$/, ' y $1')}.`;
  };

  return (
    <div>
      <Modal
        isOpen={isSectionResultOpen}
        size="lg"
        onClose={() => {
          setIsSectionResultOpen(false);
        }}
      >
        <AssignmentReport
          assignmentDescription={assignment?.description}
          assignmentQuestions={
            assignment?.sections[currentSection]?.questions || []
          }
          reportQuestions={
            assignment?.result?.sections[currentSection]?.questions || []
          }
          onImageClick={(image) => setCurrentImage(image)}
        ></AssignmentReport>
      </Modal>

      <Modal
        isOpen={currentImage !== null}
        size="lg"
        onClose={() => {
          setCurrentImage(null);
        }}
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-8">
          <p className="mb-2 text-xl">Pregunta {currentImage + 1}</p>
          <p
            className="mb-3 rounded-lg border-2 border-primary p-2"
            dangerouslySetInnerHTML={{
              __html:
                assignment?.sections[currentSection]?.questions?.[currentImage]
                  ?.content || '',
            }}
          />
          <div className="relative overflow-hidden rounded-lg border-2 border-primary">
            <ReactPanZoom
              image={
                assignment?.sections[currentSection]?.questions?.[currentImage]
                  ?.image_url
              }
              alt="Imagen de la pregunta"
            />
          </div>
        </div>
      </Modal>

      <div className="mx-4 my-8 md:mx-16 md:my-12">
        <div className="rounded-2xl bg-white">
          <div className="flex flex-col justify-between gap-4 border-b border-gray-300 p-6 lg:flex-row lg:items-center xl:px-12 xl:py-6">
            {courseLoading && (
              <div className="flex items-center gap-4">
                <div className="h-12 w-12 animate-pulse rounded-full bg-gray-300" />
                <div className="flex flex-col gap-2">
                  <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300" />
                  <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300" />
                </div>
              </div>
            )}

            {course?.school?.name && (
              <div className="flex flex-col items-center gap-3 lg:flex-row">
                <div className="flex items-center gap-4">
                  <img
                    src={AssignmentResultsIcon}
                    className="h-12 bg-no-repeat sm:h-12"
                    alt="Logo"
                  />
                  <p className="text-2xl text-primary-light">
                    {course?.name?.name || ''} - {course?.classroom?.name || ''}
                  </p>
                </div>
                <p className="text-2xl font-bold text-primary-light">/ Notas</p>
              </div>
            )}

            <OutlineButton
              className="w-min font-light"
              href={`/my_courses/${courseId}/grades`}
            >
              Regresar
            </OutlineButton>
          </div>

          <div className="p-8 xl:px-12">
            <div className="mb-8 flex flex-1 flex-wrap justify-around gap-8 rounded-xl border border-primary p-8">
              <div>
                <img src={DateIcon} alt="Date icon" className="h-6" />
                <p className="mt-1 font-normal">Fecha</p>
                <p>{formatDate(assignment?.assignment_date, 'dd/MM/yyyy')}</p>
              </div>
              <div>
                <img src={TimeIcon} alt="Time icon" className="h-6" />
                <p className="mt-1 font-normal">Horario</p>
                <p>{course?.schedule?.name}</p>
              </div>
              <div>
                <img src={SchoolIcon} alt="School icon" className="h-6" />
                <p className="mt-1 font-normal">Proyecto</p>
                <p>{course?.school?.name}</p>
              </div>
              <div>
                <img src={KindIcon} alt="Kind icon" className="h-6" />
                <p className="mt-1 font-normal">Evaluación</p>
                <p>{assignment?.kind}</p>
              </div>
              <div>
                <img src={DocsIcon} alt="Docs icon" className="h-6" />
                <p className="mt-1 font-normal">Programa</p>
                <p>{course?.name?.name}</p>
              </div>
              <div>
                <img src={BookIcon} alt="Book icon" className="h-6" />
                <p className="mt-1 font-normal">Ciclo</p>
                <p>{course?.classroom?.name}</p>
              </div>
            </div>

            {loading || !assignment ? (
              <div className="flex justify-center">
                <LoadingSpinner
                  content="Buscando resultado de la tarea"
                  color="primary"
                />
              </div>
            ) : (
              <div className="rounded-xl border border-primary">
                <div className="rounded-t-xl bg-primary py-4 px-8 text-2xl font-bold text-white">
                  ESTADÍSTICAS
                </div>

                <div className="space-y-4 rounded-b-xl py-4 lg:flex lg:space-y-0 lg:space-x-4 lg:p-4">
                  <div className="bg-white lg:w-1/4">
                    <div className="py-2 text-center text-lg font-bold text-primary">
                      PORCENTAJE DE EFECTIVIDAD
                    </div>
                    <div className="grades_chart h-96">
                      <EffectivenessChart
                        data={[
                          {
                            id: 'Correctas',
                            label: `Correctas ${(
                              (assignment.result.correct / questionsTotal) *
                              100
                            ).toFixed(2)}%`,
                            value: (
                              (assignment.result.correct / questionsTotal) *
                              100
                            ).toFixed(2),
                            color: 'rgb(103,22,144)',
                          },
                          {
                            id: 'Incorrectas',
                            label: `Incorrectas ${(
                              (assignment.result.incorrect / questionsTotal) *
                              100
                            ).toFixed(2)}%`,
                            value: (
                              (assignment.result.incorrect / questionsTotal) *
                              100
                            ).toFixed(2),
                            color: 'rgb(54, 162, 235)',
                          },
                          {
                            id: 'Blanco',
                            label: `Blanco ${(
                              (assignment.result.empty / questionsTotal) *
                              100
                            ).toFixed(2)}%`,
                            value: (
                              (assignment.result.empty / questionsTotal) *
                              100
                            ).toFixed(2),
                            color: '#afafaf',
                          },
                        ]}
                      />
                    </div>
                  </div>

                  <div className="bg-white lg:w-3/4">
                    <div className="py-2 text-center text-lg font-bold text-primary">
                      DISTRIBUCIÓN DE NOTAS
                    </div>
                    <div className="h-96">
                      <GradesChart
                        data={assignment.result.sections.map((obj, index) => ({
                          section:
                            assignment.sections.find(
                              (section) => section._id === obj.section
                            ).course ||
                            `Sección ${
                              assignment.sections.findIndex(
                                (section) => section._id === obj.section
                              ) + 1
                            }`,
                          Nota:
                            (obj.total /
                              obj.questions.reduce((a, b) => a + b.value, 0)) *
                            20,
                          color:
                            courseColors[
                              assignment.sections.find(
                                (section) => section._id === obj.section
                              )?.course || courseNames[index]
                            ],
                        }))}
                      />
                    </div>
                  </div>
                </div>

                <div className="m-10 h-0.5 bg-gray-200" />

                <div className="mb-8 flex flex-wrap justify-around gap-8 px-8">
                  <div className="px-2 text-center">
                    <p className="text-2xl font-bold text-primary">
                      Puntaje máximo
                    </p>
                    <p className="text-5xl">{maxGrade}</p>
                  </div>

                  <div className="px-2 text-center">
                    <p className="text-2xl font-bold text-primary">
                      Puntaje promedio
                    </p>
                    <p className="text-5xl">{avgGrade}</p>
                  </div>

                  <div className="px-2 text-center">
                    <p className="text-2xl font-bold text-primary">
                      Puntaje mínimo
                    </p>
                    <p className="text-5xl">{minGrade}</p>
                  </div>

                  <div className="px-2 text-center">
                    <p className="text-2xl font-bold text-primary">
                      Puntaje estudiante
                    </p>
                    <p className="text-5xl">{assignment.result.total}</p>
                  </div>

                  <div className="px-2 text-center">
                    <p className="text-2xl font-bold text-primary">
                      Puesto general
                    </p>
                    <p className="text-5xl">#{gradePosition}</p>
                  </div>

                  <div className="px-2 text-center">
                    <p className="text-2xl font-bold text-primary">Nota</p>
                    <p className="text-5xl font-bold">{grade}</p>
                  </div>
                </div>

                <div className="mb-8 min-w-full overflow-hidden overflow-x-auto px-2 align-middle lg:px-8">
                  <table className="min-w-full divide-gray-200">
                    <thead>
                      <tr>
                        <th className="rounded-l-xl bg-gray-table px-6 py-4 text-center text-lg font-light uppercase tracking-wider text-white">
                          #
                        </th>
                        <th className="bg-gray-table px-6 py-4 text-center text-lg font-bold tracking-wider text-white">
                          Curso
                        </th>
                        <th className="bg-gray-table px-6 py-4 text-center text-lg font-bold tracking-wider text-white">
                          Nota
                        </th>
                        <th className="bg-gray-table px-6 py-4 text-center text-lg font-bold tracking-wide text-white">
                          Efectividad %
                        </th>
                        <th className="bg-gray-table px-6 py-4 text-center text-lg font-bold tracking-wider text-white">
                          Buenas
                        </th>
                        <th className="bg-gray-table px-6 py-4 text-center text-lg font-bold tracking-wider text-white">
                          Malas
                        </th>
                        <th className="bg-gray-table px-3 py-4 text-center text-lg font-bold tracking-wider text-white">
                          Blanco
                        </th>
                        <th className="rounded-r-xl bg-gray-table px-3 py-4 text-center text-lg font-bold tracking-wider text-white">
                          Acción
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {assignment.result.sections.map((section, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0
                              ? 'bg-gray-tableLight'
                              : 'bg-gray-body'
                          } overflow-hidden rounded-xl`}
                        >
                          <td className="whitespace-nowrap rounded-l-xl px-6 py-4 text-center text-lg text-gray-700">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-center text-lg text-gray-700">
                            {assignment.sections[index].course || '-'}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-center text-lg text-gray-700">
                            {(
                              (section.total /
                                section.questions.reduce(
                                  (a, b) => a + b.value,
                                  0
                                )) *
                              20
                            ).toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-center text-lg text-gray-700">
                            {(
                              (section.total /
                                section.questions.reduce(
                                  (a, b) => a + b.value,
                                  0
                                )) *
                              100
                            ).toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-center text-lg text-gray-700">
                            {section.correct || '0'}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-center text-lg text-gray-700">
                            {section.incorrect || '0'}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-center text-lg text-gray-700">
                            {section.empty || '0'}
                          </td>
                          <td className="whitespace-nowrap rounded-r-xl px-2 py-4 text-center text-lg text-gray-700">
                            <span
                              className="mt-1 ml-3 cursor-pointer text-lg text-light-blue underline hover:text-light-blue-light sm:col-span-3 sm:mt-0"
                              onClick={() => {
                                setCurrentSection(index);
                                setIsSectionResultOpen(true);
                              }}
                            >
                              Ver respuestas
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="m-10 h-0.5 bg-gray-200" />

                <div className="mb-10 flex flex-col items-center justify-center gap-8 px-4 lg:flex-row">
                  <p className="text-2xl font-bold text-primary">
                    CONCLUSIONES
                  </p>
                  {/* Heroicon name: solid/arrow-narrow-right */}
                  <svg
                    className="hidden h-10 w-10 lg:block"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                  <p className="text-center text-xl">
                    {getSummaryMessage(assignment.result)}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
