import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { Transition } from '@headlessui/react';

import PaginationApi from '../../utils/paginationApi';
import StorageService from '../../utils/storage';

import LoadingSpinner from '../../components/LoadingSpinner';
import Pagination from '../../components/Pagination';
import { showErrorToast } from '../../components/Toast';
import { constants } from '../../utils/constants';
import { formatDate } from '../../utils/functions';

export default function VoucherList() {
  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [vouchers, setVouchers] = useState([]);

  // Filters
  const [searchFilter, setSearchFilter] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 10;

  const vouchersApi = new PaginationApi(
    '/classroom/vouchers/list',
    amountPerPage,
    auth,
    history
  );

  useEffect(() => {
    getVouchers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, page]);

  let getVouchers = async () => {
    setLoading(true);
    let result = await vouchersApi.getContent(page, {
      student: StorageService.get('chosenStudent'),
      search: searchFilter,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setVouchers(result.data.vouchers);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setLoading(false);
  };

  return (
    <div className="mx-4 my-8 rounded-2xl bg-white md:mx-16 md:my-12">
      <div className="px-4 py-10 md:px-12">
        <div className="mb-10">
          <label htmlFor="inp_search_filter" className="sr-only">
            Ingresa tu búsqueda
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              id="inp_search_filter"
              name="search_filter"
              type="search"
              placeholder="Ingresa tu búsqueda"
              value={searchFilter}
              onChange={(e) => {
                setSearchFilter(e.target.value);
                getVouchers();
              }}
              className="inp-base pr-10"
            ></input>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              {/* Heroicon name: mail */}
              <svg
                className="h-6 w-6 text-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
          <table className="min-w-full divide-gray-200">
            <thead>
              <tr>
                <th className="rounded-l-xl bg-primary px-6 py-3 text-center text-lg font-medium tracking-wider text-white">
                  Fecha de emisión
                </th>
                <th className="bg-primary px-6 py-3 text-center text-lg font-medium tracking-wider text-white">
                  Comprobante
                </th>
                <th className="bg-primary px-6 py-3 text-center text-lg font-medium tracking-wider text-white">
                  Serie
                </th>
                <th className="bg-primary px-6 py-3 text-center text-lg font-medium tracking-wide text-white">
                  Monto
                </th>
                <th className="bg-primary px-6 py-3 text-center text-lg font-medium tracking-wider text-white">
                  DNI/RUC
                </th>
                <th className="bg-primary px-6 py-3 text-center text-lg font-medium tracking-wider text-white">
                  Nombre del cliente
                </th>
                <th className="rounded-r-xl bg-primary px-3 py-3 text-center text-lg font-medium tracking-wider text-white">
                  PDF
                </th>
              </tr>
            </thead>
            <Transition show={!loading} as="tbody" className="bg-white">
              {vouchers.map((obj, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-body'}
                >
                  <td className="whitespace-nowrap rounded-l-xl px-6 py-3 text-center text-lg text-gray-700">
                    {formatDate(obj.pay_date, 'dd/MM/yyyy')}
                  </td>
                  <td className="whitespace-nowrap px-6 py-3 text-center text-lg text-gray-700">
                    {constants.DOCUMENT_DETAIL[obj.doc_type]}
                  </td>
                  <td className="whitespace-nowrap px-6 py-3 text-center text-lg text-gray-700">
                    {obj.doc_serie}
                  </td>
                  <td className="whitespace-nowrap px-6 py-3 text-center text-lg text-gray-700">
                    s/. {obj.paid}
                  </td>
                  <td className="whitespace-nowrap px-6 py-3 text-center text-lg text-gray-700">
                    {obj.client_number}
                  </td>
                  <td className="whitespace-nowrap px-6 py-3 text-center text-lg text-gray-700">
                    {obj.client_name}
                  </td>
                  <td className="rounded-r-xl">
                    {obj.link_pdf ? (
                      <a href={obj.link_pdf} target="_blank" rel="noreferrer">
                        <svg
                          className="mx-auto h-8 w-8 text-light-blue hover:text-light-blue-dark"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      </a>
                    ) : null}
                  </td>
                </tr>
              ))}
            </Transition>
          </table>
        </div>
        <Transition show={loading} className="flex items-center bg-white p-6">
          <LoadingSpinner color="primary"></LoadingSpinner>
        </Transition>
        <Transition
          show={!loading && vouchers.length === 0}
          className="flex items-center bg-white p-6"
        >
          <p className="text-lg">No se encontraron comprobantes</p>
        </Transition>
        {/* Pagination */}
        <Pagination
          className="mt-8"
          page={page}
          total={total}
          maxPage={maxPage}
          onPreviousPage={() => {
            setPage(page - 1);
          }}
          onNextPage={() => {
            setPage(page + 1);
          }}
          onSelectedPage={(newPage) => {
            setPage(newPage);
          }}
        />
      </div>
    </div>
  );
}
