import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

import GeneralApi from '../../utils/generalApi';
import SolidButton from '../../components/SolidButton';
import { showErrorToast } from '../../components/Toast';
import QuestionItem from './QuestionItem';
import { formatDate } from '../../utils/functions';
import LoadingSpinner from '../../components/LoadingSpinner';

export default function ForumPage() {
  const history = useHistory();
  const auth = useAuth();

  let { courseId } = useParams();

  const [loading, setLoading] = useState(true);
  const [topic, setTopic] = useState('-');
  const [questions, setQuestions] = useState([]);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  let getQuestions = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/questions/list`, {
      course_id: courseId,
      topic,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    setQuestions(result.data);
    setLoading(false);
  };

  return (
    <div className="relative">
      <div className="top-0 justify-between space-y-4 border-b-2 border-gray-300 bg-gray-body px-6 py-6 sm:sticky sm:flex sm:space-y-0 sm:px-12">
        <div className="flex items-center">
          <svg
            className="mr-2 h-12 w-12 text-primary group-hover:text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            />
          </svg>
          <span className="text-2xl font-light text-primary">Foro</span>
        </div>
        <div className="items-center space-y-2 sm:flex sm:space-y-0 sm:space-x-6">
          <div className="flex max-w-sm flex-1 items-center">
            <label
              htmlFor="inp_report_kind_filter"
              className="text-lg text-gray-600"
            >
              Curso:
            </label>
            <div className="ml-4 w-40 flex-1">
              <select
                id="inp_report_kind_filter"
                name="report_kind_filter"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                className="inp-base w-full"
              >
                <option value="-">Todos</option>
                <option>Aritmética</option>
                <option>Álgebra</option>
                <option>Geometría</option>
                <option>Trigonometría</option>
                <option>Física</option>
                <option>Química</option>
                <option>Biología</option>
                <option>Raz. Matemático</option>
                <option>Raz. Verbal</option>
                <option>Historia</option>
                <option>Psicología</option>
                <option>Cívica</option>
                <option>Economía</option>
                <option>Lenguaje</option>
                <option>Literatura</option>
                <option>Filosofía</option>
                <option>Geografía</option>
                <option>Inglés</option>
                <option>Historia Universal</option>
                <option>Historia del Perú</option>
                <option>Anatomía</option>
                <option>Hab. Verbal</option>
                <option>Hab. Lógico Matemático</option>
                <option>Estadística</option>
                <option>Números y Operaciones</option>
                <option>Vocabulario</option>
                <option>Lectura Comprensiva e Interpretativa</option>
                <option>Lectura Critica</option>
                <option>Ortografía y Puntuación</option>
                <option>Matemáticas</option>
                <option>Ciencias</option>
                <option>Humanidades</option>
              </select>
            </div>
          </div>
          <div className="flex max-w-xs items-center">
            <SolidButton
              color="light-blue"
              href={`/forum/${courseId}/question`}
            >
              CREAR PREGUNTA
            </SolidButton>
          </div>
          <div className="flex max-w-xs items-center">
            <SolidButton href={`/my_courses/${courseId}/home`}>
              REGRESAR
            </SolidButton>
          </div>
        </div>
      </div>
      <div className="px-4 py-4 md:px-12">
        <p className="mb-4 text-2xl">{topic === '-' ? 'Todos' : topic}</p>
        {loading ? (
          <LoadingSpinner content="Buscando preguntas" color="primary" />
        ) : questions.length === 0 ? (
          <p>No se encontraron preguntas</p>
        ) : (
          <div className="divide-y-2 divide-gray-300 bg-white shadow-lg">
            {questions.map((obj) => (
              <QuestionItem
                key={obj._id}
                courseId={courseId}
                questionId={obj._id}
                userName={
                  obj.teacher
                    ? `${obj.teacher.name} ${obj.teacher.last_name_p}`
                    : `${obj.student.name} ${obj.student.last_name_p}`
                }
                date={formatDate(obj.created_at, 'dd/MM/yyyy', false)}
                time={formatDate(obj.created_at, 'hh:mm aa', false)}
                imageUrl={obj.image_url}
                title={obj.title}
                content={obj.content}
                topic={obj.topic}
                contentIsHtml={obj.contentIsHtml}
                answers={obj.answers.length}
                lastAnswerUserName={
                  obj.answers.length === 0
                    ? null
                    : obj.answers[0].teacher
                    ? `${obj.answers[0].teacher.name} ${obj.answers[0].teacher.last_name_p}`
                    : `${obj.answers[0].student.name} ${obj.answers[0].student.last_name_p}`
                }
                lastAnswerDate={
                  obj.answers.length === 0
                    ? null
                    : formatDate(obj.answers[0].created_at, 'dd/MM/yyyy', false)
                }
                lastAnswerTime={
                  obj.answers.length === 0
                    ? null
                    : formatDate(obj.answers[0].created_at, 'hh:mm aa', false)
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
