import { Link } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

const sizeClasses = {
  sm: 'py-2 px-2',
  md: 'py-3 px-4',
};

export default function SolidButton({
  children,
  className = '',
  isLoading = false,
  disabled = false,
  color = 'primary',
  textColor = 'white',
  size = 'md',
  type = 'button',
  widthFull = true,
  href,
  onClick,
  style,
}) {
  return href ? (
    <Link
      to={disabled ? '' : href}
      className={`${className} ${sizeClasses[size]} ${
        disabled ? 'pointer-events-none' : ''
      } ${
        widthFull && 'w-full'
      } flex justify-center rounded-xl border border-transparent text-xl font-medium shadow-sm text-${textColor} bg-${color} hover:bg-${color}-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-light`}
    >
      {children}
    </Link>
  ) : (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`${className} ${sizeClasses[size]} ${widthFull && 'w-full'} ${
        disabled
          ? 'bg-gray-500 text-white'
          : `text-${textColor} bg-${color} hover:bg-${color}-dark`
      } focus:outline-none flex justify-center rounded-xl border border-transparent text-xl font-medium shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-${color}-light`}
      style={style}
    >
      {isLoading ? <LoadingSpinner></LoadingSpinner> : children}
    </button>
  );
}
