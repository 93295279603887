import { useEffect } from 'react';

import SolidButton from '../../../components/SolidButton';
import StudentForm from './StudentForm';

export default function Step3({
  register,
  watch,
  getValues,
  setValue,
  errors,
  students,
  append,
  remove,
}) {
  const isDelegation = watch('isDelegation', false);

  useEffect(() => {
    if (students.length === 0) {
      append({
        docType: '',
        docNumber: '',
        lastNameP: '',
        lastNameM: '',
        name: '',
        email: '',
        phone: '',
        cellphone: '',
        genre: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <p className="mb-4">
        Completa los siguientes campos
        {isDelegation ? ' de los alumnos' : ' del alumno'}
      </p>

      {students.map((field, index) => (
        <StudentForm
          key={field.id}
          index={index}
          amount={students.length}
          register={register}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          onRemove={() => remove(index)}
        />
      ))}

      {isDelegation && (
        <SolidButton
          className="mx-auto mt-8 max-w-sm"
          size="sm"
          onClick={() => {
            append({
              docType: '',
              docNumber: '',
              lastNameP: '',
              lastNameM: '',
              name: '',
              email: '',
              phone: '',
              cellphone: '',
              genre: '',
            });
          }}
        >
          AGREGAR ESTUDIANTE +
        </SolidButton>
      )}
    </>
  );
}
