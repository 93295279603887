import logicalSchoolLogo from '../resources/img/logos/logical_school.png';
import educatronicLogo from '../resources/img/logos/logo_educatronic.png';
import arkitedLogo from '../resources/img/logos/logo_arkited.png';
import logicalLogo from '../resources/img/logos/logo_logical.png';
import metrikLogo from '../resources/img/logos/metrik.png';

import logicalSchoolLogo2 from '../resources/img/logos/logical.png';
import educatronicLogo2 from '../resources/img/logos/educatronic.png';
import arkitedLogo2 from '../resources/img/logos/arkited.png';
import logicalLogo2 from '../resources/img/logos/logical.png';

const environment = process.env.NODE_ENV;

export const constants = {
  API_URL:
    environment === 'production'
      ? 'https://admin.edtlab.edu.pe/api'
      : 'http://192.168.1.36:9000/api',
  //'http://admin.edtlab.pe/api',

  IZIPAY_KEY:
    environment === 'production'
      ? '60921945:publickey_FzrXMe9n2JKlKAwBU8ObiIepkiPLqCS2soXRchxiodJFC'
      : '60921945:testpublickey_rsR53XPlFYgjuH3AvOdyVRhTUNCgqjFmYm7gTQJe22kIi',

  ERROR_MESSAGES: {
    REQUIRED: 'Este campo es obligatorio',
    REQUIRED_PLURAL: 'Este campo es obligatorio',
    WRONG_EMAIL: 'Ingrese un correo válido',
    DIGITS_ONLY: 'Ingrese solo números',
    LENGTH: (length) => `Ingrese ${length} caracteres`,
    MIN_LENGTH: (minLength) => `Ingrese como mínimo ${minLength} caracteres`,
    MAX_LENGTH: (maxLength) => `Ingrese como máximo ${maxLength} caracteres`,
  },

  SCHEDULE_DAYS: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],

  DOCUMENT_DETAIL: {
    0: 'Boleta',
    1: 'Factura',
    2: 'Ticket de Venta',
    3: 'Nota de Crédito',
    4: 'Nota de Débito',
  },

  ATTENDANCE_COLORS: {
    0: 'red-600',
    1: 'primary',
    2: 'light-blue',
  },

  TURNS: {
    morning: 'Mañana',
    afternoon: 'Tarde',
  },

  VIDEO_KINDS: {
    recorded: 'Clase grabada',
    english: 'Inglés',
    recovery: 'Recuperación',
  },

  SCHOOLS_FORMAT: {
    Arkited: {
      logo: arkitedLogo,
      logoSmall: arkitedLogo2,
      color: 'green',
      borderColor: 'green',
    },
    Educatronic: {
      logo: educatronicLogo,
      logoSmall: educatronicLogo2,
      color: 'orange',
      borderColor: 'orange',
    },
    Logical: {
      logo: logicalLogo,
      logoSmall: logicalLogo2,
      color: 'primary',
      borderColor: 'light-blue',
    },
    'Colegio Logical': {
      logo: logicalSchoolLogo,
      logoSmall: logicalSchoolLogo2,
      color: 'light-blue',
      borderColor: 'light-blue',
    },
    Metrik: {
      logo: metrikLogo,
      logoSmall: metrikLogo,
      color: 'black',
      borderColor: 'black',
    },
  },

  EVENT_KINDS: {
    seminar: { name: 'Seminarios', color: '#500D78' },
    recovery: { name: 'Recuperaciones', color: '#008DFF' },
    chat: { name: 'Charlas', color: '#FFDD00' },
    cultural_visit: { name: 'Visitas culturales', color: '#FE6205' },
    exam: { name: 'Exámenes', color: '#A5A5A5' },
    workshop: { name: 'Talleres', color: '#55863D' },
  },

  TINY_INIT: {
    height: 300,
    menubar: false,
    language: 'es_419',
    placeholder: 'Ingresar texto',
    language_url: '/langs/es_419.js',
    plugins: ['advlist lists'],
    toolbar:
      'undo redo | formatselect | ' +
      'bold italic backcolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ' +
      'removeformat',
    content_style:
      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  },
};
