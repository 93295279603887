import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../../contexts/Auth';
import GeneralApi from '../../../../utils/generalApi';

import SolidButton from '../../../../components/SolidButton';

import { buildCourseName, formatDate } from '../../../../utils/functions';
import { showErrorToast } from '../../../../components/Toast';

export default function Step2({
  course,
  pendingPayments,
  selectedPayments,
  setSelectedPayments,
  discountCode,
  setDiscountCode,
  discountAmount,
  setDiscountAmount,
  buttonLoading,
  onContinue,
}) {
  const history = useHistory();
  const auth = useAuth();
  const generalApi = new GeneralApi(auth, history);

  const [totalAmount, setTotalAmount] = useState(
    pendingPayments?.reduce(
      (a, b, currentIndex) =>
        !b.paid && currentIndex <= selectedPayments ? a + b.amount : a,
      0
    ) + (!pendingPayments?.[0]?.paid ? course.enroll_cost?.suggested || 0 : 0)
  );
  const [discountLoading, setDiscountLoading] = useState(false);

  const updateTotal = (index) => {
    setTotalAmount(
      pendingPayments.reduce(
        (a, b, currentIndex) =>
          !b.paid && currentIndex <= index ? a + b.amount : a,
        0
      ) + (!pendingPayments?.[0]?.paid ? course.enroll_cost?.suggested || 0 : 0)
    );
    setSelectedPayments(index);
  };
  const searchDiscount = async () => {
    if (discountCode.length < 6) {
      return setDiscountAmount(0);
    } else if (discountCode.length > 6) {
      return setDiscountCode(discountCode.substr(0, 6));
    }
    setDiscountLoading(true);
    const result = await generalApi.get(
      `/classroom/promo_code/${discountCode}`
    );
    if (!result.success) {
      showErrorToast(result.message);
      setDiscountLoading(false);
      return;
    }
    setDiscountLoading(false);
    if (
      !result.data ||
      !result.data.promo_code ||
      !result.data.promo_code.discount ||
      !result.data.promo_code.is_active
    )
      return setDiscountAmount(0);
    setDiscountAmount(result.data.promo_code.discount);
  };

  useEffect(() => {
    searchDiscount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCode]);

  return (
    <div>
      <p className="text-primary">Seleccione los pagos a realizar de:</p>
      <p className="text-xl font-semibold text-primary">
        {course && buildCourseName(course)}
      </p>
      <div className="-mx-4 mt-6 min-w-full overflow-hidden overflow-x-auto align-middle shadow-md">
        <table className="min-w-full divide-gray-200">
          <thead>
            <tr>
              <th className="bg-light-blue px-6 py-4 text-left text-lg font-light uppercase tracking-wider text-white">
                Concepto
              </th>
              <th className="bg-light-blue px-6 py-4 text-left text-lg font-light uppercase tracking-wider text-white">
                Monto (S/)
              </th>
              <th className="bg-light-blue px-6 py-4 text-left text-lg font-light uppercase tracking-wider text-white">
                Fecha de Vencimiento
              </th>
              <th className="bg-light-blue px-3 py-4 text-center text-lg font-light uppercase tracking-wider text-white"></th>
            </tr>
          </thead>
          <tbody>
            {!pendingPayments?.[0]?.paid &&
            course.enroll_cost?.suggested > 0 ? (
              <tr className="bg-white">
                <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                  Matrícula
                </td>
                <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                  {course.enroll_cost.suggested?.toFixed(2)}
                </td>
                <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                  -
                </td>
                <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                  <input
                    id={`to_pay_enroll`}
                    name={`to_pay_enroll`}
                    type="checkbox"
                    disabled={true}
                    checked={true}
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                  />
                </td>
              </tr>
            ) : (
              ''
            )}
            {pendingPayments.map(
              (obj, index) =>
                !obj.paid && (
                  <tr
                    key={index}
                    className={
                      index % 2 === (course.enroll_cost?.suggested > 0 ? 1 : 0)
                        ? 'bg-white'
                        : 'bg-gray-body'
                    }
                  >
                    <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                      {`Derecho de enseñanza ${
                        !pendingPayments?.[0]?.paid &&
                        course.enroll_cost?.suggested > 0
                          ? index
                          : index + 1
                      }`}
                    </td>
                    <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                      {obj.amount?.toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                      {formatDate(obj.expires_at, 'dd/MM/yyyy')}
                    </td>
                    <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                      <input
                        id={`to_pay_${index}`}
                        name={`to_pay_${index}`}
                        type="checkbox"
                        disabled={
                          pendingPayments.filter((obj) => !obj.paid).length ===
                          index
                        }
                        checked={selectedPayments >= index}
                        onChange={(e) =>
                          updateTotal(e.target.checked ? index : index - 1)
                        }
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-6 w-full items-center justify-between sm:flex">
        <div>
          <label htmlFor="inp_coupon_code" className="text-primary">
            Tengo un cupón de descuento
          </label>
          <div className="mt-1">
            <input
              id="inp_coupon_code"
              name="coupon_code"
              type="text"
              placeholder="Cupón de descuento"
              className="inp-base"
              value={discountCode}
              onChange={(event) => setDiscountCode(event.target.value)}
            ></input>
          </div>
        </div>
        {discountAmount > 0 && (
          <div className="text-lg text-primary">
            <p className="font-bold">Descuento</p>
            <p>S/ {discountAmount.toFixed(2) || '0'}</p>
          </div>
        )}
        <div className="text-lg text-primary">
          <p className="font-bold">Total</p>
          <p>S/ {(totalAmount - discountAmount).toFixed(2) || '0.00'}</p>
        </div>
      </div>
      <SolidButton
        size="sm"
        type="button"
        className="mt-4 ml-auto max-w-xs"
        disabled={discountLoading}
        isLoading={buttonLoading}
        onClick={() => onContinue()}
      >
        AGREGAR AL CARRITO
      </SolidButton>
    </div>
  );
}
