import SidebarItem from './SidebarItem';

import accountIcon from '../../resources/img/icons/account.png';
import accountWhiteIcon from '../../resources/img/icons/account_white.png';
import profileIcon from '../../resources/img/icons/profile.png';
import profileWhiteIcon from '../../resources/img/icons/profile_white.png';
import facturaIcon from '../../resources/img/icons/factura.png';
import facturaWhiteIcon from '../../resources/img/icons/factura_white.png';
import searchIcon from '../../resources/img/icons/search.png';
import searchWhiteIcon from '../../resources/img/icons/search_white.png';

const SIDEBAR_ITEMS = [
  {
    enabled: true,
    name: 'my_courses',
    path: '/my_courses',
    title: 'Aula Virtual',
    icon: accountWhiteIcon,
    selectedIcon: accountIcon,
  },
  {
    enabled: true,
    name: 'profile',
    path: '/profile',
    title: 'Mi perfil',
    icon: profileWhiteIcon,
    selectedIcon: profileIcon,
  },
  {
    enabled: true,
    name: 'vouchers',
    path: '/vouchers',
    title: 'Comprobantes',
    icon: facturaWhiteIcon,
    selectedIcon: facturaIcon,
  },
  {
    enabled: true,
    name: 'courses',
    path: '/courses',
    title: 'Buscar programas',
    icon: searchWhiteIcon,
    selectedIcon: searchIcon,
  },
];

export default function SidebarItems({ className, onCloseClick }) {
  return (
    <nav className={className}>
      {SIDEBAR_ITEMS.map((obj) => {
        if (!obj.enabled) return null;
        return (
          <SidebarItem
            key={obj.name}
            path={obj.path}
            onClick={onCloseClick}
            icon={obj.icon}
            selectedIcon={obj.selectedIcon}
          >
            {obj.title}
          </SidebarItem>
        );
      })}
    </nav>
  );
}
