import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Switch } from '@headlessui/react';

import SolidButton from '../../../components/SolidButton';
import TutorForm from './TutorForm';

export default function Step2({
  register,
  setValue,
  watch,
  control,
  errors,
  tutors,
  append,
  remove,
}) {
  const isDelegation = watch('isDelegation', false);

  useEffect(() => {
    if (tutors.length === 0) {
      append({
        docType: '',
        docNumber: '',
        lastNameP: '',
        lastNameM: '',
        name: '',
        email: '',
        phone: '',
        cellphone: '',
        specialty: '',
        relation: '',
        level1: false,
        level2: false,
        level3: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mb-4 gap-4 space-y-2 sm:grid sm:grid-cols-4 sm:space-y-0 lg:grid-cols-6">
        <Controller
          name="isDelegation"
          control={control}
          defaultValue={false}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref } }) => (
            <Switch.Group>
              <div className="flex items-center justify-center py-2 sm:col-span-6">
                <Switch.Label className="mr-4">Individual</Switch.Label>
                <Switch
                  ref={ref}
                  checked={value}
                  onChange={(e) => {
                    onChange(e);
                    remove([...Array(tutors.length).keys()].slice(1));
                  }}
                  className={`${
                    value ? 'bg-primary' : 'bg-gray-200'
                  } focus:outline-none relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:ring-2 focus:ring-primary focus:ring-offset-2`}
                >
                  <span
                    className={`${
                      value ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
                <Switch.Label className="ml-4">Delegación</Switch.Label>
              </div>
            </Switch.Group>
          )}
        />

        <p className="col-span-4 lg:col-span-6">
          Completa los siguientes campos
          {isDelegation ? ' de los docentes asesores' : ' del apoderado'}
        </p>
      </div>

      {tutors.map((field, index) => (
        <TutorForm
          key={field.id}
          index={index}
          amount={tutors.length}
          register={register}
          setValue={setValue}
          watch={watch}
          errors={errors}
          onRemove={() => remove(index)}
        />
      ))}

      {isDelegation && (
        <SolidButton
          className="mx-auto mt-8 max-w-sm"
          size="sm"
          onClick={() => {
            append({
              docType: '',
              docNumber: '',
              lastNameP: '',
              lastNameM: '',
              name: '',
              email: '',
              phone: '',
              cellphone: '',
              specialty: '',
              relation: '',
              level1: false,
              level2: false,
              level3: false,
            });
          }}
        >
          AGREGAR ASESOR +
        </SolidButton>
      )}
    </>
  );
}
