import { RadioGroup } from '@headlessui/react';

const allLetters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
const answerLetters = ['A', 'B', 'C', 'D', 'E', ''];

export default function OptionsQuestionItem({
  answers,
  content,
  imageUrl,
  chosenAnswer,
  onAnswerChange,
}) {
  return (
    <div className="flex flex-col sm:pb-9">
      <div>
        {content && (
          <p
            className="mt-2 rounded-xl bg-gray-200 px-4 py-2 text-xl"
            dangerouslySetInnerHTML={{ __html: content || '' }}
          />
        )}
        {imageUrl ? (
          <img
            src={imageUrl}
            className="mx-auto mt-2 rounded-xl"
            alt={content || ''}
          />
        ) : null}
      </div>
      <RadioGroup
        className="mt-4"
        value={chosenAnswer}
        onChange={onAnswerChange}
      >
        <RadioGroup.Label className="text-lg">
          Elegir respuesta:
        </RadioGroup.Label>
        <div className={`mt-1 flex-wrap sm:flex`}>
          {answers
            ? answers.map((obj, index) => (
                <RadioGroup.Option key={obj._id} value={allLetters[index]}>
                  {({ active, checked }) => (
                    <div className="mx-6 my-2 flex items-center space-x-3 sm:my-4">
                      <span
                        className={`${
                          active
                            ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-300'
                            : ''
                        }
              ${
                checked
                  ? 'bg-light-blue-900 bg-primary-light text-white'
                  : 'bg-white text-primary hover:bg-purple-200'
              }
                focus:outline-none relative flex cursor-pointer justify-center self-start rounded-xl border-2 border-primary-dark py-2 px-4 text-2xl font-bold shadow-md`}
                      >
                        {allLetters[index]}
                      </span>
                      {obj.content && (
                        <span className="rounded-xl bg-gray-200 px-4 py-2 text-xl">
                          {obj.content}
                        </span>
                      )}
                      {obj.image_url ? (
                        <img
                          src={obj.image_url}
                          className="mx-auto h-24 rounded-xl bg-gray-200 p-1 text-xl"
                          alt={obj.content || ''}
                        />
                      ) : null}
                    </div>
                  )}
                </RadioGroup.Option>
              ))
            : answerLetters.map((obj) => (
                <RadioGroup.Option key={obj} value={obj}>
                  {({ active, checked }) => (
                    <span
                      className={`${
                        active
                          ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-300'
                          : ''
                      }
                ${
                  checked
                    ? 'bg-light-blue-900 bg-primary-light text-white'
                    : 'bg-white text-primary hover:bg-purple-200'
                }
                  focus:outline-none relative flex cursor-pointer justify-center rounded-xl border-2 border-primary-dark py-2 px-5 text-2xl font-bold shadow-md sm:py-3`}
                    >
                      {obj !== '' ? obj : 'Sin respuesta'}
                    </span>
                  )}
                </RadioGroup.Option>
              ))}
        </div>
      </RadioGroup>
    </div>
  );
}
