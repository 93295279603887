import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { formatDate } from '../../../../utils/functions';
import { checkFileType } from '../../../../utils/fileUtils';

const getWeekNumber = (courseDate, weekDate) => {
  const courseDateObj = new Date(courseDate);
  const weekDateObj = new Date(weekDate);
  const diffTime = Math.abs(weekDateObj - courseDateObj);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return Math.ceil(diffDays / 7);
};

export default function ContentCard({
  openAtStart = false,
  courseDate,
  date,
  description,
  attachments,
  links,
}) {
  const [isContentItemOpen, setIsContentItemOpen] = useState(openAtStart);

  return (
    <div className="mt-4 rounded-xl border border-gray-300">
      <div
        className={`${
          isContentItemOpen
            ? 'rounded-t-xl bg-primary text-white'
            : 'rounded-xl'
        } space-between flex items-center px-6 py-4 text-xl transition-all`}
      >
        <div className="flex-1 justify-between sm:flex sm:pr-8">
          <div className="flex justify-between sm:gap-x-6">
            <dt className="text-lg font-normal">
              Semana{' '}
              {courseDate && date ? getWeekNumber(courseDate, date) : '-'}
            </dt>
            <div className="h-full w-px bg-white" />
            <dd className="mt-1 text-lg sm:mt-0">{description}</dd>
          </div>
          <div className="text-lg sm:mt-0">
            {formatDate(date, 'dd/MM/yyyy')}
          </div>
        </div>
        <div
          className="cursor-pointer justify-self-end rounded-full p-1 transition-colors hover:bg-gray-200 hover:text-light-blue sm:col-span-1"
          onClick={() => setIsContentItemOpen(!isContentItemOpen)}
        >
          <svg
            className="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {isContentItemOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 15l7-7 7 7"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            )}
          </svg>
        </div>
      </div>

      <Transition
        show={isContentItemOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="rounded-b-xl bg-white text-xl"
      >
        {links && links.length > 0 ? (
          <div className="border-b border-gray-300 px-6 py-8 sm:grid sm:grid-cols-4 sm:gap-4">
            <div className="mt-1 cursor-pointer text-lg text-light-blue underline sm:col-span-3 sm:mt-0">
              {links.map((obj, index) => (
                <a
                  key={index}
                  className="block hover:text-light-blue-light"
                  href={obj}
                  target="_blank"
                  rel="noreferrer"
                >
                  {obj}
                </a>
              ))}
            </div>
          </div>
        ) : null}
        {attachments && attachments.length > 0 && (
          <div className="px-6 py-8">
            <div className="mt-2 grid-cols-3 sm:grid">
              <div className="col-span-2 grid grid-cols-1 gap-4 sm:grid-cols-2">
                {attachments.map((obj) => (
                  <a
                    key={obj.url}
                    className="group flex"
                    href={obj.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {checkFileType(obj.name) === 'PDF' ? (
                      <Document
                        file={obj.url}
                        className="border-primary-200 max-h-24 min-w-2/7 overflow-hidden rounded-xl rounded-r-none border-2 border-r-0 p-1"
                        renderMode="svg"
                      >
                        <Page pageNumber={1} width={150} className="mx-auto" />
                      </Document>
                    ) : (
                      <div className="border-primary-200 flex max-h-24 min-w-2/7 items-center justify-center overflow-hidden rounded-xl rounded-r-none border-2 border-r-0 p-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-12 w-12 text-primary-light"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      </div>
                    )}
                    <div className="border-primary-200 flex w-full max-w-5/7 flex-col justify-center rounded-xl rounded-l-none border-2 p-4 text-gray-700 transition-all group-hover:bg-gray-50">
                      <p className="truncate text-base font-semibold transition-all group-hover:text-primary-light">
                        {obj.name}
                      </p>
                      <p className="text-sm">{checkFileType(obj.name)}</p>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
}
