import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import KRGlue from '@lyracom/embedded-form-glue';

import { useAuth } from '../../../../contexts/Auth';
import GeneralApi from '../../../../utils/generalApi';

import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import Modal from '../../../../components/Modal';

import { constants } from '../../../../utils/constants';
import StorageService from '../../../../utils/storage';

export default function Step4({ lines, onContinue }) {
  const history = useHistory();
  const auth = useAuth();
  const generalApi = new GeneralApi(auth, history);

  const [formLoading, setFormLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [formToken, setFormToken] = useState('');

  useEffect(() => {
    submitCreateCharge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formToken !== '') loadForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formToken]);

  let submitCreateCharge = async () => {
    setFormLoading(true);
    const result = await generalApi.post(`/payment/charge/bulk`, {
      student: StorageService.get('chosenStudent'),
      lines,
      paid: (
        lines.reduce((a, b) => a + b.total - (b.discount_amount || 0), 0) * 100
      ).toString(),
    });
    if (!result.success) {
      setFormLoading(false);
      showErrorToast(result.message);
      return;
    }
    setFormToken(result.data.formToken);
    setFormLoading(false);
  };

  let loadForm = () => {
    const publicKey = constants.IZIPAY_KEY;
    const endPoint = 'https://static.micuentaweb.pe';

    KRGlue.loadLibrary(endPoint, publicKey)
      .then(({ KR }) =>
        KR.setFormConfig({
          formToken,
          'kr-language': 'es',
        })
      )
      .then(({ KR }) => KR.addForm('#payment-form'))
      .then(({ KR, result }) => KR.showForm(result.formId))
      .then(({ KR }) =>
        KR.onSubmit((event) => {
          onPaid(KR, event);
        })
      )
      .catch((error) =>
        console.log({
          promiseError: error + ' (see console for more details)',
        })
      );
  };

  let onPaid = (KR, event) => {
    if (event.clientAnswer.orderStatus === 'PAID') {
      KR.removeForms();

      submitPayment(event.clientAnswer);
    } else {
      showErrorToast('Ocurrió un error al procesar el pago');
    }
  };

  let submitPayment = async (iziData) => {
    setSubmitLoading(true);
    const result = await generalApi.post(`/payment/izipay/bulk`, {
      student: StorageService.get('chosenStudent'),
      doc_type: 'Boleta',
      client_document: StorageService.get('chosenStudentData').docType,
      client_number: StorageService.get('chosenStudentData').docNumber,
      client_name: `${StorageService.get('chosenStudentData').firstName} ${
        StorageService.get('chosenStudentData').lastName
      }`,
      payment_kind: 'Tarjeta',
      payment_code: '',
      izi_data: iziData,
      lines_full: lines,
      lines: lines.reduce(
        (a, b) => [
          ...a,
          ...b.payments
            .filter((obj) => obj.to_pay)
            .map((obj) => ({
              code: obj.name,
              is_enroll_payment: obj.is_enroll_payment
                ? obj.is_enroll_payment
                : false,
              amount: obj.amount,
            })),
        ],
        []
      ),
      paid: lines
        .reduce((a, b) => a + b.total - (b.discount_amount || 0), 0)
        .toString(),
      discount: lines
        .reduce((a, b) => a + (b.discount_amount || 0), 0)
        .toString(),
    });
    setSubmitLoading(false);
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    showSuccessToast('Pago completado!');
    emitPayment(result.data.payment_id);
    onContinue(result.data.link_pdf || '');
  };

  let emitPayment = async (paymentId) => {
    const result = await generalApi.post(`/payment/emit`, {
      paymentId,
    });
    if (!result.success) {
      console.log(result.message);
    }
  };

  return (
    <div>
      {formLoading && (
        <div className="flex w-full justify-center py-8">
          <LoadingSpinner
            color="primary"
            content="Cargando formulario de pago"
          />
        </div>
      )}
      <Modal isOpen={submitLoading}>
        <div className="flex justify-center rounded-lg border-2 border-primary bg-white py-8">
          <LoadingSpinner color="primary" content="Procesando pago" />
        </div>
      </Modal>
      <div id="payment-form"></div>
    </div>
  );
}
