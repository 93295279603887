import axios from 'axios';
import React, { useContext, createContext, useState } from 'react';
import { constants } from '../utils/constants';
import StorageService from '../utils/storage';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    StorageService.get('userData') != null
  );
  const [shoppingCartLines, setShoppingCartLines] = useState(
    StorageService.get('shopping_cart_lines')
  );

  const signin = async (accessValue, password) => {
    try {
      const result = await axios.post(`${constants.API_URL}/classroom/login`, {
        username: accessValue,
        password,
      });
      StorageService.set('userData', result.data.user);
      StorageService.set('isAuthenticated', true);
      StorageService.set('accessToken', result.data.accessToken);
      StorageService.set('refreshToken', result.data.refreshToken);
      StorageService.set('isProxy', result.data.user.isProxy);
      if (result.data.user.isProxy) {
        StorageService.set('students', result.data.students);
        if (result.data.students.length > 0) {
          StorageService.set('chosenStudent', result.data.students[0]._id);
          StorageService.set('chosenStudentData', result.data.students[0]);
          StorageService.set(
            'shopping_cart_lines',
            result.data.students[0].shoppingCartLines || 0
          );
          setShoppingCartLines(result.data.students[0].shoppingCartLines || 0);
        } else {
          StorageService.set('chosenStudentData', result.data.user);
        }
      } else {
        StorageService.set('chosenStudent', result.data.user._id);
        StorageService.set('chosenStudentData', result.data.user);
        StorageService.set(
          'shopping_cart_lines',
          result.data.user.shoppingCartLines || 0
        );
        setShoppingCartLines(result.data.user.shoppingCartLines || 0);
      }
      setIsAuthenticated(true);
      return {
        success: true,
        message: 'Inicio de sesión completado, bienvenido',
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      StorageService.set('userData', null);
      setIsAuthenticated(false);
      setShoppingCartLines(0);
      return {
        success: false,
        message:
          error.response != null
            ? error.response.data.message
            : 'Ocurrió un error',
      };
    }
  };

  const refreshUserData = async () => {
    try {
      const result = await axios.post(`${constants.API_URL}/classroom/user`, {
        user: StorageService.get('userData')._id,
      });
      StorageService.set('userData', result.data.user);
      StorageService.set('isProxy', result.data.user.isProxy);
      if (result.data.user.isProxy) {
        StorageService.set('students', result.data.students);
        if (result.data.students.length > 0) {
          StorageService.set('chosenStudent', result.data.students[0]._id);
          StorageService.set('chosenStudentData', result.data.students[0]);
        } else {
          StorageService.set('chosenStudentData', result.data.user);
        }
      } else {
        StorageService.set('chosenStudent', result.data.user._id);
        StorageService.set('chosenStudentData', result.data.user);
      }
      return {
        success: true,
        message: 'Usuario verificado, bienvenido',
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message:
          error.response != null
            ? error.response.data.message
            : 'Ocurrió un error',
      };
    }
  };

  const signout = () => {
    StorageService.set('userData', null);
    StorageService.set('students', null);
    StorageService.set('isProxy', null);
    StorageService.set('chosenStudent', null);
    StorageService.set('chosenStudentData', null);
    StorageService.set('isAuthenticated', false);
    StorageService.set('accessToken', null);
    StorageService.set('refreshToken', null);
    StorageService.set('shopping_cart_lines', null);
    setIsAuthenticated(false);
    setShoppingCartLines(0);
  };

  return {
    isAuthenticated,
    shoppingCartLines,
    setShoppingCartLines,
    signin,
    signout,
    refreshUserData,
  };
}
