import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import { showSuccessToast, showErrorToast } from '../../../components/Toast';
import SolidButton from '../../../components/SolidButton';

import { formatSchedule } from '../../../utils/functions';
import { constants } from '../../../utils/constants';
import StorageService from '../../../utils/storage';

export default function CourseCard({ id, course, turn, api }) {
  const {
    name,
    classroom,
    schedule,
    classroom_section,
    classroom_number,
    campus,
    vacants,
    school,
    enroll_cost,
    payments,
  } = course;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  let submitEnroll = async () => {
    setLoading(true);
    const result = await api.post(`/freeEnroll`, {
      student_id: StorageService.get('chosenStudent'),
      course_id: id,
    });
    if (result?.status === 400) {
      showErrorToast(result?.data?.server);
      history.replace(`/my_courses/${id}/report`);
      return;
    }
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return;
    }
    showSuccessToast('Matrícula completada');
    setTimeout(() => {
      history.replace(`/my_courses/${id}/report`);
      setLoading(false);
    }, 3000);
  };

  return (
    <div
      className={`border-${constants.SCHOOLS_FORMAT[school].borderColor} flex flex-col justify-between rounded-2xl border bg-white px-6 py-6 text-center sm:px-8`}
    >
      <div>
        <img
          src={constants.SCHOOLS_FORMAT[school].logo}
          className="mx-auto mb-6 h-12 bg-no-repeat sm:h-16"
          alt="Logo"
        />
        <p className="text-2xl font-bold text-primary">{name.name}</p>
        <div className="mt-6 text-left">
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Sede:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {campus || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Ciclo:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {classroom || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Turno:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {turn || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Horario:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {formatSchedule(schedule)}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Modalidad:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {classroom_section || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Aula:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {classroom_number || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 items-end gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Vacantes:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {vacants} vacantes
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Matrícula:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              S/ {enroll_cost?.suggested || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 items-end gap-4 pt-2 pb-1">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Derecho enseñanza:
            </dt>
            {payments?.length > 0 && (
              <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                S/ {payments[0].amount}
              </dd>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6">
        {enroll_cost?.suggested > 0 ||
        (payments?.length && payments[0].amount > 0) ? (
          <SolidButton
            href={`/payment/${id}`}
            color={constants.SCHOOLS_FORMAT[school].color}
          >
            MATRICULAR
          </SolidButton>
        ) : (
          <SolidButton
            onClick={() => submitEnroll()}
            color={constants.SCHOOLS_FORMAT[school].color}
            isLoading={loading}
          >
            MATRICULAR GRATIS
          </SolidButton>
        )}
      </div>
    </div>
  );
}
