import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import GeneralApi from '../../../utils/generalApi';
import { useAuth } from '../../../contexts/Auth';

import OutlineButton from '../../../components/OutlineButton';
import { showErrorToast } from '../../../components/Toast';

import VideosIcon from '../../../resources/img/icons/whiteboard.png';
import RecordedIcon from '../../../resources/img/icons/recorded.png';
import EnglishIcon from '../../../resources/img/icons/english.png';
import RecoveryIcon from '../../../resources/img/icons/recovery.png';

const options = [
  {
    name: 'Clase grabada',
    kind: 'recorded',
    icon: RecordedIcon,
  },
  {
    name: 'Inglés',
    kind: 'english',
    icon: EnglishIcon,
  },
  {
    name: 'Recuperación',
    kind: 'recovery',
    icon: RecoveryIcon,
  },
];

export default function CourseVideos() {
  let { courseId } = useParams();

  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getCourseData = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/course/details`, {
      course_id: courseId,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/home`);
      return;
    }
    setCourse(result.data.course);
    setLoading(false);
  };

  return (
    <div className="mx-4 my-8 md:mx-16 md:my-12">
      <div className="rounded-2xl bg-white">
        <div className="flex flex-col justify-between gap-4 border-b border-gray-300 p-6 lg:flex-row lg:items-center xl:px-12 xl:py-6">
          {loading && (
            <div className="flex items-center gap-4">
              <div className="h-12 w-12 animate-pulse rounded-full bg-gray-300" />
              <div className="flex flex-col gap-2">
                <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300" />
                <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300" />
              </div>
            </div>
          )}
          {course?.school?.name && (
            <div className="flex items-center gap-3">
              <img
                src={VideosIcon}
                className="h-12 bg-no-repeat sm:h-12"
                alt="Logo"
              />
              <p className="text-2xl text-primary-light">
                {course?.name?.name || ''} - {course?.classroom?.name || ''}
              </p>
              <p className="text-2xl font-bold text-primary-light">/ Clases</p>
            </div>
          )}

          <OutlineButton
            className="w-min font-light"
            href={`/my_courses/${courseId}/home`}
          >
            Regresar
          </OutlineButton>
        </div>
        <div className="grid grid-cols-1 gap-4 p-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-16 xl:px-12 xl:py-12">
          {options.map((option, index) => (
            <Link
              key={index}
              className="group flex cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl border-2 border-gray-400 px-4 py-8 transition-all hover:bg-light-blue sm:py-16"
              to={`/my_courses/${courseId}/videos/${option.kind}`}
            >
              <img
                src={option.icon}
                className="img-white h-16 bg-no-repeat transition-all sm:h-24"
                alt="Logo"
              />
              <p className="text-2xl font-bold uppercase text-primary-light group-hover:text-white">
                {option.name}
              </p>
            </Link>
          ))}
          <Link
            className="group flex cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl border-2 border-gray-400 px-4 py-8 transition-all hover:bg-light-blue sm:py-16"
            to={`/my_courses/${courseId}/counselings`}
          >
            <img
              src={RecordedIcon}
              className="img-white h-16 bg-no-repeat transition-all sm:h-24"
              alt="Logo"
            />
            <p className="text-2xl font-bold uppercase text-primary-light group-hover:text-white">
              Asesoría
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
