import StarsStat from '../../../components/StarsStat';
import avatar from '../../../resources/img/illustrations/avatar.png';

export default function AnswerItem({
  isTeacher,
  userName,
  date,
  time,
  content,
  rating,
  imageUrl,
  avatarUrl = avatar,
  onShowClick,
  onRateClick,
}) {
  return (
    <div className="sm:flex sm:space-x-6">
      <img
        className="hidden h-20 w-20 rounded-full sm:block"
        src={avatarUrl}
        alt="Avatar del usuario"
      />
      <div className="flex-1 rounded-md bg-gray-body p-4 sm:p-6">
        <img
          className="h-16 w-16 rounded-full sm:hidden"
          src={avatarUrl}
          alt="Avatar del usuario"
        />
        <div className="mt-3 sm:mt-0">
          <p className="sm:flex sm:items-center">
            {isTeacher ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-1 inline h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                />
              </svg>
            ) : null}
            <span className="font-bold">{userName} - </span>
            <span className="sm:ml-2">{date}</span>
            <span className="ml-4">{time}</span>
          </p>
          <div className="mt-2 flex xl:mt-0">
            <span className="mr-2 hidden sm:block">Calificación:</span>
            <button
              className="flex cursor-pointer items-center rounded-lg px-2 hover:bg-gray-300"
              title="Calificar"
              onClick={onRateClick}
            >
              <StarsStat rating={rating} />
            </button>
          </div>
        </div>
        <p className="mt-3" dangerouslySetInnerHTML={{ __html: content }} />
        {imageUrl && (
          <div className="flex w-full justify-end">
            <button
              className="text-lg text-light-blue underline hover:text-light-blue-light"
              onClick={onShowClick}
            >
              Ver más
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
