import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from '../../../../contexts/Auth';

import { secondsToTime } from '../../../../utils/functions';
import StorageService from '../../../../utils/storage';
import GeneralApi from '../../../../utils/generalApi';

import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import ConfirmModalContent from '../../../../components/ConfirmModalContent';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import SolidButton from '../../../../components/SolidButton';
import OptionsQuestionItem from './OptionsQuestionItem';
import OpenQuestionItem from './OpenQuestionItem';
import Modal from '../../../../components/Modal';

export default function ExamPage() {
  let { examId } = useParams();

  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);

  const [timerCounting, setTimerCounting] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const [emptyText, setEmptyText] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isConfirmFinishOpen, setIsConfirmFinishOpen] = useState(false);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getExamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timerCounting) {
      const interval = setInterval(() => {
        setTimerValue((value) => {
          if (value > 0) return value - 1000;
          else {
            finishExam();
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerCounting]);

  let getExamData = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/exam/details`, {
      student_id: StorageService.get('chosenStudent'),
      exam_id: examId,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    setLoading(false);
    setExam(result.data);
    setQuestions(result.data.questions || []);
    setTimerValue(
      result.data.duration * 1000 * 60 -
        (new Date().getTime() - new Date(result.data.started_at).getTime())
    );
    setTimerCounting(true);
  };

  const setAnswer = (questionId, value) => {
    const questionIndex = questions.findIndex((obj) => obj._id === questionId);
    questions[questionIndex].chosenAnswer = value;

    setQuestions([...questions]);
  };

  let validateExam = () => {
    const emptyAmount = questions.reduce(
      (a, b) =>
        b.chosenAnswer === undefined || b.chosenAnswer === '' ? a + 1 : a,
      0
    );
    setEmptyText(
      emptyAmount > 0
        ? `Hay ${emptyAmount} ${
            emptyAmount > 1 ? 'preguntas' : 'pregunta'
          } sin responder.`
        : ''
    );
    setIsConfirmFinishOpen(true);
  };

  let finishExam = async () => {
    setSubmitLoading(true);
    const result = await generalApi.post(`/classroom/exam/finish`, {
      student_id: StorageService.get('chosenStudent'),
      exam_id: examId,
      questions,
    });
    if (!result.success) {
      setSubmitLoading(false);
      showErrorToast(result.message);
      return;
    }
    showSuccessToast('Examen completado!');
    setIsConfirmFinishOpen(false);
    history.replace(`/my_courses/${exam.course._id}/report`);
  };

  return (
    <div>
      <Modal
        isOpen={isConfirmFinishOpen}
        onClose={() => {
          setIsConfirmFinishOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmFinishOpen(false);
          }}
          onConfirm={finishExam}
          title="Finalizar examen"
          content={`¿Está seguro que desea finalizar el examen? ${emptyText}`}
          buttonLoading={submitLoading}
        />
      </Modal>

      <div className="top-0 justify-between space-y-2 border-b-2 border-gray-300 bg-gray-body px-6 py-6 sm:sticky sm:z-10 sm:flex sm:space-x-6 sm:space-y-0 sm:px-12">
        <div className="flex items-center">
          <svg
            className="mr-2 h-12 w-12 text-primary group-hover:text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 14l9-5-9-5-9 5 9 5z" />
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            />
          </svg>
          <span className="text-2xl font-light text-primary">Examen</span>
        </div>
      </div>
      <div className="px-4 py-10 md:px-12">
        <div className="sm:flex">
          {loading ? (
            <LoadingSpinner
              content="Buscando preguntas"
              className="flex-1 justify-center py-6"
              color="primary"
            />
          ) : questions.length === 0 ? (
            <p>No se encontraron preguntas</p>
          ) : (
            <div className="mb-6 flex-1 bg-white px-4 py-4 shadow-md sm:flex sm:px-6">
              <div className="flex-1">
                <div className="flex h-9 w-9 items-center justify-self-end rounded-full bg-gray-200 text-light-blue">
                  <p className="mx-auto text-xl font-bold">
                    {questionIndex + 1}
                  </p>
                </div>
                {exam.mode === 'Alternativas' ? (
                  <OptionsQuestionItem
                    content={questions[questionIndex].content}
                    imageUrl={questions[questionIndex].image_url}
                    chosenAnswer={questions[questionIndex].chosenAnswer}
                    onAnswerChange={(value) =>
                      setAnswer(questions[questionIndex]._id, value)
                    }
                  ></OptionsQuestionItem>
                ) : (
                  <OpenQuestionItem
                    content={questions[questionIndex].content}
                    imageUrl={questions[questionIndex].image_url}
                    chosenAnswer={questions[questionIndex].chosenAnswer}
                    onAnswerChange={(value) =>
                      setAnswer(questions[questionIndex]._id, value)
                    }
                  ></OpenQuestionItem>
                )}
              </div>
              <div className="mt-4 flex h-full justify-between sm:mt-0 sm:flex-col sm:pl-4">
                {questionIndex > 0 ? (
                  <button
                    className="flex h-10 w-10 cursor-pointer items-center justify-center justify-self-end rounded-full bg-gray-200 text-light-blue transition-colors hover:bg-gray-300"
                    onClick={() => {
                      if (questionIndex > 0)
                        setQuestionIndex(questionIndex - 1);
                    }}
                  >
                    <svg
                      className="hidden h-7 w-7 sm:block"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 sm:hidden"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>
                ) : (
                  <div></div>
                )}
                {questionIndex < questions.length - 1 && (
                  <button
                    className="flex h-10 w-10 cursor-pointer items-center justify-center justify-self-end rounded-full bg-gray-200 text-light-blue transition-colors hover:bg-gray-300"
                    onClick={() => {
                      if (questionIndex < questions.length - 1)
                        setQuestionIndex(questionIndex + 1);
                    }}
                  >
                    <svg
                      className="hidden h-7 w-7 sm:block"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 sm:hidden"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
          {exam && (
            <div className="sm:pl-12">
              <p className="text-2xl font-bold text-primary">
                {exam.course?.classroom?.name} {exam.course?.name?.name}
              </p>
              <p className="text-2xl font-bold text-light-blue">{exam.title}</p>

              {timerCounting && timerValue > 0 ? (
                <>
                  <p className="mt-8 text-xl font-bold">Tiempo Restante</p>
                  <p className="w-60 text-5xl font-bold text-primary">
                    {secondsToTime(timerValue / 1000)}
                  </p>
                </>
              ) : timerCounting ? (
                <p className="mt-8 text-xl font-bold text-primary">
                  Examen finalizado
                </p>
              ) : null}

              <p className="mt-8 text-xl font-bold">Barra de progreso</p>
              <div className="flex items-end justify-center space-x-4">
                <div className="mt-4 flex h-64 w-16 flex-col justify-end border-2 border-primary">
                  <div
                    className="h-1/2 w-full bg-primary"
                    style={{
                      height: `${
                        (questionIndex / (questions.length - 1)) * 100
                      }%`,
                    }}
                  ></div>
                </div>
                <span className="w-16 text-xl font-bold">
                  {((questionIndex / (questions.length - 1)) * 100).toFixed(0)}%
                </span>
              </div>
              <div className="mt-12">
                <SolidButton onClick={validateExam} isLoading={submitLoading}>
                  TERMINAR EXAMEN
                </SolidButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
