const gapClasses = {
  4: 'gap-y-4 sm:flex sm:gap-y-0 sm:gap-x-4',
  6: 'gap-y-6 sm:flex sm:gap-y-0 sm:gap-x-6',
  8: 'gap-y-8 sm:flex sm:gap-y-0 sm:gap-x-8',
};

export default function InfoLine({ gap, fields }) {
  return (
    <div className={`${gapClasses[gap || '6']} flex justify-between`}>
      <div className="">
        {fields.map((field) => (
          <p className="font-bold text-gray-800">{field.name}</p>
        ))}
      </div>

      <div className="">
        {fields.map(() => (
          <p className="font-bold text-gray-800">:</p>
        ))}
      </div>

      <div className="">
        {fields.map((field) => (
          <p className="text-gray-800">{field.value}</p>
        ))}
      </div>
    </div>
  );
}
