import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../../contexts/Auth';
import GeneralApi from '../../../../utils/generalApi';
import StorageService from '../../../../utils/storage';

import OutlineButton from '../../../../components/OutlineButton';
import ErrorMessage from '../../../../components/ErrorMessage';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import SolidButton from '../../../../components/SolidButton';
import { buildCourseName } from '../../../../utils/functions';

export default function Step1({
  course,
  form,
  studentId,
  onStudentFound,
  onContinue,
}) {
  const history = useHistory();
  const auth = useAuth();
  const generalApi = new GeneralApi(auth, history);

  const [checkLoading, setCheckLoading] = useState(false);

  const {
    watch,
    register,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  const docType = watch(
    'doc_type',
    StorageService.get('chosenStudentData').docType
  );
  const docNumber = watch(
    'doc_number',
    StorageService.get('chosenStudentData').docNumber
  );

  let searchStudent = async () => {
    if (docType === '' || docNumber === '') return;
    setCheckLoading(true);
    const result = await generalApi.post(`/getStudentByDocument`, {
      doc_type: getValues('doc_type'),
      doc_number: getValues('doc_number').trim(),
    });
    if (!result.success) {
      showErrorToast(result.message);
      setCheckLoading(false);
      return;
    }
    setCheckLoading(false);
    if (!result.data) {
      onStudentFound(null);
      return showErrorToast(
        'El alumno debe estar registrado para que se pueda matricular'
      );
    }
    showSuccessToast('Alumno encontrado');
    setValue('name', result.data.name);
    setValue('last_name_p', result.data.last_name_p);
    setValue('last_name_m', result.data.last_name_m);
    setValue('phone', result.data.phone);
    setValue('email', result.data.email);
    trigger(['name', 'last_name_p', 'last_name_m', 'phone', 'email']);
    onStudentFound(result.data._id);
  };

  useEffect(() => {
    searchStudent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <p className="text-primary">
        Seleccione los datos del alumno que se matriculará en:
      </p>
      <p className="text-xl font-semibold text-primary">
        {course && buildCourseName(course)}
      </p>
      <div className="mx-auto mt-6 max-w-xl gap-4 space-y-2 md:grid md:grid-cols-4 md:space-y-0">
        <div className="col-span-2">
          <label htmlFor="inp_doc_type" className="sr-only">
            Tipo de documento
          </label>
          <div className="mt-1">
            <select
              id="inp_doc_type"
              name="doc_type"
              placeholder="Tipo de documento"
              className="inp-base"
              {...register('doc_type', {
                required: true,
              })}
            >
              <option value="">Elegir tipo de documento</option>
              <option>DNI</option>
              <option>Pasaporte</option>
              <option>Carnet de extranjería</option>
              <option>RUC</option>
            </select>
          </div>
          <ErrorMessage errors={errors} field="doc_type" />
        </div>
        <div className="col-span-2"></div>
        <div className="col-span-2">
          <label htmlFor="inp_doc_number" className="sr-only">
            Número de documento
          </label>
          <div className="mt-1">
            <input
              id="inp_doc_number"
              name="doc_number"
              type="text"
              placeholder="Número de documento"
              className="inp-base"
              {...register('doc_number', {
                required: true,
              })}
            ></input>
          </div>
          <ErrorMessage errors={errors} field="doc_number" />
        </div>
        <div className="col-span-2">
          <OutlineButton
            onClick={() => searchStudent()}
            isLoading={checkLoading}
            disabled={docType === '' || docNumber === ''}
            color="light-blue"
          >
            BUSCAR
          </OutlineButton>
        </div>
        <div className="col-span-2">
          <label htmlFor="inp_name" className="sr-only">
            Nombre
          </label>
          <div className="mt-1">
            <input
              id="inp_name"
              name="name"
              type="text"
              placeholder="Nombre"
              className="inp-base"
              {...register('name', {
                required: true,
              })}
            ></input>
          </div>
          <ErrorMessage errors={errors} field="name" />
        </div>
        <div className="col-span-2">
          <label htmlFor="inp_last_name_p" className="sr-only">
            Apellido Paterno
          </label>
          <div className="mt-1">
            <input
              id="inp_last_name_p"
              name="last_name_p"
              type="text"
              placeholder="Apellido Paterno"
              className="inp-base"
              {...register('last_name_p', {
                required: true,
              })}
            ></input>
          </div>
          <ErrorMessage errors={errors} field="last_name_p" />
        </div>
        <div className="col-span-2">
          <label htmlFor="inp_last_name_m" className="sr-only">
            Apellido Materno
          </label>
          <div className="mt-1">
            <input
              id="inp_last_name_m"
              name="last_name_m"
              type="text"
              placeholder="Apellido Materno"
              className="inp-base"
              {...register('last_name_m', {
                required: true,
              })}
            ></input>
          </div>
          <ErrorMessage errors={errors} field="last_name_m" />
        </div>
        <div className="col-span-2">
          <label htmlFor="inp_phone" className="sr-only">
            Celular
          </label>
          <div className="mt-1">
            <input
              id="inp_phone"
              name="phone"
              type="text"
              placeholder="Celular"
              className="inp-base"
              {...register('phone', {
                required: true,
              })}
            ></input>
          </div>
          <ErrorMessage errors={errors} field="phone" />
        </div>
        <div className="col-span-2">
          <label htmlFor="inp_email" className="sr-only">
            Email
          </label>
          <div className="mt-1">
            <input
              id="inp_email"
              name="email"
              type="email"
              placeholder="Email"
              className="inp-base"
              {...register('email', {
                required: true,
              })}
            ></input>
          </div>
          <ErrorMessage errors={errors} field="email" />
        </div>
      </div>
      <SolidButton
        size="sm"
        type="button"
        className="mt-4 ml-auto max-w-xs"
        disabled={!studentId}
        onClick={() => onContinue()}
      >
        SELECCIONAR PAGOS
      </SolidButton>
    </div>
  );
}
