import { Transition } from '@headlessui/react';

export default function Pagination({
  className,
  page,
  total,
  maxPage,
  onPreviousPage,
  onNextPage,
  onSelectedPage,
}) {
  let numbers = [];
  if (maxPage > 5) {
    if (page < 3) {
      numbers = [1, 2, 3, 0, maxPage - 2, maxPage - 1, maxPage];
    } else if (page > maxPage - 3) {
      numbers = [
        maxPage - 5,
        maxPage - 4,
        maxPage - 3,
        0,
        maxPage - 2,
        maxPage - 1,
        maxPage,
      ];
    } else {
      numbers = [
        page - 1,
        page,
        page + 1,
        0,
        maxPage - 2,
        maxPage - 1,
        maxPage,
      ];
    }
  } else {
    for (let i = 0; i < (maxPage > 2 ? maxPage + 1 : maxPage); i++) {
      if (i === 3) numbers.push(0);
      else if (i > 3) numbers.push(i);
      else numbers.push(i + 1);
    }
  }
  return (
    <Transition
      show={total > 0}
      as="nav"
      className={`${className} mt-6 flex items-center justify-center px-4 sm:px-0`}
      aria-label="Pagination"
    >
      <div className="mr-12 -mt-px flex w-0">
        <div
          onClick={() => {
            if (page === 1) return;
            onPreviousPage();
          }}
          className="inline-flex cursor-pointer items-center border-t-2 border-transparent px-2 py-2 text-sm font-medium text-gray-600 transition-colors hover:bg-light-blue hover:text-white"
        >
          {/* Heroicon name: solid/arrow-narrow-left */}
          <svg
            className="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </div>
      </div>
      <div className="hidden space-x-2 md:-mt-px md:flex">
        {numbers.map((obj, index) => {
          if (index === 3) {
            if (maxPage < 7) return null;
            return (
              <span
                key={index}
                className="inline-flex items-center border-t-2 border-transparent px-4 py-2 text-lg font-light text-gray-700"
              >
                ...
              </span>
            );
          }
          return (
            <div
              key={index}
              onClick={() => {
                onSelectedPage(obj);
              }}
              className={`${
                obj === page
                  ? 'bg-light-blue font-medium text-white'
                  : 'font-light text-gray-700 hover:bg-light-blue hover:text-white'
              } inline-flex cursor-pointer items-center border-t-2 border-transparent py-2 px-4 text-lg transition-colors`}
            >
              {obj}
            </div>
          );
        })}
      </div>
      <div className="ml-12 -mt-px flex w-0 justify-end">
        <div
          onClick={() => {
            if (page === maxPage) return;
            onNextPage();
          }}
          className="inline-flex cursor-pointer items-center border-t-2 border-transparent px-2 py-2 text-sm font-medium text-gray-600 transition-colors hover:bg-light-blue hover:text-white"
        >
          {/* Heroicon name: solid/arrow-narrow-right */}
          <svg
            className="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </div>
    </Transition>
  );
}
