import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../../contexts/Auth';
import GeneralApi from '../../../../utils/generalApi';

import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import SolidButton from '../../../../components/SolidButton';

import { formatSchedule } from '../../../../utils/functions';
import { constants } from '../../../../utils/constants';
import StorageService from '../../../../utils/storage';

export default function Step3({
  isLoading,
  lines,
  onReload,
  onEdit,
  onContinue,
}) {
  const history = useHistory();
  const auth = useAuth();
  const generalApi = new GeneralApi(auth, history);

  const [removeLoading, setRemoveLoading] = useState(false);

  let removeLine = async (lineId) => {
    setRemoveLoading(true);
    const result = await generalApi.post(`/classroom/shopping_cart/remove`, {
      student_id: StorageService.get('chosenStudent'),
      line_id: lineId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setRemoveLoading(false);
      return;
    }
    setRemoveLoading(false);
    showSuccessToast('Curso eliminado del carrito de compras');
    onReload();
  };

  if (isLoading) {
    return (
      <div className="flex w-full justify-center py-8">
        <LoadingSpinner color="primary" content="Cargando Carrito de compras" />
      </div>
    );
  }

  if (lines.length === 0) {
    return (
      <div>
        <p className="text-center text-lg font-bold text-primary sm:text-2xl">
          No tiene programas en su Carrito de compras, agregue uno para
          proceder.
        </p>
        <SolidButton
          size="sm"
          type="button"
          className="mx-auto mt-8 max-w-xs"
          href="/courses"
        >
          LISTA DE CURSOS
        </SolidButton>
      </div>
    );
  }

  return (
    <div>
      <div className="my-6 -mx-4 min-w-full overflow-hidden overflow-x-auto align-middle shadow-md">
        <table className="min-w-full divide-gray-200">
          <thead>
            <tr>
              <th className="bg-light-blue px-6 py-4 text-left text-lg font-light uppercase tracking-wider text-white">
                Curso
              </th>
              <th className="bg-light-blue px-6 py-4 text-left text-lg font-light uppercase tracking-wider text-white">
                Detalle
              </th>
              <th className="bg-light-blue px-6 py-4 text-left text-lg font-light uppercase tracking-wider text-white">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {lines?.map((obj, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? 'bg-white' : 'bg-gray-body'}
              >
                <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                  <img
                    src={constants.SCHOOLS_FORMAT[obj.course.school.name].logoSmall}
                    className="mx-auto mb-2 h-12 w-12 bg-no-repeat"
                    alt="Logo"
                  />
                  <p className="text-center text-xl font-bold text-primary">
                    {obj.course.name.name}
                  </p>
                </td>
                <td className="whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                  <div className="grid-cols-4 gap-4 py-1 sm:grid">
                    <dt className="col-span-2 text-lg text-gray-500">Sede:</dt>
                    <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                      {obj.course.campus.name || '-'}
                    </dd>
                  </div>
                  <div className="grid-cols-4 gap-4 py-1 sm:grid">
                    <dt className="col-span-2 text-lg text-gray-500">Ciclo:</dt>
                    <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                      {obj.course.classroom.name || '-'}
                    </dd>
                  </div>
                  <div className="grid-cols-4 gap-4 py-1 sm:grid">
                    <dt className="col-span-2 text-lg text-gray-500">Turno:</dt>
                    <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                      {obj.course.classroom_turn
                        ? constants.TURNS[obj.course.classroom_turn]
                        : obj.course.classroom_number?.name || '-'}
                    </dd>
                  </div>
                  <div className="grid-cols-4 gap-4 py-1 sm:grid">
                    <dt className="col-span-2 text-lg text-gray-500">
                      Horario:
                    </dt>
                    <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                      {formatSchedule(obj.course.schedule.name)}
                    </dd>
                  </div>
                  <div className="grid-cols-4 gap-4 py-1 sm:grid">
                    <dt className="col-span-2 text-lg text-gray-500">
                      Modalidad:
                    </dt>
                    <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                      {obj.course.classroom_section || '-'}
                    </dd>
                  </div>
                  <div className="grid-cols-4 gap-4 py-1 sm:grid">
                    <dt className="col-span-2 text-lg text-gray-500">Aula:</dt>
                    <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                      {obj.course.classroom_number?.name || '-'}
                    </dd>
                  </div>
                </td>
                <td className="flex flex-col whitespace-nowrap px-6 py-3 text-left text-lg text-gray-700">
                  <div>
                    {obj.discount_amount > 0 && (
                      <div className="mt-1 grid-cols-4 items-end gap-4 pb-1 sm:grid">
                        <dt className="col-span-2 text-lg text-gray-500">
                          Descuento:
                        </dt>
                        <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                          S/ {obj.discount_amount.toFixed(2)}
                        </dd>
                      </div>
                    )}
                    <div className="mt-1 grid-cols-4 items-end gap-4 pb-1 sm:grid">
                      <dt className="col-span-2 text-lg text-gray-500">
                        Monto:
                      </dt>
                      <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                        S/ {(obj.total - (obj.discount_amount || 0)).toFixed(2)}
                      </dd>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3">
                    <SolidButton
                      size="sm"
                      type="button"
                      className="ml-auto max-w-xs"
                      color="light-blue"
                      disabled={removeLoading}
                      onClick={() => onEdit(obj)}
                    >
                      EDITAR
                    </SolidButton>
                    <SolidButton
                      size="sm"
                      type="button"
                      className="ml-auto max-w-xs"
                      color="orange"
                      onClick={() => removeLine(obj._id)}
                    >
                      ELIMINAR
                    </SolidButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {lines.reduce((a, b) => a + (b.discount_amount || 0), 0).toFixed(2) >
        0 && (
        <p className="mt-2 text-right text-xl font-bold text-primary">
          <span className="mr-12">Descuento:</span>
          S/{' '}
          {lines.reduce((a, b) => a + (b.discount_amount || 0), 0).toFixed(2)}
        </p>
      )}
      <p className="mt-2 text-right text-xl font-bold text-primary">
        <span className="mr-12">Total:</span>
        S/{' '}
        {lines
          .reduce((a, b) => a + b.total - (b.discount_amount || 0), 0)
          .toFixed(2)}
      </p>
      <SolidButton
        size="sm"
        type="button"
        className="mt-8 ml-auto max-w-xs"
        onClick={() => onContinue()}
      >
        PAGAR
      </SolidButton>
    </div>
  );
}
