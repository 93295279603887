import { AcademicCapIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';

import SolidButton from '../../../components/SolidButton';

export default function StudentDetails({
  student,
  index,
  finished,
  onEditClick,
}) {
  return (
    <>
      <div className="mb-8 overflow-hidden border border-gray-200 bg-white sm:rounded-lg">
        <div className="flex justify-between px-4 py-5 sm:px-6">
          <h3 className="flex items-center text-lg font-medium leading-6 text-gray-900">
            <AcademicCapIcon className="mr-2 h-6 w-6" />
            {`Estudiante ${index + 1}`}
          </h3>
          {!finished && (
            <div>
              <SolidButton size="sm" onClick={onEditClick}>
                <PencilIcon className="h-6 w-6 text-white" />
              </SolidButton>
            </div>
          )}
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Tipo de documento
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{student?.docType}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Número de documento
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {student?.docNumber}
              </dd>
            </div>
            <div className="sm:col-span-1"></div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Apellido Paterno
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {student?.lastNameP}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Apellido Materno
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {student?.lastNameM}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Nombres</dt>
              <dd className="mt-1 text-sm text-gray-900">{student?.name}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Sexo</dt>
              <dd className="mt-1 text-sm text-gray-900">{student?.genre}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Edad</dt>
              <dd className="mt-1 text-sm text-gray-900">{student?.age}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Nivel</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <span className="inline-flex items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-sm font-medium text-indigo-800">
                  <svg
                    className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  {student?.level}
                </span>
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Grado/Año</dt>
              <dd className="mt-1 text-sm text-gray-900">{student?.grade}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Sección</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {student?.section || '-'}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Correo electrónico
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {student?.email || '-'}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Teléfono fijo
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {student?.phone || '-'}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Celular</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {student?.cellphone || '-'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
