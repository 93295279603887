import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from './components/Utils/ProtectedRoute';
import PublicOnlyRoute from './components/Utils/PublicOnlyRoute';
import { ProvideAuth } from './contexts/Auth';

import Login from './pages/auth/Login';
import NewSignup from './pages/auth/NewSignup';
import ForgotPassword from './pages/auth/ForgotPassword';

import Profile from './pages/profile';
import MyCourses from './pages/courses/MyCourses';
import PendingPayment from './pages/courses/Payment/PendingPayment';
import PaymentPage from './pages/courses/Payment/PaymentPage';
import ShoppingCartPage from './pages/courses/Payment/ShoppingCartPage';
import VoucherList from './pages/voucher/List';
import CoursesList from './pages/courses/CoursesList';
import CourseReport from './pages/courses/CourseReport';
import CourseHome from './pages/courses/CourseHome';
import Forum from './pages/forum';
import QuestionDetails from './pages/forum/QuestionDetails';

import NotFound from './pages/misc/NotFound';
import CreateQuestion from './pages/forum/CreateQuestion';
import ExamPage from './pages/courses/CourseReport/ExamPage';
import NewFormatExamPage from './pages/courses/CourseReport/ExamPage/NewFormatExam';
import RecoverPassword from './pages/auth/RecoverPassword';
import InscriptionPage from './pages/misc/Inscription';
import CourseContent from './pages/courses/CourseReport/CourseContent';
import GradesReport from './pages/courses/CourseReport/GradesReport';
import Assignments from './pages/courses/CourseReport/Assignments';
import AttendanceReport from './pages/courses/CourseReport/AttendanceReport';
import ExamsResults from './pages/courses/CourseReport/ExamResults';
import ExamResult from './pages/courses/CourseReport/ExamResult';
import CourseVideos from './pages/courses/CourseVideos';
import VideosList from './pages/courses/CourseVideos/VideosList';
import CounselingsList from './pages/courses/CourseVideos/CounselingsList';
import CourseCalendar from './pages/courses/CourseCalendar';
import CourseHomeworks from './pages/courses/CourseHomeworks';
import AssignmentResult from './pages/courses/CourseReport/Assignments/Results';

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <PublicOnlyRoute exact path="/">
            <Login />
          </PublicOnlyRoute>
          <PublicOnlyRoute path="/login">
            <Login />
          </PublicOnlyRoute>
          <PublicOnlyRoute path="/signup">
            <NewSignup />
          </PublicOnlyRoute>
          <PublicOnlyRoute path="/recover_password/:userId/:code">
            <RecoverPassword />
          </PublicOnlyRoute>
          <PublicOnlyRoute path="/recover_password">
            <ForgotPassword />
          </PublicOnlyRoute>
          {/* <PrivateRoute path="/welcome" title="TITULO">
            <Welcome />
          </PrivateRoute> */}
          <PrivateRoute path="/profile" title="MI PERFIL">
            <Profile />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/pending_payment"
            title="AULA VIRTUAL"
          >
            <PendingPayment />
          </PrivateRoute>
          <PrivateRoute path="/my_courses/:courseId/home" title="AULA VIRTUAL">
            <CourseHome />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/resources"
            title="AULA VIRTUAL"
          >
            <CourseContent />
          </PrivateRoute>
          <PrivateRoute path="/my_courses/:courseId/exams" title="AULA VIRTUAL">
            <GradesReport />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/assignments"
            title="AULA VIRTUAL"
          >
            <Assignments />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/grades"
            title="AULA VIRTUAL"
            exact
          >
            <ExamsResults />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/videos"
            title="AULA VIRTUAL"
            exact
          >
            <CourseVideos />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/videos/:kind"
            title="AULA VIRTUAL"
          >
            <VideosList />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/counselings"
            title="AULA VIRTUAL"
          >
            <CounselingsList />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/calendar"
            title="AULA VIRTUAL"
          >
            <CourseCalendar />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/attendance"
            title="AULA VIRTUAL"
          >
            <AttendanceReport />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/homeworks"
            title="AULA VIRTUAL"
          >
            <CourseHomeworks />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/report"
            title="AULA VIRTUAL"
          >
            <CourseReport />
          </PrivateRoute>
          <PrivateRoute path="/exam/legacy/:examId" title="AULA VIRTUAL">
            <ExamPage />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/grades/:examId"
            title="AULA VIRTUAL"
          >
            <ExamResult />
          </PrivateRoute>
          <PrivateRoute
            path="/my_courses/:courseId/assignment_grades/:assignmentId"
            title="AULA VIRTUAL"
          >
            <AssignmentResult />
          </PrivateRoute>
          <PrivateRoute path="/exam/:examId" title="AULA VIRTUAL">
            <NewFormatExamPage />
          </PrivateRoute>
          <PrivateRoute
            path="/forum/:courseId/question/:questionId"
            title="AULA VIRTUAL"
          >
            <QuestionDetails />
          </PrivateRoute>
          <PrivateRoute path="/forum/:courseId/question" title="AULA VIRTUAL">
            <CreateQuestion />
          </PrivateRoute>
          <PrivateRoute path="/forum/:courseId" title="AULA VIRTUAL">
            <Forum />
          </PrivateRoute>
          <PrivateRoute path="/my_courses" title="AULA VIRTUAL">
            <MyCourses />
          </PrivateRoute>
          <PrivateRoute path="/payment/:courseId" title="AULA VIRTUAL">
            <PaymentPage />
          </PrivateRoute>
          <PrivateRoute path="/shopping_cart/:courseId" title="AULA VIRTUAL">
            <ShoppingCartPage />
          </PrivateRoute>
          <PrivateRoute path="/shopping_cart" title="AULA VIRTUAL">
            <ShoppingCartPage />
          </PrivateRoute>
          <PrivateRoute path="/vouchers" title="COMPROBANTES">
            <VoucherList />
          </PrivateRoute>
          <PrivateRoute path="/courses" title="BUSCAR PROGRAMAS">
            <CoursesList />
          </PrivateRoute>
          <Route path="/inscripcion">
            <InscriptionPage />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <div
          id="toast-container"
          className="fixed bottom-0 left-0 right-0 z-50 sm:bottom-auto sm:left-auto sm:top-16 sm:right-4"
        ></div>
      </Router>
    </ProvideAuth>
  );
}

export default App;
