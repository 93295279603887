import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';

import StorageService from '../../../../utils/storage';
import GeneralApi from '../../../../utils/generalApi';
import { useAuth } from '../../../../contexts/Auth';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import OutlineButton from '../../../../components/OutlineButton';
import { showErrorToast } from '../../../../components/Toast';
import Modal from '../../../../components/Modal';

import ExamResultsIcon from '../../../../resources/img/icons/data-analytics.png';
import FilterIcon from '../../../../resources/img/icons/filter.png';
import ExamReport from '../GradesReport/ExamReport';
import ExamItem from '../GradesReport/ExamItem';
import ExamResultItem from './ExamResultItem';

export default function ExamsResults() {
  let { courseId } = useParams();

  const history = useHistory();
  const auth = useAuth();

  // const [kindFilter, setKindFilter] = useState('all');
  const [gradesKindFilter, setGradesKindFilter] = useState('monthly');
  const [gradesFilterMonth, setGradesFilterMonth] = useState(
    new Date().getMonth()
  );
  const [gradesFilterFrom, setGradesFilterFrom] = useState(null);
  const [gradesFilterTo, setGradesFilterTo] = useState(null);

  const [isExamResultOpen, setIsExamResultOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [courseLoading, setCourseLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [exams, setExams] = useState([]);

  const [examData, setExamData] = useState(null);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradesKindFilter, gradesFilterMonth, gradesFilterFrom, gradesFilterTo]);

  let getExams = async () => {
    if (
      gradesKindFilter === 'range' &&
      (gradesFilterFrom === null || gradesFilterTo === null)
    )
      return;
    setLoading(true);
    const result = await generalApi.post(`/classroom/exams/completed`, {
      student_id: StorageService.get('chosenStudent'),
      course_id: courseId,
      kind_filter: gradesKindFilter,
      filter_month: gradesFilterMonth,
      filter_from: gradesFilterFrom,
      filter_to: gradesFilterTo,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    setLoading(false);
    setExams(result.data);
  };

  let getCourseData = async () => {
    setCourseLoading(true);
    const result = await generalApi.post(`/classroom/course/details`, {
      course_id: courseId,
    });
    if (!result.success) {
      setCourseLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/home`);
      return;
    }
    setCourse(result.data.course);
    setCourseLoading(false);
  };

  return (
    <div>
      <Modal
        isOpen={isExamResultOpen}
        size="lg"
        onClose={() => {
          setIsExamResultOpen(false);
        }}
      >
        <ExamReport
          examDescription={examData?.description}
          reportQuestions={
            examData?.results !== undefined ? examData.results[0].questions : []
          }
          examQuestions={examData?.questions || []}
        ></ExamReport>
      </Modal>

      <div className="mx-4 my-8 md:mx-16 md:my-12">
        <div className="rounded-2xl bg-white">
          <div className="flex flex-col justify-between gap-4 border-b border-gray-300 p-6 lg:flex-row lg:items-center xl:px-12 xl:py-6">
            {courseLoading && (
              <div className="flex items-center gap-4">
                <div className="h-12 w-12 animate-pulse rounded-full bg-gray-300" />
                <div className="flex flex-col gap-2">
                  <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300" />
                  <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300" />
                </div>
              </div>
            )}

            {course?.school?.name && (
              <div className="flex flex-col items-center gap-3 lg:flex-row">
                <div className="flex items-center gap-4">
                  <img
                    src={ExamResultsIcon}
                    className="h-12 bg-no-repeat sm:h-12"
                    alt="Logo"
                  />
                  <p className="text-2xl text-primary-light">
                    {course?.name?.name || ''} - {course?.classroom?.name || ''}
                  </p>
                </div>
                <p className="text-2xl font-bold text-primary-light">/ Notas</p>
              </div>
            )}

            <OutlineButton
              className="w-min font-light"
              href={`/my_courses/${courseId}/home`}
            >
              Regresar
            </OutlineButton>
          </div>

          <div className="p-8 xl:px-12">
            <div className="mb-8 flex items-center gap-8">
              <label
                htmlFor="inp_kind"
                className="flex items-center gap-2 text-xl font-bold text-gray-800"
              >
                <img className="h-4 w-4" src={FilterIcon} alt="Filtro" />
                FILTRAR
              </label>

              <div className="h-16 w-0.5 bg-gray-300" />

              <div className="flex flex-col items-center gap-4 lg:flex-row">
                {/* <div>
                  <label htmlFor="inp_kind" className="sr-only">
                    Tipo
                  </label>
                  <select
                    id="inp_kind"
                    name="kind"
                    value={kindFilter}
                    onChange={(e) => setKindFilter(e.target.value)}
                    className="inp-base w-64 rounded-full px-6"
                  >
                    <option value="all">Todos</option>
                    <option value="Semanal">Semanal</option>
                    <option value="Mensual">Mensual</option>
                    <option value="Bimestral">Bimestral</option>
                    <option value="Final">Final</option>
                    <option value="Parcial">Parcial</option>
                    <option value="Simulacro">Simulacro</option>
                    <option value="Olimpiada">Olimpiada</option>
                  </select>
                </div> */}

                <div className="flex max-w-sm items-center">
                  <label htmlFor="inp_grades_kind_filter" className="sr-only">
                    Tipo de Filtro:
                  </label>
                  <div className="w-36 flex-1">
                    <select
                      id="inp_grades_kind_filter"
                      name="grades_kind_filter"
                      value={gradesKindFilter}
                      onChange={(e) => setGradesKindFilter(e.target.value)}
                      className="inp-base w-full rounded-full"
                    >
                      <option value="monthly">Mes</option>
                      <option value="range">Rango</option>
                    </select>
                  </div>
                </div>
                <Transition
                  show={gradesKindFilter === 'monthly'}
                  className="flex max-w-sm flex-1 items-center"
                >
                  <label htmlFor="inp_grades_filter_month" className="sr-only">
                    Mes:
                  </label>
                  <div className="flex-1">
                    <select
                      id="inp_grades_filter_month"
                      name="grades_filter_month"
                      value={gradesFilterMonth}
                      onChange={(e) => setGradesFilterMonth(e.target.value)}
                      className="inp-base w-full rounded-full"
                    >
                      <option value="0">Enero</option>
                      <option value="1">Febrero</option>
                      <option value="2">Marzo</option>
                      <option value="3">Abril</option>
                      <option value="4">Mayo</option>
                      <option value="5">Junio</option>
                      <option value="6">Julio</option>
                      <option value="7">Agosto</option>
                      <option value="8">Setiembre</option>
                      <option value="9">Octubre</option>
                      <option value="10">Noviembre</option>
                      <option value="11">Diciembre</option>
                    </select>
                  </div>
                </Transition>
                <Transition
                  show={gradesKindFilter === 'range'}
                  className="items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-8"
                >
                  <div className="flex items-center">
                    <label htmlFor="inp_grades_filter_from" className="sr-only">
                      De:
                    </label>
                    <div className="ml-4 flex-1">
                      <input
                        id="inp_grades_filter_from"
                        name="grades_filter_from"
                        type="date"
                        placeholder="De"
                        value={gradesFilterFrom}
                        onChange={(e) => setGradesFilterFrom(e.target.value)}
                        className="inp-base"
                      ></input>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label
                      htmlFor="inp_grades_filter_to"
                      className="text-lg text-gray-600"
                    >
                      Hasta:
                    </label>
                    <div className="ml-4 flex-1">
                      <input
                        id="inp_grades_filter_to"
                        name="grades_filter_to"
                        type="date"
                        placeholder="Hasta"
                        value={gradesFilterTo}
                        onChange={(e) => setGradesFilterTo(e.target.value)}
                        className="inp-base"
                      ></input>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>

            {loading ? (
              <LoadingSpinner content="Buscando evaluaciones" color="primary" />
            ) : exams.length === 0 ? (
              <p>No se encontraron evaluaciones</p>
            ) : (
              exams.map((obj, index) =>
                obj.new_format ? (
                  <ExamResultItem
                    key={obj._id}
                    id={obj._id}
                    course={course}
                    exam={obj}
                    completed={obj.results.length > 0}
                    graded={
                      obj.results.length > 0 ? obj.results[0].graded : false
                    }
                    result={obj.results.length > 0 ? obj.results[0] : null}
                    defaultOpen={index === 0}
                  />
                ) : (
                  <ExamItem
                    key={obj._id}
                    id={obj._id}
                    date={obj.exam_date}
                    description={obj.description}
                    mode={obj.mode}
                    kind={obj.kind}
                    completed={obj.results.length > 0}
                    graded={
                      obj.results.length > 0 ? obj.results[0].graded : false
                    }
                    result={obj.results.length > 0 ? obj.results[0] : null}
                    onResultOpen={() => {
                      setExamData(obj);
                      setIsExamResultOpen(true);
                    }}
                  />
                )
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
