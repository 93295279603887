import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

import GeneralApi from '../../utils/generalApi';
import { formatDate } from '../../utils/functions';
import { validateFile } from '../../utils/fileUtils';

import avatar from '../../resources/img/illustrations/avatar.png';
import avatarShape from '../../resources/img/backgrounds/avatar.svg';

import SolidButton from '../../components/SolidButton';
import StorageService from '../../utils/storage';
import { showErrorToast, showSuccessToast } from '../../components/Toast';

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

export default function Profile() {
  const history = useHistory();
  const auth = useAuth();

  const [chosenStudent, setChosenStudent] = useState(
    StorageService.get('chosenStudent')
  );

  const [loading, setLoading] = useState(false);

  const [avatarUrl, setAvatarUrl] = useState('');
  const [image, setImage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [department, setDepartment] = useState('-');
  const [province, setProvince] = useState('-');
  const [district, setDistrict] = useState('-');

  const [genre, setGenre] = useState('');
  //const [birthDate, setBirthDate] = useState('');
  const [studyLevel, setStudyLevel] = useState('');
  const [studyGrade, setStudyGrade] = useState('');
  const [studyCollege, setStudyCollege] = useState('');
  const [studyCareer, setStudyCareer] = useState('');

  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [inputRef, setInputFocus] = useFocus();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordLoading, setPasswordLoading] = useState(false);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getDeparments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const students = StorageService.get('students');
    if (students) {
      const index = students.findIndex((obj) => obj._id === chosenStudent);
      if (index > -1) {
        StorageService.set('chosenStudent', students[index]._id);
        StorageService.set(
          'shopping_cart_lines',
          students[index].shoppingCartLines || 0
        );
        auth.setShoppingCartLines(students[index].shoppingCartLines || 0);
        StorageService.set('chosenStudentData', students[index]);
      }
    }
    history.replace('/profile');
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenStudent]);

  useEffect(() => {
    if (department && department !== '-') getProvinces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  useEffect(() => {
    if (department && province && department !== '-' && province !== '-')
      getDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [province]);

  let getDeparments = async () => {
    const result = await generalApi.get(`/departments`);
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setDepartments(result.data);
  };

  let getProvinces = async () => {
    const result = await generalApi.get(
      `/provinces/${department.split(['|'])[0]}`
    );
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setProvinces(result.data);
  };

  let getDistricts = async () => {
    const result = await generalApi.get(
      `/districts/${department.split(['|'])[0]}/${province.split(['|'])[0]}`
    );
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setDistricts(result.data);
  };

  let refreshData = async () => {
    let result = await auth.refreshUserData();
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    history.replace(result?.data?.redirect || '/profile');
  };

  let getProfile = async () => {
    if (!StorageService.get('chosenStudent')) {
      return refreshData();
    }

    setLoading(true);
    const result = await generalApi.post(`/classroom/student/profile`, {
      student: StorageService.get('chosenStudent'),
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return;
    }
    setAvatarUrl(result.data.avatar_url);
    setPhoneNumber(result.data.phone);
    setEmail(result.data.email);
    setAddress(result.data.address);
    if (result.data.department)
      setDepartment(
        `${result.data.department.id}|${result.data.department.name}`
      );
    if (result.data.province)
      setProvince(`${result.data.province.id}|${result.data.province.name}`);
    if (result.data.distrite)
      setDistrict(`${result.data.distrite.id}|${result.data.distrite.name}`);
    setGenre(result.data.genre);
    //setBirthDate(formatDate(result.data.date_of_birth, 'yyyy-MM-dd'));
    setStudyLevel(result.data.study_level || '');
    setStudyGrade(result.data.study_grade || '');
    setStudyCollege(result.data.study_college || '');
    setStudyCareer(result.data.study_career || '');
    setLoading(false);
  };

  let updateProfile = async () => {
    setLoading(true);
    let userData = new FormData();

    userData.append('student', StorageService.get('chosenStudent'));
    userData.append('address', address);
    userData.append('email', email);
    userData.append('phone', phoneNumber);
    if (image !== null) userData.append('image', image);

    if (department && department !== '-') {
      userData.append('department_id', department.split(['|'])[0]);
      userData.append('department_name', department.split(['|'])[1]);
    }
    if (province && province !== '-') {
      userData.append('province_id', province.split(['|'])[0]);
      userData.append('province_name', province.split(['|'])[1]);
    }
    if (district && district !== '-') {
      userData.append('district_id', district.split(['|'])[0]);
      userData.append('district_name', district.split(['|'])[1]);
    }
    userData.append('genre', genre || '');
    //userData.append('date_of_birth', birthDate || '');
    userData.append('study_level', studyLevel || '');
    userData.append('study_grade', studyGrade || '');
    userData.append('study_college', studyCollege || '');
    userData.append('study_career', studyCareer || '');
    const result = await generalApi.put(`/classroom/student/profile`, userData);
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return;
    }
    showSuccessToast('Datos guardados');
    getProfile();
  };

  const savePicture = async (newImage) => {
    if (!newImage) return;
    setLoading(true);
    let userData = new FormData();
    userData.append('student', StorageService.get('chosenStudent'));
    userData.append('image', newImage);
    const result = await generalApi.put(
      `/classroom/student/profile/picture`,
      userData
    );
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return;
    }
    setImage(null);
    showSuccessToast('Imagen actualizada');
    getProfile();
  };

  let checkImage = async (event) => {
    let isValid = validateFile(event.target, 'img');
    if (!isValid) {
      setImage(null);
      showErrorToast(
        'Formato de imagen no válido, debe ser un archivo .png, .jpg o .jpeg'
      );
      return;
    }
    setImage(event.target.files[0]);
    savePicture(event.target.files[0]);
  };

  let submitEditPassword = async () => {
    if (oldPassword === '' || newPassword === '') {
      return showErrorToast('Debe llenar todos los campos');
    }
    if (newPassword !== repeatPassword) {
      return showErrorToast('Las contraseñas no coinciden');
    }

    setPasswordLoading(true);
    const result = await generalApi.post(`/classroom/user/password`, {
      user_id: StorageService.get('userData')?._id,
      old_password: oldPassword,
      new_password: newPassword,
    });
    if (!result.success) {
      setPasswordLoading(false);
      showErrorToast(result.message);
      return;
    }
    showSuccessToast('Contraseña modificada!');
    setOldPassword('');
    setNewPassword('');
    setPasswordLoading(false);
  };

  return (
    <div className="mx-4 my-8 md:mx-16 md:my-12">
      <div className="rounded-2xl bg-white p-8 xl:px-16 xl:py-12">
        <div className="flex flex-col items-center gap-4 md:flex-row md:gap-8">
          <p className="text-2xl font-bold text-primary-light">
            DATOS DEL ALUMNO(A)
          </p>
          {StorageService.get('isProxy') ? (
            <div className="flex w-full max-w-sm flex-1 items-center">
              <label
                htmlFor="inp_chosen_student"
                className="hidden text-lg text-gray-600 sm:block"
              >
                Selecciona:
              </label>
              <div className="flex-1 sm:ml-4">
                <select
                  id="inp_chosen_student"
                  name="chosen_student"
                  value={chosenStudent}
                  onChange={(e) => setChosenStudent(e.target.value)}
                  className="inp-base w-full"
                >
                  {StorageService.get('students')?.map((obj) => (
                    <option key={obj._id} value={obj._id}>
                      {obj.firstName} {obj.lastName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}
        </div>

        <div className="mt-8 gap-y-8 sm:flex sm:gap-y-0 sm:gap-x-6">
          <div className="text-center md:pr-8">
            <div
              className="mx-auto mb-4 h-28 w-32 bg-no-repeat p-2 pr-6"
              style={{ backgroundImage: `url(${avatarShape})` }}
            >
              <img
                className="h-full w-full rounded-full"
                src={avatarUrl || avatar}
                alt="Avatar del usuario"
              />
            </div>
            <div>
              <label
                className="cursor-pointer text-lg font-light leading-none text-blue-600 underline hover:text-blue-400"
                htmlFor="image_file"
              >
                {loading ? 'Cargando ...' : 'Editar imagen'}
              </label>
              <input
                id="image_file"
                className="hidden"
                type="file"
                name="image"
                accept=".png, .jpg, .jpeg"
                onChange={checkImage}
              />
            </div>
            <button
              className="mt-2 cursor-pointer text-lg font-light leading-none text-blue-600 underline hover:text-blue-400"
              onClick={setInputFocus}
            >
              Editar contraseña
            </button>
          </div>

          <div className="hidden text-lg md:block">
            <p className="font-bold text-gray-800">
              {StorageService.get('chosenStudentData').docType}
            </p>
            <p className="font-bold text-gray-800">Nombre y Apellidos</p>
            <p className="font-bold text-gray-800">F. de nacimiento</p>
          </div>
          <div className="hidden text-lg md:block">
            <p className="font-bold text-gray-800">:</p>
            <p className="font-bold text-gray-800">:</p>
            <p className="font-bold text-gray-800">:</p>
          </div>
          <div className="hidden text-lg md:block">
            <p className="font-bold text-gray-800">
              {StorageService.get('chosenStudentData').docNumber}
            </p>
            <p className="font-bold text-gray-800">
              {StorageService.get('chosenStudentData').firstName}{' '}
              {StorageService.get('chosenStudentData').lastName}
            </p>
            <p className="font-bold text-gray-800">
              {formatDate(StorageService.get('chosenStudentData').birthdate)}
            </p>
          </div>

          <div className="mt-8 flex-1 text-center sm:text-left md:hidden">
            <p className="text-lg">
              <span className="font-bold text-gray-800">
                {StorageService.get('chosenStudentData').docType}:{' '}
              </span>{' '}
              <br className="sm:hidden" />{' '}
              {StorageService.get('chosenStudentData').docNumber}
            </p>
            <p className="text-lg">
              <span className="font-bold text-gray-800">
                Nombre y Apellidos:{' '}
              </span>{' '}
              <br className="sm:hidden" />{' '}
              {StorageService.get('chosenStudentData').firstName}{' '}
              {StorageService.get('chosenStudentData').lastName}
            </p>
            <p className="text-lg">
              <span className="font-bold text-gray-800">
                F. de nacimiento:{' '}
              </span>{' '}
              <br className="sm:hidden" />{' '}
              {formatDate(StorageService.get('chosenStudentData').birthdate)}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-8 rounded-2xl bg-white p-8 xl:px-16 xl:py-12">
        <p className="text-2xl font-bold text-primary-light">INFORMACIÓN</p>
        <div className="mt-8 gap-6 gap-y-4 text-left md:grid md:grid-cols-4 md:gap-y-8 xl:grid-cols-8">
          <div className="col-span-2">
            <label className="text-lg font-bold" htmlFor="inp_phone_number">
              Celular
            </label>
            <div className="mt-1">
              <input
                id="inp_phone_number"
                name="phone_number"
                type="text"
                placeholder="Celular"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') updateProfile();
                }}
                className="inp-base"
              ></input>
            </div>
          </div>
          <div className="col-span-2">
            <label className="text-lg font-bold" htmlFor="inp_email">
              Email
            </label>
            <div className="mt-1">
              <input
                id="inp_email"
                name="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') updateProfile();
                }}
                className="inp-base"
              ></input>
            </div>
          </div>
          <div className="col-span-4">
            <label className="text-lg font-bold" htmlFor="inp_address">
              Dirección
            </label>
            <div className="mt-1">
              <input
                id="inp_address"
                name="address"
                type="text"
                placeholder="Dirección"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') updateProfile();
                }}
                className="inp-base"
              ></input>
            </div>
          </div>
          <div className="col-span-2">
            <label className="text-lg font-bold" htmlFor="inp_department">
              Departamento
            </label>
            <div className="mt-1">
              <select
                id="inp_department"
                name="department"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                className="inp-base"
              >
                <option value="-">Departamento</option>
                {departments.map((obj) => (
                  <option key={obj.id} value={`${obj.id}|${obj.name}`}>
                    {obj.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-span-2">
            <label className="text-lg font-bold" htmlFor="inp_province">
              Provincia
            </label>
            <div className="mt-1">
              <select
                id="inp_province"
                name="province"
                value={province}
                onChange={(e) => setProvince(e.target.value)}
                className="inp-base"
              >
                <option value="-">Provincia</option>
                {provinces.map((obj) => (
                  <option key={obj.id} value={`${obj.id}|${obj.name}`}>
                    {obj.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-span-2">
            <label className="text-lg font-bold" htmlFor="inp_district">
              Distrito
            </label>
            <div className="mt-1">
              <select
                id="inp_district"
                name="district"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                className="inp-base"
              >
                <option value="-">Distrito</option>
                {districts.map((obj) => (
                  <option key={obj.id} value={`${obj.id}|${obj.name}`}>
                    {obj.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-span-2">
            <label className="text-lg font-bold" htmlFor="inp_genre">
              Sexo
            </label>
            <div className="mt-1">
              <select
                id="inp_genre"
                name="genre"
                placeholder="Sexo"
                className="inp-base"
                value={genre}
                onChange={(e) => setGenre(e.target.value)}
              >
                <option value="">Elegir sexo</option>
                <option>Masculino</option>
                <option>Femenino</option>
              </select>
            </div>
          </div>
          {/* <div className="col-span-2">
                <label className='font-bold text-lg' htmlFor="inp_date_of_birth">Fecha de nacimiento</label>
                <div className="mt-1">
                  <input
                    id="inp_date_of_birth"
                    name="date_of_birth"
                    type="date"
                    placeholder="Fecha de nacimiento"
                    className="relative inp-base"
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                  ></input>
                </div>
              </div> */}
          <div className="col-span-2">
            <label className="text-lg font-bold" htmlFor="inp_study_level">
              Nivel de Estudio
            </label>
            <div className="mt-1">
              <select
                id="inp_study_level"
                name="study_level"
                placeholder="Nivel de Estudio"
                className="inp-base"
                value={studyLevel}
                onChange={(e) => setStudyLevel(e.target.value)}
              >
                <option value="">Elegir Nivel de Estudio</option>
                <option>Inicial</option>
                <option>Primaria</option>
                <option>Secundaria</option>
                <option>Pre-Universitario</option>
                <option>Universitario</option>
              </select>
            </div>
          </div>
          {!['Pre-Universitario', 'Universitario'].includes(studyLevel) && (
            <div className="col-span-2 xl:col-span-3">
              <label className="text-lg font-bold" htmlFor="inp_grade">
                Grado de Estudio
              </label>
              <div className="mt-1">
                <select
                  id="inp_grade"
                  name="grade"
                  placeholder="Grado de Estudio"
                  className="inp-base"
                  value={studyGrade}
                  onChange={(e) => setStudyGrade(e.target.value)}
                >
                  <option value="">Elegir Grado de Estudio</option>
                  {studyLevel === 'Inicial' ? (
                    <>
                      <option>3 años</option>
                      <option>4 años</option>
                      <option>5 años</option>
                    </>
                  ) : studyLevel === 'Primaria' ? (
                    <>
                      <option>1ero</option>
                      <option>2do</option>
                      <option>3ero</option>
                      <option>4to</option>
                      <option>5to</option>
                      <option>6to</option>
                    </>
                  ) : studyLevel === 'Secundaria' ? (
                    <>
                      <option>1ero</option>
                      <option>2do</option>
                      <option>3ero</option>
                      <option>4to</option>
                      <option>5to</option>
                    </>
                  ) : null}
                </select>
              </div>
            </div>
          )}
          {(['Pre-Universitario', 'Universitario'].includes(studyLevel) ||
            (studyLevel === 'Secundaria' &&
              ['4to', '5to'].includes(studyGrade))) && (
            <>
              <div className="col-span-2 xl:col-span-3">
                <label className="text-lg font-bold" htmlFor="inp_college">
                  Universidad
                </label>
                <div className="mt-1">
                  <select
                    id="inp_college"
                    name="college"
                    placeholder="Universidad"
                    className="inp-base"
                    value={studyCollege}
                    onChange={(e) => setStudyCollege(e.target.value)}
                  >
                    <option value="">Elegir Universidad</option>
                    <option>Pontificia Universidad Católica del Perú</option>
                    <option>Universidad Católica San Pablo</option>
                    <option>Universidad Científica del Sur</option>
                    <option>Universidad de Lima</option>
                    <option>Universidad de Piura</option>
                    <option>Universidad del Pacífico</option>
                    <option>Universidad ESAN</option>
                    <option>Universidad Nacional Agraria La Molina</option>
                    <option>Universidad Nacional de Ingeniería</option>
                    <option>Universidad Nacional de la Amazonia Peruana</option>
                    <option>
                      Universidad Nacional de San Agustín de Arequipa
                    </option>
                    <option>
                      Universidad Nacional de San Antonio Abad del Cusco
                    </option>
                    <option>Universidad Nacional de Trujillo</option>
                    <option>Universidad Nacional del Altiplano</option>
                    <option>Universidad Nacional Federico Villarreal</option>
                    <option>Universidad Nacional Mayor de San Marcos</option>
                    <option>Universidad Peruana Cayetano Heredia</option>
                    <option>Universidad Peruana de Ciencias Aplicadas</option>
                    <option>Universidad Privada del Norte</option>
                    <option>Universidad Ricardo Palma</option>
                    <option>Universidad San Ignacio de Loyola</option>
                    <option>Universidad San Martín de Porres</option>
                    <option>Otros</option>
                  </select>
                </div>
              </div>

              <div className="col-span-2 xl:col-span-3">
                <label className="text-lg font-bold" htmlFor="inp_study_career">
                  Carrera
                </label>
                <div className="mt-1">
                  <select
                    id="inp_study_career"
                    name="study_career"
                    placeholder="Carrera"
                    className="inp-base"
                    value={studyCareer}
                    onChange={(e) => setStudyCareer(e.target.value)}
                  >
                    <option value="">Elegir Carrera</option>
                    <option>Administración</option>
                    <option>Antropología</option>
                    <option>Arqueología</option>
                    <option>Arquitectura </option>
                    <option>Arte</option>
                    <option>Auditoría empresarial y del sector público</option>
                    <option>
                      Bibliotecología y ciencias de la información
                    </option>
                    <option>Biología</option>
                    <option>Ciencia de la computación </option>
                    <option>Ciencia política</option>
                    <option>Ciencias biológicas</option>
                    <option>Ciencias de los alimentos</option>
                    <option>Computación científica</option>
                    <option>Comunicación social</option>
                    <option>Conservación y restauración</option>
                    <option>Contabilidad</option>
                    <option>Danza</option>
                    <option>Derecho</option>
                    <option>Economía</option>
                    <option>Educación</option>
                    <option>Educación física</option>
                    <option>Enfermería</option>
                    <option>Estadística</option>
                    <option>Farmacia y bioquímica</option>
                    <option>Filosofía</option>
                    <option>Física</option>
                    <option>Genética y biotecnología</option>
                    <option>Geografía</option>
                    <option>Gestión tributaria</option>
                    <option>Historia</option>
                    <option>Ingeniería agroindustrial</option>
                    <option>Ingeniería alimentaria</option>
                    <option>Ingeniería ambiental</option>
                    <option>Ingeniería biomédica</option>
                    <option>Ingeniería civil</option>
                    <option>
                      Ingeniería de higiene y seguridad industrial{' '}
                    </option>
                    <option>Ingeniería de minas</option>
                    <option>Ingeniería de petróleo y gas natural </option>
                    <option>
                      Ingeniería de seguridad y salud en el trabajo
                    </option>
                    <option>Ingeniería de sistemas </option>
                    <option>Ingeniería de software</option>
                    <option>Ingeniería de telecomunicaciones </option>
                    <option>Ingeniería de transportes</option>
                    <option>Ingeniería económica </option>
                    <option>Ingeniería eléctrica</option>
                    <option>Ingeniería electrónica</option>
                    <option>Ingeniería en acuicultura</option>
                    <option>Ingeniería en ecoturismo</option>
                    <option>Ingeniería estadística </option>
                    <option>Ingeniería física </option>
                    <option>Ingeniería geográfica</option>
                    <option>Ingeniería geológica</option>
                    <option>Ingeniería industrial</option>
                    <option>Ingeniería informática</option>
                    <option>Ingeniería mecánica </option>
                    <option>Ingeniería mecánica de fluidos</option>
                    <option>Ingeniería mecánica-eléctrica </option>
                    <option>Ingeniería mecatrónica</option>
                    <option>Ingeniería metalúrgica</option>
                    <option>Ingeniería naval </option>
                    <option>Ingeniería pesquera</option>
                    <option>Ingeniería petroquímica </option>
                    <option>Ingeniería química</option>
                    <option>Ingeniería sanitaria </option>
                    <option>Ingeniería textil </option>
                    <option>Investigación operativa</option>
                    <option>Laboratorio clínico y anatomía patológica</option>
                    <option>Lingüística</option>
                    <option>Literatura</option>
                    <option>Marketing</option>
                    <option>Matemática</option>
                    <option>Medicina humana</option>
                    <option>Medicina veterinaria</option>
                    <option>Microbiología y parasitología</option>
                    <option>Negocios internacionales</option>
                    <option>Nutrición</option>
                    <option>Obstetricia</option>
                    <option>Odontología</option>
                    <option>Optometría</option>
                    <option>Psicología</option>
                    <option>Química</option>
                    <option>Radiología</option>
                    <option>Sociología</option>
                    <option>Terapia de lenguaje</option>
                    <option>Terapia física y rehabilitación</option>
                    <option>Terapia ocupacional</option>
                    <option>Toxicología</option>
                    <option>Trabajo social</option>
                    <option>Otros</option>
                  </select>
                </div>
              </div>
            </>
          )}
          {!['Pre-Universitario', 'Universitario'].includes(studyLevel) && (
            <div className="col-span-2 xl:col-span-3" />
          )}

          <div className="col-span-2 pt-8 pr-8">
            <SolidButton widthFull onClick={updateProfile} isLoading={loading}>
              GUARDAR
            </SolidButton>
          </div>
        </div>
      </div>

      <div className="mt-8 rounded-2xl bg-white p-8 xl:px-16 xl:py-12">
        <p className="text-2xl font-bold text-primary-light">
          EDITAR CONTRASEÑA
        </p>
        <div className="mt-8 items-center gap-y-4 sm:flex sm:gap-y-0 sm:gap-x-6">
          <div className="flex-1">
            <label className="text-lg font-bold" htmlFor="inp_old_password">
              Contraseña actual
            </label>
            <div className="mt-1">
              <input
                id="inp_old_password"
                name="oldPassword"
                type="password"
                autoComplete="current-password"
                placeholder="Ingrese su contraseña actual"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') submitEditPassword();
                }}
                className="inp-base"
                ref={inputRef}
              ></input>
            </div>
          </div>
          <div className="flex-1">
            <label className="text-lg font-bold" htmlFor="inp_new_password">
              Contraseña nueva
            </label>
            <div className="mt-1">
              <input
                id="inp_new_password"
                name="new_password"
                type="password"
                autoComplete="new-password"
                placeholder="Ingrese su nueva contraseña"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') submitEditPassword();
                }}
                className="inp-base"
              ></input>
            </div>
          </div>
          <div className="flex-1">
            <label className="text-lg font-bold" htmlFor="inp_repeat_password">
              Repetir contraseña nueva
            </label>
            <div className="mt-1">
              <input
                id="inp_repeat_password"
                name="repeat_password"
                type="password"
                autoComplete="new-password"
                placeholder="Ingrese su nueva contraseña"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') submitEditPassword();
                }}
                className="inp-base"
              ></input>
            </div>
          </div>
        </div>
        <div className="mt-8 max-w-xs">
          <SolidButton onClick={submitEditPassword} isLoading={passwordLoading}>
            GUARDAR
          </SolidButton>
        </div>
      </div>
    </div>
  );
}
