import { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import Pagination from '../../../../components/Pagination';
import { SearchIcon } from '@heroicons/react/outline';

export default function ExamReport({
  examDescription,
  examQuestions,
  reportQuestions,
  onImageClick,
}) {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 8;

  useEffect(() => {
    setQuestions(reportQuestions);
    setPage(1);
    setMaxPage(Math.ceil(reportQuestions.length / amountPerPage));
    setTotal(reportQuestions.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportQuestions]);

  useEffect(() => {
    showQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  let showQuestions = async () => {
    setLoading(true);
    setQuestions([
      ...questions.slice(
        amountPerPage * (page - 1),
        amountPerPage * (page - 1) + amountPerPage
      ),
    ]);
    setLoading(false);
  };

  return (
    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-8">
      <p className="text-xl">Calificación examen: {examDescription}</p>
      <div className="mt-6 min-w-full overflow-hidden overflow-x-auto align-middle shadow-md">
        <table className="min-w-full divide-gray-200">
          <thead>
            <tr>
              <th className="bg-light-blue px-6 py-3 text-left text-sm font-light uppercase tracking-wider text-white">
                # DE PREGUNTA
              </th>
              <th className="bg-light-blue px-6 py-3 text-left text-sm font-light uppercase tracking-wider text-white">
                PREGUNTA
              </th>
              <th className="bg-light-blue px-6 py-3 text-left text-sm font-light uppercase tracking-wider text-white">
                IMAGEN
              </th>
              <th className="bg-light-blue px-6 py-3 text-left text-sm font-light uppercase tracking-wider text-white">
                RESPUESTAS MARCADAS
              </th>
              <th className="bg-light-blue px-6 py-3 text-left text-sm font-light uppercase tracking-wider text-white">
                RESPUESTAS CORRECTAS
              </th>
            </tr>
          </thead>
          <Transition
            show={!loading}
            as="tbody"
            className="divide-y divide-gray-200 bg-white"
          >
            {questions.map((obj, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? 'bg-white' : 'bg-gray-body'}
              >
                <td className="whitespace-nowrap px-6 py-3 text-left text-base text-gray-700">
                  {((page - 1) * amountPerPage + index + 1).toLocaleString(
                    'en-US',
                    {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    }
                  )}
                </td>
                <td
                  className="whitespace-nowrap px-6 py-3 text-center text-base text-gray-700"
                  dangerouslySetInnerHTML={{
                    __html:
                      examQuestions.find(
                        (question) => question._id === obj.question
                      )?.content || '',
                  }}
                />
                <td className="whitespace-nowrap px-6 py-3 text-center text-base text-gray-700">
                  {examQuestions.find(
                    (question) => question._id === obj.question
                  )?.image_url && (
                    <button
                      className="group inline-block rounded-full border-2 border-primary p-3 transition-colors duration-300 hover:bg-primary"
                      onClick={() =>
                        onImageClick(
                          examQuestions.findIndex(
                            (question) => question._id === obj.question
                          )
                        )
                      }
                    >
                      <SearchIcon className="m-0 h-6 w-6 text-primary transition-colors duration-300 group-hover:text-white" />
                    </button>
                  )}
                </td>
                <td
                  className="whitespace-nowrap px-6 py-3 text-center text-base text-gray-700"
                  dangerouslySetInnerHTML={{
                    __html: obj.answer,
                  }}
                />
                <td className="whitespace-nowrap px-6 py-3 text-center text-base font-bold text-light-blue">
                  {obj.correct_answer}
                </td>
              </tr>
            ))}
          </Transition>
        </table>
      </div>
      <Transition
        show={loading}
        className="flex items-center bg-white p-6 shadow-md"
      >
        <LoadingSpinner color="primary"></LoadingSpinner>
      </Transition>
      <Transition
        show={!loading && questions.length === 0}
        className="flex items-center bg-white p-6 shadow-md"
      >
        <p>No se encontraron resultados</p>
      </Transition>
      {/* Pagination */}
      <Pagination
        className="mt-8"
        page={page}
        total={total}
        maxPage={maxPage}
        onPreviousPage={() => {
          setPage(page - 1);
        }}
        onNextPage={() => {
          setPage(page + 1);
        }}
        onSelectedPage={(newPage) => {
          setPage(newPage);
        }}
      />
    </div>
  );
}
