import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { ShoppingCartIcon } from '@heroicons/react/outline';

import StorageService from '../../../utils/storage';
import GeneralApi from '../../../utils/generalApi';
import { useAuth } from '../../../contexts/Auth';

import { showErrorToast } from '../../../components/Toast';
import Stepper from '../../misc/Inscription/Stepper';
import Step1 from './ShoppingCartSteps/Step1';
import Step2 from './ShoppingCartSteps/Step2';
import Modal from '../../../components/Modal';
import { formatSchedule } from '../../../utils/functions';
import { constants } from '../../../utils/constants';
import OutlineButton from '../../../components/OutlineButton';
import SolidButton from '../../../components/SolidButton';
import Step3 from './ShoppingCartSteps/Step3';
import Step4 from './ShoppingCartSteps/Step4';

export default function ShoppingCartPage() {
  const history = useHistory();
  const auth = useAuth();

  let { courseId } = useParams();
  const [currentStep, setCurrentStep] = useState(courseId ? 0 : 2);
  const [course, setCourse] = useState(null);
  const [pendingPayments, setPendingPayments] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selectedPayments, setSelectedPayments] = useState(0);
  const [discountCode, setDiscountCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isAddedLineOpen, setIsAddedLineOpen] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [isPaidModalOpen, setIsPaidModalOpen] = useState(false);

  const [lines, setLines] = useState([]);
  const [currentLine, setCurrentLine] = useState(null);

  const form = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      doc_type: StorageService.get('chosenStudentData').docType,
      doc_number: StorageService.get('chosenStudentData').docNumber,
    },
  });

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    if (courseId) getCourseData();
    getShoppingCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  useEffect(() => {
    setSelectedPayments(
      !pendingPayments?.[0]?.paid
        ? 0
        : pendingPayments.filter((obj) => obj.paid).length + 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPayments]);

  let getCourseData = async (id) => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/course/pending_payments`, {
      course_id: id || courseId,
      student_id: StorageService.get('chosenStudent'),
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    if (result.data.amount <= 0) {
      history.replace(`/my_courses/${id || courseId}/report`);
    }
    setCourse(result.data.course);
    setPendingPayments(
      result?.data?.payments
        ?.filter((obj) => !obj.is_removed)
        .map((obj) => ({
          ...obj,
          amount: obj.amount - (obj.discount || 0),
        })) ||
        result?.data?.course?.payments ||
        []
    );
    setLoading(false);
  };

  let getShoppingCart = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/shopping_cart`, {
      student_id: StorageService.get('chosenStudent'),
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    StorageService.set('shopping_cart_lines', result.data.lines || 0);
    auth.setShoppingCartLines(result.data.lines || 0);
    setLines(result.data.shoppingCart.lines);
    setLoading(false);
  };

  let addToShoppingCart = async () => {
    setLoading(true);
    const result = await generalApi.post(
      currentLine
        ? `/classroom/shopping_cart/update`
        : `/classroom/shopping_cart/add`,
      {
        course_id: course._id,
        student_id: StorageService.get('chosenStudent'),
        line: {
          _id: currentLine || undefined,
          course: course._id,
          payments: [
            !pendingPayments?.[0]?.paid &&
              course.enroll_cost?.suggested && {
                name: 'Matrícula',
                amount: course.enroll_cost?.suggested,
                is_enroll_payment: true,
                to_pay: true,
              },
            ...pendingPayments.map((obj, index) =>
              !obj.paid
                ? {
                    ...obj,
                    name: `Derecho de enseñanza ${
                      !pendingPayments?.[0]?.paid &&
                      course.enroll_cost?.suggested
                        ? index + 1
                        : index
                    }`,
                    to_pay: index <= selectedPayments,
                  }
                : null
            ),
          ].filter((obj) => Boolean(obj)),
          total:
            pendingPayments?.reduce(
              (a, b, currentIndex) =>
                !b.paid && currentIndex <= selectedPayments ? a + b.amount : a,
              0
            ) +
            (!pendingPayments?.[0]?.paid
              ? course.enroll_cost?.suggested || 0
              : 0),
          discount_code: discountCode,
          discount_amount: discountAmount,
          student: StorageService.get('chosenStudent'),
          student_data: form.getValues(),
        },
      }
    );
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    if (!currentLine) {
      StorageService.set('shopping_cart_lines', (lines?.length || 0) + 1);
      auth.setShoppingCartLines((lines?.length || 0) + 1);
    }
    setIsAddedLineOpen(true);
    setLoading(false);
  };

  const steps = [
    {
      id: '1',
      name: 'Identificación',
      content: (
        <Step1
          course={course}
          form={form}
          studentId={studentId}
          onStudentFound={(newValue) => setStudentId(newValue)}
          onContinue={async () => {
            if (
              await form.trigger([
                'doc_type',
                'doc_number',
                'name',
                'last_name_p',
                'last_name_m',
                'phone',
                'email',
              ])
            )
              setCurrentStep(1);
          }}
        />
      ),
    },
    {
      id: '2',
      name: 'Selecciona',
      content: (
        <Step2
          course={course}
          pendingPayments={pendingPayments}
          selectedPayments={selectedPayments}
          setSelectedPayments={(newValue) => setSelectedPayments(newValue)}
          discountCode={discountCode}
          setDiscountCode={(newValue) => setDiscountCode(newValue)}
          discountAmount={discountAmount}
          setDiscountAmount={(newValue) => setDiscountAmount(newValue)}
          buttonLoading={loading}
          onContinue={() => addToShoppingCart()}
        />
      ),
    },
    {
      id: '3',
      name: 'Carro de compras',
      content: (
        <Step3
          isLoading={loading}
          lines={lines}
          onReload={() => getShoppingCart()}
          onEdit={async (line) => {
            await getCourseData(line.course._id);
            form.setValue('doc_type', line.student_data.doc_type);
            form.setValue('doc_number', line.student_data.doc_number);
            setCurrentStep(0);
            setCurrentLine(line._id);
          }}
          onContinue={() => setCurrentStep(3)}
        />
      ),
    },
    {
      id: '4',
      name: 'Pago',
      content: (
        <Step4
          lines={lines}
          onContinue={(url) => {
            setIsPaidModalOpen(true);
            setInvoiceUrl(url);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <Modal
        isOpen={isAddedLineOpen}
        onClose={() => {
          setIsAddedLineOpen(false);
          setCurrentStep(2);
          getShoppingCart();
        }}
      >
        <div className="flex rounded-t-lg bg-primary p-5 text-white">
          <ShoppingCartIcon className="mr-2 h-6 w-6 stroke-2" />
          Producto agregado
        </div>
        <div className="rounded-b-lg border-2 border-primary bg-white p-4">
          {course && (
            <div className="px-8">
              <img
                src={constants.SCHOOLS_FORMAT[course.school.name].logoSmall}
                className="mx-auto mb-6 h-12 w-12 bg-no-repeat sm:h-16 sm:w-16 lg:h-24 lg:w-24"
                alt="Logo"
              />
              <p className="text-center text-xl font-bold text-primary">
                {course.name.name}
              </p>
              <div className="mt-6 text-left">
                <div className="grid grid-cols-4 gap-4 py-1">
                  <dt className="col-span-2 text-lg text-gray-500">Sede:</dt>
                  <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                    {course.campus.name || '-'}
                  </dd>
                </div>
                <div className="grid grid-cols-4 gap-4 py-1">
                  <dt className="col-span-2 text-lg text-gray-500">Ciclo:</dt>
                  <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                    {course.classroom?.name || '-'}
                  </dd>
                </div>
                <div className="grid grid-cols-4 gap-4 py-1">
                  <dt className="col-span-2 text-lg text-gray-500">Turno:</dt>
                  <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                    {course.classroom_turn
                      ? constants.TURNS[course.classroom_turn]
                      : course.classroom_number?.name || '-'}
                  </dd>
                </div>
                <div className="grid grid-cols-4 gap-4 py-1">
                  <dt className="col-span-2 text-lg text-gray-500">Horario:</dt>
                  <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                    {formatSchedule(course.schedule.name)}
                  </dd>
                </div>
                <div className="grid grid-cols-4 gap-4 py-1">
                  <dt className="col-span-2 text-lg text-gray-500">
                    Modalidad:
                  </dt>
                  <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                    {course.classroom_section || '-'}
                  </dd>
                </div>
                <div className="grid grid-cols-4 gap-4 py-1">
                  <dt className="col-span-2 text-lg text-gray-500">Aula:</dt>
                  <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                    {course.classroom_number?.name || '-'}
                  </dd>
                </div>
                {discountAmount > 0 && (
                  <div className="mt-1 grid grid-cols-4 items-end gap-4 pb-1">
                    <dt className="col-span-2 text-lg text-gray-500">
                      Descuento:
                    </dt>
                    <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                      S/ {discountAmount}
                    </dd>
                  </div>
                )}
                <div className="mt-1 grid grid-cols-4 items-end gap-4 pb-1">
                  <dt className="col-span-2 text-lg text-gray-500">Monto:</dt>
                  <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                    S/{' '}
                    {pendingPayments?.reduce(
                      (a, b, currentIndex) =>
                        !b.paid && currentIndex <= selectedPayments
                          ? a + b.amount
                          : a,
                      0
                    ) +
                      (!pendingPayments?.[0]?.paid
                        ? course.enroll_cost?.suggested || 0
                        : 0) -
                      discountAmount}
                  </dd>
                </div>
              </div>
            </div>
          )}
          <div className="mt-6 items-center space-y-3 sm:flex sm:space-y-0 sm:space-x-3">
            <OutlineButton href="/courses" size="sm" className="text-center">
              MATRICULAR + CURSOS
            </OutlineButton>
            <SolidButton
              size="sm"
              color={constants.SCHOOLS_FORMAT[course?.school?.name]?.color}
              onClick={() => {
                setIsAddedLineOpen(false);
                setCurrentStep(2);
                getShoppingCart();
              }}
            >
              IR A PAGAR
            </SolidButton>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isPaidModalOpen}
        size="lg"
        onClose={() => {
          StorageService.set('shopping_cart_lines', 0);
          auth.setShoppingCartLines(0);
          history.replace('/my_courses');
        }}
      >
        <div className="flex rounded-t-lg bg-primary p-5 text-white">
          <ShoppingCartIcon className="mr-2 h-6 w-6 stroke-2" />
          Confirmación de compra
        </div>
        <div className="rounded-b-lg border-2 border-primary bg-white">
          <div className="w-full overflow-hidden overflow-x-auto">
            <table className="w-full divide-gray-200">
              <thead>
                <tr>
                  <th className="bg-light-blue px-6 py-4 text-left text-lg font-light uppercase tracking-wider text-white">
                    Curso
                  </th>
                  <th className="bg-light-blue px-6 py-4 text-left text-lg font-light uppercase tracking-wider text-white">
                    Detalle
                  </th>
                  <th className="bg-light-blue px-6 py-4 text-left text-lg font-light uppercase tracking-wider text-white">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {lines?.map((obj, index) => (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0
                        ? 'rounded-b-lg bg-white'
                        : 'rounded-b-lg bg-gray-body'
                    }
                  >
                    <td className="whitespace-nowrap p-3 text-left text-lg text-gray-700">
                      <img
                        src={
                          constants.SCHOOLS_FORMAT[obj.course.school.name]
                            .logoSmall
                        }
                        className="mx-auto mb-2 h-12 w-12 bg-no-repeat"
                        alt="Logo"
                      />
                      <p className="text-center font-bold text-primary">
                        {obj.course.name.name}
                      </p>
                    </td>
                    <td className="whitespace-nowrap p-3 text-left text-lg text-gray-700">
                      <div className="grid-cols-4 gap-4 py-1 sm:grid">
                        <dt className="col-span-2 text-lg text-gray-500">
                          Sede:
                        </dt>
                        <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                          {obj.course.campus.name || '-'}
                        </dd>
                      </div>
                      <div className="grid-cols-4 gap-4 py-1 sm:grid">
                        <dt className="col-span-2 text-lg text-gray-500">
                          Ciclo:
                        </dt>
                        <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                          {obj.course.classroom.name || '-'}
                        </dd>
                      </div>
                      <div className="grid-cols-4 gap-4 py-1 sm:grid">
                        <dt className="col-span-2 text-lg text-gray-500">
                          Turno:
                        </dt>
                        <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                          {obj.course.classroom_turn
                            ? constants.TURNS[obj.course.classroom_turn]
                            : obj.course.classroom_number?.name || '-'}
                        </dd>
                      </div>
                      <div className="grid-cols-4 gap-4 py-1 sm:grid">
                        <dt className="col-span-2 text-lg text-gray-500">
                          Horario:
                        </dt>
                        <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                          {formatSchedule(obj.course.schedule.name)}
                        </dd>
                      </div>
                      <div className="grid-cols-4 gap-4 py-1 sm:grid">
                        <dt className="col-span-2 text-lg text-gray-500">
                          Modalidad:
                        </dt>
                        <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                          {obj.course.classroom_section || '-'}
                        </dd>
                      </div>
                      <div className="grid-cols-4 gap-4 py-1 sm:grid">
                        <dt className="col-span-2 text-lg text-gray-500">
                          Aula:
                        </dt>
                        <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                          {obj.course.classroom_number?.name || '-'}
                        </dd>
                      </div>
                    </td>
                    <td className="flex flex-col whitespace-nowrap p-3 text-left text-lg text-gray-700">
                      <div>
                        {obj.discount_amount > 0 && (
                          <div className="mt-1 grid-cols-4 items-end gap-4 pb-1 sm:grid">
                            <dt className="col-span-2 text-lg text-gray-500">
                              Descuento:
                            </dt>
                            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                              S/ {obj.discount_amount.toFixed(2)}
                            </dd>
                          </div>
                        )}
                        <div className="mt-1 grid-cols-4 items-end gap-4 pb-1 sm:grid">
                          <dt className="col-span-2 text-lg text-gray-500">
                            Monto:
                          </dt>
                          <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
                            S/{' '}
                            {(obj.total - (obj.discount_amount || 0)).toFixed(
                              2
                            )}
                          </dd>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="px-3">
            {lines
              .reduce((a, b) => a + (b.discount_amount || 0), 0)
              .toFixed(2) > 0 && (
              <p className="mt-2 text-right text-xl font-bold text-primary">
                <span className="mr-12">Descuento:</span>
                S/{' '}
                {lines
                  .reduce((a, b) => a + (b.discount_amount || 0), 0)
                  .toFixed(2)}
              </p>
            )}
            <p className="mt-2 text-right text-xl font-bold text-primary">
              <span className="mr-12">Total pagado:</span>
              S/{' '}
              {lines
                .reduce((a, b) => a + b.total - (b.discount_amount || 0), 0)
                .toFixed(2)}
            </p>
          </div>
          {
            //invoiceUrl && invoiceUrl !== '' && (
            <a
              href={invoiceUrl}
              target="_blank"
              rel="noreferrer"
              className="focus:outline-none mx-auto my-8 flex w-6/7 justify-center rounded-xl border border-transparent bg-primary px-2 py-2 text-center text-xl font-medium text-white shadow-sm hover:bg-primary-dark focus:ring-2 focus:ring-primary-light focus:ring-offset-2 lg:w-1/2"
            >
              DESCARGAR COMPROBANTE
            </a>
          }
        </div>
      </Modal>
      <div className="border-b-2 border-gray-300 px-6 py-6 sm:px-12">
        <div className="flex items-center">
          <ShoppingCartIcon className="mr-2 h-12 w-12 stroke-1 text-primary" />
          <span className="text-2xl font-light text-primary">
            Carrito de Compras {lines && `(${lines.length} productos)`}
          </span>
        </div>
      </div>
      <div className="py-8 md:px-12">
        <Stepper steps={steps} currentStep={currentStep} />
        <form className="mx-auto mt-4 bg-white p-4 shadow sm:p-8 md:rounded-md lg:px-12 xl:px-24">
          {steps[currentStep].content}
        </form>
      </div>
    </div>
  );
}
