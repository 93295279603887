export default function ErrorMessage({
  errors,
  field,
  content = 'Campo obligatorio',
  className = '',
}) {
  const fields = field.split('.');
  let error =
    fields.length === 1
      ? errors[field]
      : fields.length === 3
      ? errors[fields[0]]?.[fields[1]]?.[fields[2]]
      : null;
  return error ? (
    <p className={`${className} text-sm text-red`}>
      {error?.message || content}
    </p>
  ) : null;
}
