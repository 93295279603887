import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../contexts/Auth';

import background from '../../resources/img/backgrounds/signup.png';
import loginShape from '../../resources/img/backgrounds/login_shape.svg';
import loginShape2 from '../../resources/img/backgrounds/login_shape_2.svg';

import SolidButton from '../../components/SolidButton';
import { showErrorToast, showSuccessToast } from '../../components/Toast';
import GeneralApi from '../../utils/generalApi';

export default function ForgotPassword() {
  let history = useHistory();
  let auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const [completed, setCompleted] = useState(false);

  const generalApi = new GeneralApi(auth, history);

  let submitForgotPassword = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/recover/password/email`, {
      email,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    showSuccessToast('Correo enviado!');
    setLoading(false);
    setCompleted(true);
  };

  return (
    <div
      className="justify-top relative flex min-h-screen flex-col bg-cover"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div
        className="absolute top-0 left-0 z-0 hidden h-full w-full max-w-2xl bg-no-repeat md:block lg:max-w-3xl xl:max-w-5xl"
        style={{ backgroundImage: `url(${loginShape})` }}
      />
      <div
        className="absolute bottom-0 right-0 z-0 hidden h-full w-full max-w-xl bg-right bg-no-repeat md:block"
        style={{ backgroundImage: `url(${loginShape2})` }}
      />
      <div className="z-10 py-20 sm:mx-auto sm:w-full sm:max-w-lg sm:px-2">
        <div className="bg-white px-4 py-11 shadow sm:rounded-2xl sm:px-12">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-2xl font-light text-primary">
              RECUPERAR CONTRASEÑA
            </h2>
          </div>

          {completed ? (
            <div className="mt-8 justify-center text-center text-xl font-light">
              <p>
                Se envió un mensaje a tu correo electrónico, en caso no llegue
                revisa en la carpeta de spam.
              </p>
            </div>
          ) : (
            <>
              <div className="mt-8 text-center">
                <span className="justify-center text-center text-xl font-light">
                  Para restablecer tu contraseña debes ingresar tu email
                  asociado a la cuenta, te enviaremos un mensaje.
                </span>
              </div>
              <div className="mt-8">
                <div>
                  <label htmlFor="inp_email" className="sr-only">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="inp_email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={email}
                      autoFocus
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') submitForgotPassword();
                      }}
                      className="inp-base"
                    ></input>
                  </div>
                </div>

                <div className="mt-8">
                  <SolidButton
                    onClick={submitForgotPassword}
                    isLoading={loading}
                  >
                    ENVIAR
                  </SolidButton>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
