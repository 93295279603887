import { BookmarkAltIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';

import SolidButton from '../../../components/SolidButton';

export default function TutorDetails({
  tutor,
  isDelegation,
  index,
  finished,
  onEditClick,
}) {
  return (
    <>
      <div className="mb-8 overflow-hidden border border-gray-200 bg-white sm:rounded-lg">
        <div className="space-between flex px-4 py-5 sm:px-6">
          <h3 className="flex flex-1 items-center text-lg font-medium leading-6 text-gray-900">
            <BookmarkAltIcon className="mr-2 h-6 w-6" />
            {isDelegation ? `Docente asesor ${index + 1}` : 'Apoderado'}
          </h3>
          {!finished && (
            <div>
              <SolidButton size="sm" onClick={onEditClick}>
                <PencilIcon className="h-6 w-6 text-white" />
              </SolidButton>
            </div>
          )}
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Tipo de documento
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{tutor?.docType}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Número de documento
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{tutor?.docNumber}</dd>
            </div>
            <div className="sm:col-span-1"></div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Apellido Paterno
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{tutor?.lastNameP}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Apellido Materno
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{tutor?.lastNameM}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Nombres</dt>
              <dd className="mt-1 text-sm text-gray-900">{tutor?.name}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Correo electrónico
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{tutor?.email}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Teléfono fijo
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {tutor?.phone || '-'}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Celular</dt>
              <dd className="mt-1 text-sm text-gray-900">{tutor?.cellphone}</dd>
            </div>
            {isDelegation ? (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Especialidad
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {tutor?.specialty}
                </dd>
              </div>
            ) : (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Parentesco
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {tutor?.relation}
                </dd>
              </div>
            )}
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Nivel</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {tutor?.level1 && (
                  <span className="mr-4 inline-flex items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-sm font-medium text-indigo-800">
                    <svg
                      className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    I
                  </span>
                )}
                {tutor?.level2 && (
                  <span className="mr-4 inline-flex items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-sm font-medium text-indigo-800">
                    <svg
                      className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    II
                  </span>
                )}
                {tutor?.level3 && (
                  <span className="mr-4 inline-flex items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-sm font-medium text-indigo-800">
                    <svg
                      className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    III
                  </span>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
