import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { ShoppingCartIcon } from '@heroicons/react/outline';

import whiteLogo from '../../resources/img/logos/logo_edtlab.png';
import avatar from '../../resources/img/illustrations/avatar.png';
import avatarShape from '../../resources/img/backgrounds/avatar.svg';

import StorageService from '../../utils/storage';
import Footer from '../Footer';
import SidebarItems from './SidebarItems';

export default function Sidebar(props) {
  const location = useLocation();
  let history = useHistory();
  let auth = useAuth();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDesktopSidebarOpen, setIsDesktopSidebarOpen] = useState(
    !location.pathname.includes('/exam/') ||
      location.pathname.includes('/exam/result')
  );

  return (
    <div className="font-gotham font-light">
      <div className="flex h-screen overflow-hidden bg-gray-body font-gotham">
        {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
        <Transition show={isSidebarOpen}>
          <div className="lg:hidden">
            <div className="fixed inset-0 z-40 flex">
              {/*
              Off-canvas menu overlay, show/hide based on off-canvas menu state.

              Entering: "transition-opacity ease-linear duration-300"
                From: "opacity-0"
                To: "opacity-100"
              Leaving: "transition-opacity ease-linear duration-300"
                From: "opacity-100"
                To: "opacity-0"
            */}
              <Transition.Child
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="fixed inset-0"
                aria-hidden="true"
                onClick={() => setIsSidebarOpen(false)}
              >
                <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
              </Transition.Child>
              {/*
              Off-canvas menu, show/hide based on off-canvas menu state.

              Entering: "transition ease-in-out duration-300 transform"
                From: "-translate-x-full"
                To: "translate-x-0"
              Leaving: "transition ease-in-out duration-300 transform"
                From: "translate-x-0"
                To: "-translate-x-full"
            */}
              <Transition.Child
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
                className="relative flex w-full max-w-xs flex-1 flex-col bg-sidebar pt-5 pb-4"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    className="focus:outline-none ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:ring-2 focus:ring-inset focus:ring-white"
                  >
                    <span className="sr-only">Close sidebar</span>
                    {/* Heroicon name: x */}
                    <svg
                      className="h-6 w-6 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <img
                  className="mx-auto h-16"
                  src={whiteLogo}
                  alt="Logo de EDTLAB"
                />
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <div className="text-center">
                    <div
                      className="mx-auto my-4 h-20 w-24 bg-no-repeat p-1.5 pr-6"
                      style={{ backgroundImage: `url(${avatarShape})` }}
                    >
                      <img
                        className="h-full w-full rounded-full"
                        src={
                          StorageService.get('chosenStudentData').avatarUrl ||
                          avatar
                        }
                        alt="Avatar del usuario"
                      />
                    </div>
                    <span className="text-lg font-light leading-none text-white">
                      {StorageService.get('chosenStudentData').firstName}{' '}
                      {StorageService.get('chosenStudentData').lastName}
                    </span>
                    <Link
                      to="/profile"
                      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                      <p className="text-md cursor-pointer font-light leading-none text-blue-500 underline hover:text-blue-400">
                        Editar imagen
                      </p>
                    </Link>
                  </div>
                  <SidebarItems
                    onCloseClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    className="mt-12 space-y-1"
                  />
                </div>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </div>
        </Transition>

        {/* Static sidebar for desktop */}
        <Transition
          show={isDesktopSidebarOpen}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className="hidden lg:flex lg:flex-shrink-0"
        >
          <div className="flex w-80 flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-sidebar pt-8 pb-4">
              <img
                className="h-18 mx-auto"
                src={whiteLogo}
                alt="Logo de EDTLAB"
              />
              <div className="mt-12 flex flex-grow flex-col">
                <div className="text-center">
                  <div
                    className="mx-auto my-4 h-28 w-32 bg-no-repeat p-1.5 pr-6"
                    style={{ backgroundImage: `url(${avatarShape})` }}
                  >
                    <img
                      className="h-full w-full rounded-full"
                      src={
                        StorageService.get('chosenStudentData').avatarUrl ||
                        avatar
                      }
                      alt="Avatar del usuario"
                    />
                  </div>
                  <span className="text-lg font-medium leading-none text-white">
                    {StorageService.get('chosenStudentData').firstName}{' '}
                    {StorageService.get('chosenStudentData').lastName}
                  </span>
                  <Link to="/profile">
                    <p className="text-md cursor-pointer font-light leading-none text-blue-500 underline hover:text-blue-400">
                      Editar imagen
                    </p>
                  </Link>
                </div>
                <SidebarItems className="mt-16 flex-1 space-y-1 bg-sidebar" />
              </div>
            </div>
          </div>
        </Transition>

        <div className="flex w-0 flex-1 flex-col overflow-hidden">
          <main
            className="focus:outline-none relative flex-1 overflow-y-auto"
            tabIndex="0"
            id="scroller"
          >
            <div className="min-h-screen">
              {(!location.pathname.includes('/exam/') ||
                location.pathname.includes('/exam/result')) && (
                <header className="bg-white lg:static lg:overflow-y-visible">
                  <div className="relative z-20 flex h-20 flex-shrink-0 bg-white shadow-sm sm:space-x-4 sm:px-6">
                    <button
                      onClick={() => {
                        setIsSidebarOpen(!isSidebarOpen);
                        setIsDesktopSidebarOpen(!isDesktopSidebarOpen);
                      }}
                      className="focus:outline-none m-3 rounded-full px-2 text-primary focus:ring-2 focus:ring-inset focus:ring-primary"
                    >
                      <span className="sr-only">Open sidebar</span>
                      {/* Heroicon name: menu-alt */}
                      <svg
                        className="h-10 w-10"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={4}
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                    <div className="hidden flex-shrink-0 items-center sm:flex">
                      <p className="text-2xl font-bold xl:text-3xl">
                        {props.title}
                      </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:px-5">
                      <div className="flex flex-1"></div>
                      {auth.shoppingCartLines > 0 && (
                        <Link
                          to="/shopping_cart"
                          className="group my-3 flex cursor-pointer items-center rounded-xl px-2 transition-all duration-300 hover:bg-primary sm:px-3"
                        >
                          <ShoppingCartIcon className="mr-2 h-8 w-8 stroke-1 text-primary transition-all duration-300 group-hover:text-white sm:h-10 sm:w-10" />
                          <span className="hidden text-2xl font-light text-primary transition-all duration-300 group-hover:text-white sm:block">
                            Mi Carro
                          </span>
                          <div className="ml-2 flex h-8 w-8 items-center justify-center rounded-full bg-primary font-bold text-white transition-all duration-300 group-hover:bg-gray-body group-hover:text-primary sm:h-10 sm:w-10">
                            {auth.shoppingCartLines}
                          </div>
                        </Link>
                      )}
                      <div className="flex items-center lg:ml-6">
                        <div className="relative inline-block text-left">
                          <button
                            className="focus:outline-none flex max-w-xs items-center rounded-full bg-white px-2 text-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            onClick={() => {
                              auth.signout(() => history.push('/'));
                            }}
                          >
                            <span className="sr-only">Open user menu</span>
                            <span className="flex w-full items-center justify-between">
                              <p className="truncate text-lg font-light text-red-500">
                                Cerrar sesión
                              </p>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
              )}
              {props.children}
            </div>
            {(!location.pathname.includes('/exam/') ||
              location.pathname.includes('/exam/result')) && (
              <Footer isSidebarOpen={isDesktopSidebarOpen}></Footer>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
