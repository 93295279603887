import ErrorMessage from '../../../components/ErrorMessage';

export default function Step1({
  register,
  errors,
  departments,
  provinces,
  districts,
}) {
  return (
    <div className="gap-4 space-y-2 sm:grid sm:grid-cols-4 sm:space-y-0 lg:grid-cols-6">
      <p className="col-span-4 mb-2 flex justify-between lg:col-span-6">
        Completa los siguientes campos de la Institución Educativa
      </p>
      <div className="col-span-4 flex space-x-4 lg:col-span-6">
        <div className="flex items-center">
          <input
            id="is_dre"
            name="is_dre"
            type="checkbox"
            className="h-6 w-6 rounded border-gray-300 text-primary focus:ring-primary"
            {...register('isDre')}
          />
          <label htmlFor="is_dre" className="ml-2 block text-lg text-gray-900">
            DRE
          </label>
        </div>
        <div className="flex items-center">
          <input
            id="is_ugel"
            name="is_ugel"
            type="checkbox"
            className="h-6 w-6 rounded border-gray-300 text-primary focus:ring-primary"
            {...register('isUgel')}
          />
          <label htmlFor="is_ugel" className="ml-2 block text-lg text-gray-900">
            UGEL
          </label>
        </div>
      </div>
      <div className="col-span-4">
        <label htmlFor="inp_school_name">Nombre del Colegio</label>
        <div className="mt-1">
          <input
            id="inp_school_name"
            name="school_name"
            type="text"
            placeholder="Nombre del Colegio"
            className="inp-base"
            {...register('name', { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field="Name" />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <label htmlFor="inp_school_code">Código modular</label>
        <div className="mt-1">
          <input
            id="inp_school_code"
            name="school_code"
            type="number"
            placeholder="Código modular"
            className="inp-base"
            {...register('code', {
              required: true,
              minLength: {
                value: 7,
                message: 'Ingrese un número de 7 dígitos',
              },
              maxLength: {
                value: 7,
                message: 'Ingrese un número de 7 dígitos',
              },
            })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field="code" />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <label htmlFor="inp_school_type">Tipo de I.E.</label>
        <div className="mt-1">
          <select
            id="inp_school_type"
            name="school_type"
            placeholder="Tipo de I.E."
            className="inp-base"
            {...register('kind', { required: true })}
          >
            <option>Estatal</option>
            <option>Privada</option>
          </select>
        </div>
        <ErrorMessage errors={errors} field="kind" />
      </div>
      <div className="col-span-2">
        <label htmlFor="inp_school_email">Correo electrónico</label>
        <div className="mt-1">
          <input
            id="inp_school_email"
            name="school_email"
            type="email"
            placeholder="Correo electrónico"
            className="inp-base"
            {...register('email', {
              required: true,
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Ingrese un correo válido',
              },
            })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field="Email" />
      </div>
      <div className="col-span-2">
        <label htmlFor="inp_school_phone">Teléfono</label>
        <div className="mt-1">
          <input
            id="inp_school_phone"
            name="school_phone"
            type="number"
            placeholder="Teléfono"
            className="inp-base"
            {...register('phone', { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field="Phone" />
      </div>
      <div className="col-span-2">
        <label htmlFor="inp_school_address">Dirección de la I.E.</label>
        <div className="mt-1">
          <input
            id="inp_school_address"
            name="school_address"
            type="text"
            placeholder="Dirección de la I.E."
            className="inp-base"
            {...register('address', { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field="address" />
      </div>
      <div className="col-span-2">
        <label htmlFor="inp_school_department">Departamento</label>
        <div className="mt-1">
          <select
            id="inp_school_department"
            name="school_department"
            className="inp-base"
            {...register('department', { required: true })}
          >
            <option value="">Departamento</option>
            {departments.map((obj) => (
              <option key={obj.id} value={`${obj.id}|${obj.name}`}>
                {obj.name}
              </option>
            ))}
          </select>
        </div>
        <ErrorMessage errors={errors} field="department" />
      </div>
      <div className="col-span-2">
        <label htmlFor="inp_school_province">Provincia</label>
        <div className="mt-1">
          <select
            id="inp_school_province"
            name="school_province"
            className="inp-base"
            {...register('province', { required: true })}
          >
            <option value="">Provincia</option>
            {provinces.map((obj) => (
              <option key={obj.id} value={`${obj.id}|${obj.name}`}>
                {obj.name}
              </option>
            ))}
          </select>
        </div>
        <ErrorMessage errors={errors} field="province" />
      </div>
      <div className="col-span-2">
        <label htmlFor="inp_school_district">Distrito</label>
        <div className="mt-1">
          <select
            id="inp_school_district"
            name="school_district"
            className="inp-base"
            {...register('district', { required: true })}
          >
            <option value="">Distrito</option>
            {districts.map((obj) => (
              <option key={obj.id} value={`${obj.id}|${obj.name}`}>
                {obj.name}
              </option>
            ))}
          </select>
        </div>
        <ErrorMessage errors={errors} field="district" />
      </div>
    </div>
  );
}
