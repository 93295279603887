import { TrashIcon } from '@heroicons/react/outline';
import { useEffect } from 'react';

import ErrorMessage from '../../../components/ErrorMessage';
import SolidButton from '../../../components/SolidButton';

export default function TutorForm({
  index,
  amount,
  register,
  setValue,
  watch,
  errors,
  onRemove,
}) {
  const isDelegation = watch('isDelegation', false);
  const level1 = watch(`tutors.${index}.level1`, false);
  const level2 = watch(`tutors.${index}.level2`, false);
  const level3 = watch(`tutors.${index}.level3`, false);

  useEffect(() => {
    if (isDelegation) return;
    setValue(`tutors.${index}.level1`, false);
    setValue(`tutors.${index}.level2`, false);
    setValue(`tutors.${index}.level3`, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDelegation]);
  useEffect(() => {
    if (isDelegation) return;
    if (level1 && (level2 || level3)) {
      setValue(`tutors.${index}.level2`, false);
      setValue(`tutors.${index}.level3`, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level1]);
  useEffect(() => {
    if (isDelegation) return;
    if (level2 && (level1 || level3)) {
      setValue(`tutors.${index}.level1`, false);
      setValue(`tutors.${index}.level3`, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level2]);
  useEffect(() => {
    if (isDelegation) return;
    if (level3 && (level1 || level2)) {
      setValue(`tutors.${index}.level1`, false);
      setValue(`tutors.${index}.level2`, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level3]);

  return (
    <div className="mb-6 gap-4 space-y-2 rounded-xl border border-gray-200 p-6 sm:grid sm:grid-cols-4 sm:space-y-0 lg:grid-cols-6">
      <div className="col-span-2 lg:col-span-2">
        <label htmlFor={`inp_tutor_doc_type_${index}`}>Tipo de documento</label>
        <div className="mt-1">
          <select
            id={`inp_tutor_doc_type_${index}`}
            name={`tutor_doc_type_${index}`}
            placeholder="Tipo de documento"
            className="inp-base"
            {...register(`tutors.${index}.docType`, { required: true })}
          >
            <option value="">Elegir tipo de documento</option>
            <option>DNI</option>
            <option>Pasaporte</option>
            <option>Carnet de extranjería</option>
            <option>RUC</option>
          </select>
        </div>
        <ErrorMessage errors={errors} field={`tutors.${index}.docType`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_tutor_doc_number_${index}`}>
          Número de documento
        </label>
        <div className="mt-1">
          <input
            id={`inp_tutor_doc_number_${index}`}
            name={`tutor_doc_number_${index}`}
            type="text"
            placeholder="Número de documento"
            className="inp-base"
            {...register(`tutors.${index}.docNumber`, { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`tutors.${index}.docNumber`} />
      </div>
      <div className="col-span-2 flex items-start justify-end">
        {amount > 1 && (
          <SolidButton
            className="flex max-w-xs items-center justify-center px-6"
            widthFull={false}
            size="sm"
            color="red"
            onClick={() => onRemove()}
          >
            Borrar
            <TrashIcon className="ml-2 h-6 w-6 text-white" aria-hidden="true" />
          </SolidButton>
        )}
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_tutor_last_name_p_${index}`}>
          Apellido Paterno
        </label>
        <div className="mt-1">
          <input
            id={`inp_tutor_last_name_p_${index}`}
            name={`tutor_last_name_p_${index}`}
            type="text"
            placeholder="Apellido Paterno"
            className="inp-base"
            {...register(`tutors.${index}.lastNameP`, { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`tutors.${index}.lastNameP`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_tutor_last_name_m_${index}`}>
          Apellido Materno
        </label>
        <div className="mt-1">
          <input
            id={`inp_tutor_last_name_m_${index}`}
            name={`tutor_last_name_m_${index}`}
            type="text"
            placeholder="Apellido Materno"
            className="inp-base"
            {...register(`tutors.${index}.lastNameM`, { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`tutors.${index}.lastNameM`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_tutor_name_${index}`}>Nombres</label>
        <div className="mt-1">
          <input
            id={`inp_tutor_name_${index}`}
            name={`tutor_name_${index}`}
            type="text"
            placeholder="Nombres"
            className="inp-base"
            {...register(`tutors.${index}.name`, { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`tutors.${index}.name`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_tutor_email_${index}`}>Email</label>
        <div className="mt-1">
          <input
            id={`inp_tutor_email_${index}`}
            name={`tutor_email_${index}`}
            type="email"
            placeholder="Email"
            className="inp-base"
            {...register(`tutors.${index}.email`, { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`tutors.${index}.email`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_tutor_phone_${index}`}>Teléfono fijo</label>
        <div className="mt-1">
          <input
            id={`inp_tutor_phone_${index}`}
            name={`tutor_phone_${index}`}
            type="number"
            placeholder="Teléfono fijo"
            className="inp-base"
            {...register(`tutors.${index}.phone`)}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`tutors.${index}.phone`} />
      </div>
      <div className="col-span-2">
        <label htmlFor={`inp_tutor_cellphone_${index}`}>Celular</label>
        <div className="mt-1">
          <input
            id={`inp_tutor_cellphone_${index}`}
            name={`tutor_cellphone_${index}`}
            type="number"
            placeholder="Celular"
            className="inp-base"
            {...register(`tutors.${index}.cellphone`, { required: true })}
          ></input>
        </div>
        <ErrorMessage errors={errors} field={`tutors.${index}.cellphone`} />
      </div>
      <div className="col-span-2">
        {isDelegation ? (
          <>
            <label htmlFor={`inp_tutor_specialty_${index}`}>
              Especialidad de enseñanza
            </label>
            <div className="mt-1">
              <input
                id={`inp_tutor_specialty_${index}`}
                name={`tutor_specialty_${index}`}
                type="text"
                placeholder="Especialidad de enseñanza"
                className="inp-base"
                {...register(`tutors.${index}.specialty`, {
                  required: true,
                })}
              ></input>
            </div>
            <ErrorMessage errors={errors} field={`tutors.${index}.specialty`} />
          </>
        ) : (
          <>
            <label htmlFor={`inp_tutor_relation_${index}`}>Parentesco</label>
            <div className="mt-1">
              <select
                id={`inp_tutor_relation_${index}`}
                name={`tutor_relation_${index}`}
                placeholder="Parentesco"
                className="inp-base"
                {...register(`tutors.${index}.relation`, {
                  required: true,
                })}
              >
                <option value="">Elegir parentesco</option>
                <option>Padre</option>
                <option>Madre</option>
                <option>Padrastro/Madrastra</option>
                <option>Hermano(a)</option>
                <option>Tío(a)</option>
                <option>Tutor(a)</option>
                <option>Profesor(a)</option>
              </select>
            </div>
            <ErrorMessage errors={errors} field={`tutors.${index}.relation`} />
          </>
        )}
      </div>
      <div className="col-span-2 lg:col-span-4">
        <label>Nivel</label>
        <div className="mt-4 flex space-x-4">
          <div className="flex items-center">
            <input
              id={`inp_tutor_level_1_${index}`}
              name={`inp_tutor_level_1_${index}`}
              type="checkbox"
              className="h-6 w-6 rounded border-gray-300 text-primary focus:ring-primary"
              {...register(`tutors.${index}.level1`)}
            />
            <label
              htmlFor={`inp_tutor_level_1_${index}`}
              className="ml-2 block text-lg text-gray-900"
            >
              I
            </label>
          </div>
          <div className="flex items-center">
            <input
              id={`inp_tutor_level_2_${index}`}
              name={`inp_tutor_level_2_${index}`}
              type="checkbox"
              className="h-6 w-6 rounded border-gray-300 text-primary focus:ring-primary"
              {...register(`tutors.${index}.level2`)}
            />
            <label
              htmlFor={`inp_tutor_level_2_${index}`}
              className="ml-2 block text-lg text-gray-900"
            >
              II
            </label>
          </div>
          <div className="flex items-center">
            <input
              id={`inp_tutor_level_3_${index}`}
              name={`inp_tutor_level_3_${index}`}
              type="checkbox"
              className="h-6 w-6 rounded border-gray-300 text-primary focus:ring-primary"
              {...register(`tutors.${index}.level3`)}
            />
            <label
              htmlFor={`inp_tutor_level_3_${index}`}
              className="ml-2 block text-lg text-gray-900"
            >
              III
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
