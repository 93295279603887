import { Disclosure, Transition } from '@headlessui/react';
import SolidButton from '../../../../components/SolidButton';
import { formatDate } from '../../../../utils/functions';

import DateIcon from '../../../../resources/img/icons/date.png';
import TimeIcon from '../../../../resources/img/icons/time.png';
import DurationIcon from '../../../../resources/img/icons/duration.png';
import QuestionsIcon from '../../../../resources/img/icons/questions.png';
import GradeIcon from '../../../../resources/img/icons/grade.png';

export default function AssignmentItem({
  id,
  assignment,
  completed,
  graded,
  result,
  defaultOpen,
}) {
  return (
    <Disclosure
      as="div"
      className="mt-4 rounded-xl border border-gray-300"
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <div
            className={`${
              open ? 'rounded-t-xl bg-primary text-white' : 'rounded-xl'
            } flex flex-col items-center justify-between px-6 py-3 text-xl transition-all sm:gap-4 lg:flex-row`}
          >
            <div className="flex items-center gap-4">
              <div className="col-span-3 flex-1 text-center text-xl font-normal">
                {assignment.title}
              </div>
              <span
                className={`${
                  graded
                    ? 'border-green bg-green-dark'
                    : completed
                    ? 'border-orange bg-orange-dark'
                    : 'border-red-600 bg-red-500'
                } rounded-full border-2 px-6 py-1 text-lg font-normal text-white`}
              >
                {graded
                  ? 'Realizado'
                  : completed
                  ? 'Por calificar'
                  : 'Pendiente'}
              </span>
            </div>
            <div className="mt-2 flex items-center space-x-4 justify-self-end sm:mt-0">
              <p>{formatDate(assignment.assignment_date, 'dd/MM/yyyy')}</p>
              <Disclosure.Button className="cursor-pointer rounded-full p-1 hover:bg-gray-200 hover:text-light-blue">
                <svg
                  className="h-7 w-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {open ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 15l7-7 7 7"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  )}
                </svg>
              </Disclosure.Button>
            </div>
          </div>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="rounded-b-xl bg-white" static>
              <div className="flex flex-wrap items-center gap-8 px-6 py-6">
                <div className="flex flex-1 flex-wrap justify-around gap-8">
                  <div>
                    <img src={DateIcon} alt="Date icon" className="h-6" />
                    <p className="mt-1 font-normal">Fecha</p>
                    <p>
                      {formatDate(assignment.assignment_date, 'dd/MM/yyyy')}
                    </p>
                  </div>
                  <div>
                    <img src={TimeIcon} alt="Time icon" className="h-6" />
                    <p className="mt-1 font-normal">Hora</p>
                    <p>{formatDate(assignment.assignment_time, 'HH:mm a')}</p>
                  </div>
                  <div>
                    <img
                      src={DurationIcon}
                      alt="Duration icon"
                      className="h-6"
                    />
                    <p className="mt-1 font-normal">Duración</p>
                    <p>{assignment.duration} min.</p>
                  </div>
                  {/* <div>
                    <img src={KindIcon} alt="Kind icon" className="h-6" />
                    <p className="mt-1 font-normal">Tipo</p>
                    <p>{assignment.kind}</p>
                  </div> */}
                  <div>
                    <img
                      src={QuestionsIcon}
                      alt="Questions icon"
                      className="h-6"
                    />
                    <p className="mt-1 font-normal">Preguntas</p>
                    <p>
                      {assignment.sections.reduce(
                        (a, b) => a + b.questions.length,
                        0
                      )}
                    </p>
                  </div>
                  {graded && assignment.results_published && (
                    <div>
                      <img src={GradeIcon} alt="Grade icon" className="h-6" />
                      <p className="mt-1 font-normal">Puntaje</p>
                      <p>
                        {result.total}/
                        {assignment.sections.reduce(
                          (a, b) =>
                            a + b.questions.reduce((c, d) => c + d.value, 0),
                          0
                        )}
                      </p>
                    </div>
                  )}
                </div>

                <div>
                  {!completed && assignment.can_start ? (
                    <SolidButton
                      className="rounded-full px-8"
                      href={`/exam/${id}?type=assignment`}
                    >
                      EMPEZAR
                    </SolidButton>
                  ) : null}

                  {completed ? (
                    <SolidButton
                      className="rounded-full px-8"
                      href={`/my_courses/${assignment.course}/assignment_grades/${id}`}
                    >
                      VER RESULTADO
                    </SolidButton>
                  ) : null}
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
