import { useEffect, useState } from 'react';
import { LibraryIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';
import { validateFile } from '../../../utils/fileUtils';

import OutlineButton from '../../../components/OutlineButton';
import { showErrorToast } from '../../../components/Toast';
import SolidButton from '../../../components/SolidButton';
import StudentDetails from './StudentDetails';
import TutorDetails from './TutorDetails';

export default function Step4({
  getValues,
  setValue,
  setCurrentStep,
  finished,
}) {
  const [data, setData] = useState({});
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const values = getValues();
    setData(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let checkImage = async (event) => {
    let isValid = validateFile(event.target, 'img');
    if (!isValid) {
      setImagePreview(null);
      showErrorToast(
        'Formato de imagen no válido, debe ser un archivo .png, .jpg o .jpeg'
      );
      return;
    }
    setValue('voucherImage', event.target.files);
    await loadPreviewImage(event.target.files[0]);
  };

  const loadPreviewImage = async (data) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    await reader.readAsDataURL(data);
  };

  useEffect(() => {
    const image = getValues('voucherImage');
    if (image?.length > 0) loadPreviewImage(image[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mb-8 overflow-hidden border border-gray-200 bg-white sm:rounded-lg">
        <div className="flex items-center justify-between px-4 py-5 sm:px-6">
          <h3 className="flex text-lg font-medium leading-6 text-gray-900">
            <LibraryIcon className="mr-2 h-6 w-6" />
            Institución Educativa
          </h3>
          {!finished && (
            <div>
              <SolidButton size="sm" onClick={() => setCurrentStep(0)}>
                <PencilIcon className="h-6 w-6 text-white" />
              </SolidButton>
            </div>
          )}
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Código modular - Nombre
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data?.code} - {data?.name}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Tipo de I.E.
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{data?.kind}</dd>
            </div>
            <div className="flex items-center sm:col-span-1">
              <dd className="mt-1 text-lg text-gray-900">
                {data?.isDre && (
                  <span className="mr-4 inline-flex items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-sm font-medium text-indigo-800">
                    <svg
                      className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    DRE
                  </span>
                )}
                {data?.isUgel && (
                  <span className="mr-4 inline-flex items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-sm font-medium text-indigo-800">
                    <svg
                      className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    UGEL
                  </span>
                )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Correo electrónico
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{data?.email}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Teléfono</dt>
              <dd className="mt-1 text-sm text-gray-900">{data?.phone}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Dirección</dt>
              <dd className="mt-1 text-sm text-gray-900">{data?.address}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Departamento
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data?.department?.split('|')?.[1]}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Provincia</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data?.province?.split('|')?.[1]}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Distrito</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data?.district?.split('|')?.[1]}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {data?.tutors?.map((obj, index) => (
        <TutorDetails
          key={obj}
          tutor={obj}
          isDelegation={data?.isDelegation}
          index={index}
          finished={finished}
          onEditClick={() => setCurrentStep(1)}
        />
      ))}

      {data?.students?.map((obj, index) => (
        <StudentDetails
          key={obj}
          student={obj}
          index={index}
          finished={finished}
          onEditClick={() => setCurrentStep(2)}
        />
      ))}

      <div
        className={`${
          finished ? 'hidden' : 'flex'
        } mx-auto max-w-md items-center`}
      >
        <OutlineButton
          color="light-blue"
          htmlFor="inp_voucher_image"
          className="cursor-pointer"
        >
          <svg
            className="text-blue mr-2 h-7 w-7 group-hover:text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          {imagePreview
            ? 'CAMBIAR IMAGEN DE VOUCHER'
            : 'SUBIR IMAGEN DE VOUCHER'}
        </OutlineButton>
        <input
          id="inp_voucher_image"
          className="hidden"
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={(e) => {
            checkImage(e);
          }}
        />
      </div>
      {imagePreview ? (
        <img
          src={imagePreview}
          className="mx-auto mt-4 h-24 max-w-full rounded-lg border-2 border-light-blue sm:h-36 xl:max-w-lg"
          alt="DNI del alumno"
        ></img>
      ) : null}
    </>
  );
}
