import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import GeneralApi from '../../../utils/generalApi';
import { useAuth } from '../../../contexts/Auth';
import StorageService from '../../../utils/storage';
import { formatDate } from '../../../utils/functions';

import LoadingSpinner from '../../../components/LoadingSpinner';
import OutlineButton from '../../../components/OutlineButton';
import { showErrorToast, showSuccessToast } from '../../../components/Toast';
import SolidButton from '../../../components/SolidButton';
import Modal from '../../../components/Modal';

import VideosIcon from '../../../resources/img/icons/whiteboard.png';
import FilterIcon from '../../../resources/img/icons/filter.png';
import DateIcon from '../../../resources/img/icons/date.png';
import MathBackground from '../../../resources/img/backgrounds/counseling_math@2x.png';
import LettersBackground from '../../../resources/img/backgrounds/counseling_letters@2x.png';

const mathCourses = [
  'Aritmética',
  'Álgebra',
  'Geometría',
  'Trigonometría',
  'Física',
  'Química',
  'Biología',
  'Raz. Matemático',
  'Matemáticas',
];

export default function CounselingsList() {
  let { courseId, kind } = useParams();

  const history = useHistory();
  const auth = useAuth();

  const [topicFilter, setTopicFilter] = useState('-');
  const [dateKindFilter, setDateKindFilter] = useState('monthly');
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth());
  const [filterFrom, setFilterFrom] = useState(null);
  const [filterTo, setFilterTo] = useState(null);

  const [teachers, setTeachers] = useState([]);
  const [createName, setCreateName] = useState('');
  const [createTopic, setCreateTopic] = useState('-');
  const [createDate, setCreateDate] = useState(null);
  const [createSchedule, setCreateSchedule] = useState('-');
  const [createTeacher, setCreateTeacher] = useState('-');
  const [createLoading, setCreateLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [courseLoading, setCourseLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [counselings, setCounselings] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourseData();
    getCourseTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCounselings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateKindFilter, topicFilter, filterMonth, filterFrom, filterTo]);

  let getCounselings = async () => {
    if (dateKindFilter === 'range' && (!filterFrom || !filterTo)) return;
    setLoading(true);
    const result = await generalApi.post(`/classroom/counselings/list`, {
      student_id: StorageService.get('chosenStudent'),
      course_id: courseId,
      kind,
      topic: topicFilter,
      date_kind: dateKindFilter,
      filter_month: filterMonth,
      filter_from: filterFrom,
      filter_to: filterTo,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    setLoading(false);
    setCounselings(result.data);
  };

  let getCourseData = async () => {
    setCourseLoading(true);
    const result = await generalApi.post(`/classroom/course/details`, {
      course_id: courseId,
    });
    if (!result.success) {
      setCourseLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/home`);
      return;
    }
    setCourse(result.data.course);
    setCourseLoading(false);
  };

  let getCourseTeachers = async () => {
    const result = await generalApi.post(`/classroom/teachers/list`, {
      course_id: courseId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setTeachers(result.data);
  };

  const validateCreateCounseling = () => {
    if (createDate === null) {
      showErrorToast('Debes seleccionar una fecha');
      return false;
    }
    return true;
  };

  let createCounseling = async () => {
    if (!validateCreateCounseling()) return;
    setCreateLoading(true);
    const result = await generalApi.post(`/classroom/counselings`, {
      course_id: courseId,
      student_id: StorageService.get('chosenStudent'),
      teacher_id: createTeacher,
      name: createName,
      topic: createTopic,
      date: createDate,
      schedule: createSchedule,
    });
    if (!result.success) {
      setCreateLoading(false);
      showErrorToast(result.message);
      return;
    }
    setCreateLoading(false);
    setShowCreateModal(false);
    showSuccessToast('Asesoría programada');
    getCounselings();
  };

  return (
    <div>
      <Modal
        isOpen={showCreateModal}
        size="lg"
        onClose={() => {
          setShowCreateModal(false);
        }}
      >
        <div className="rounded-2xl bg-white pb-4 sm:pb-8">
          <div className="flex items-center gap-4 border-b-2 border-gray-100 px-12 py-8">
            <img src={DateIcon} alt="Date icon" className="h-8" />
            <h1 className="text-lg font-bold text-primary">
              PROGRAMAR ASESORÍA
            </h1>
          </div>

          <div className="px-12 pt-8">
            <div>
              <label htmlFor="inp_create_name" className="mb-1 font-bold">
                Nombre
              </label>
              <div className="flex-1">
                <input
                  id="inp_create_name"
                  name="name"
                  type="text"
                  placeholder="Nombre de la asesoría (Opcional)"
                  value={createName}
                  onChange={(e) => setCreateName(e.target.value)}
                  className="inp-base py-2"
                />
              </div>
            </div>

            <div className="mt-6">
              <label htmlFor="inp_create_topic" className="mb-1 font-bold">
                Elige tu curso
              </label>
              <select
                id="inp_create_topic"
                name="topic"
                value={createTopic}
                onChange={(e) => setCreateTopic(e.target.value)}
                className="inp-base w-full py-2 px-6"
              >
                <option value="-">Todos</option>
                <option>Aritmética</option>
                <option>Álgebra</option>
                <option>Geometría</option>
                <option>Trigonometría</option>
                <option>Física</option>
                <option>Química</option>
                <option>Biología</option>
                <option>Raz. Matemático</option>
                <option>Raz. Verbal</option>
                <option>Historia</option>
                <option>Psicología</option>
                <option>Cívica</option>
                <option>Economía</option>
                <option>Lenguaje</option>
                <option>Literatura</option>
                <option>Filosofía</option>
                <option>Geografía</option>
                <option>Inglés</option>
                <option>Historia Universal</option>
                <option>Historia del Perú</option>
                <option>Anatomía</option>
                <option>Hab. Verbal</option>
                <option>Hab. Lógico Matemático</option>
                <option>Estadística</option>
                <option>Números y Operaciones</option>
                <option>Vocabulario</option>
                <option>Lectura Comprensiva e Interpretativa</option>
                <option>Lectura Critica</option>
                <option>Ortografía y Puntuación</option>
                <option>Matemáticas</option>
                <option>Ciencias</option>
                <option>Humanidades</option>
              </select>
            </div>

            <div className="mt-6 grid gap-4 lg:grid-cols-2">
              <div className="">
                <label htmlFor="inp_create_date" className="mb-1 font-bold">
                  Selecciona una fecha
                </label>
                <div className="flex-1">
                  <input
                    id="inp_create_date"
                    name="date"
                    type="date"
                    value={createDate}
                    onChange={(e) => setCreateDate(e.target.value)}
                    className="inp-base py-2"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="inp_create_schedule" className="mb-1 font-bold">
                  Horario
                </label>
                <select
                  id="inp_create_schedule"
                  name="schedule"
                  value={createSchedule}
                  onChange={(e) => setCreateSchedule(e.target.value)}
                  className="inp-base w-full py-2 px-6"
                >
                  <option value="-">Todos</option>
                  <option>Mañana</option>
                  <option>Tarde</option>
                </select>
              </div>
            </div>

            <div className="mt-6">
              <label htmlFor="inp_create_teacher" className="mb-1 font-bold">
                Elige a tu profesor
              </label>
              <select
                id="inp_create_teacher"
                name="teacher"
                value={createTeacher}
                onChange={(e) => setCreateTeacher(e.target.value)}
                className="inp-base w-full py-2 px-6"
              >
                <option value="-">Todos</option>
                {teachers.map((teacher) => (
                  <option key={teacher._id} value={teacher._id}>
                    {teacher.name} {teacher.last_name_p || ''}{' '}
                    {teacher.last_name_m || ''}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-8 flex flex-col justify-start gap-4 lg:w-2/3 lg:flex-row">
              <SolidButton
                className="w-64"
                size="sm"
                isLoading={createLoading}
                onClick={() => {
                  createCounseling();
                }}
              >
                PROGRAMAR
              </SolidButton>
              <OutlineButton
                className="w-64"
                size="sm"
                disabled={createLoading}
                onClick={() => {
                  setShowCreateModal(false);
                }}
              >
                CANCELAR
              </OutlineButton>
            </div>
          </div>
        </div>
      </Modal>

      <div className="mx-4 my-8 md:mx-16 md:my-12">
        <div className="rounded-2xl bg-white">
          <div className="flex flex-col justify-between gap-4 border-b border-gray-300 p-6 lg:flex-row lg:items-center xl:px-12 xl:py-6">
            {courseLoading && (
              <div className="flex items-center gap-4">
                <div className="h-12 w-12 animate-pulse rounded-full bg-gray-300" />
                <div className="flex flex-col gap-2">
                  <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300" />
                  <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300" />
                </div>
              </div>
            )}
            {course?.school?.name && (
              <div className="flex items-center gap-3">
                <img
                  src={VideosIcon}
                  className="h-12 bg-no-repeat sm:h-12"
                  alt="Logo"
                />
                <p className="text-2xl text-primary-light">
                  {course?.name?.name || ''} - {course?.classroom?.name || ''} /
                  Clases
                </p>
                <p className="text-2xl font-bold text-primary-light">
                  / Asesoría
                </p>
              </div>
            )}

            <OutlineButton
              className="w-min font-light"
              href={`/my_courses/${courseId}/videos`}
            >
              Regresar
            </OutlineButton>
          </div>

          <div className="p-8 xl:px-12">
            <div className="mb-8 flex flex-col items-center gap-4 xl:flex-row xl:gap-8">
              <label
                htmlFor="inp_kind"
                className="flex items-center gap-2 text-xl font-bold text-gray-800"
              >
                <img className="h-4 w-4" src={FilterIcon} alt="Filtro" />
                FILTRAR
              </label>

              <div className="hidden h-16 w-0.5 bg-gray-300 xl:block" />

              <div className="flex flex-wrap gap-4 lg:flex-row lg:items-center">
                <div>
                  <label htmlFor="inp_topic" className="sr-only">
                    Tipo
                  </label>
                  <select
                    id="inp_topic"
                    name="topic"
                    value={topicFilter}
                    onChange={(e) => setTopicFilter(e.target.value)}
                    className="inp-base w-64 rounded-full px-6"
                  >
                    <option value="-">Todos los cursos</option>
                    <option>Aritmética</option>
                    <option>Álgebra</option>
                    <option>Geometría</option>
                    <option>Trigonometría</option>
                    <option>Física</option>
                    <option>Química</option>
                    <option>Biología</option>
                    <option>Raz. Matemático</option>
                    <option>Raz. Verbal</option>
                    <option>Historia</option>
                    <option>Psicología</option>
                    <option>Cívica</option>
                    <option>Economía</option>
                    <option>Lenguaje</option>
                    <option>Literatura</option>
                    <option>Filosofía</option>
                    <option>Geografía</option>
                    <option>Inglés</option>
                    <option>Historia Universal</option>
                    <option>Historia del Perú</option>
                    <option>Anatomía</option>
                    <option>Hab. Verbal</option>
                    <option>Hab. Lógico Matemático</option>
                    <option>Estadística</option>
                    <option>Números y Operaciones</option>
                    <option>Vocabulario</option>
                    <option>Lectura Comprensiva e Interpretativa</option>
                    <option>Lectura Critica</option>
                    <option>Ortografía y Puntuación</option>
                    <option>Matemáticas</option>
                    <option>Ciencias</option>
                    <option>Humanidades</option>
                  </select>
                </div>
                {/* 
                <div className="flex max-w-sm items-center">
                  <label htmlFor="inp_grades_topic_filter" className="sr-only">
                    Tipo de Filtro:
                  </label>
                  <div className="w-36 flex-1">
                    <select
                      id="inp_grades_kind_filter"
                      name="grades_kind_filter"
                      value={dateKindFilter}
                      onChange={(e) => setDateKindFilter(e.target.value)}
                      className="inp-base w-full rounded-full"
                    >
                      <option value="monthly">Mes</option>
                      <option value="range">Rango</option>
                    </select>
                  </div>
                </div>
                <Transition
                  show={dateKindFilter === 'monthly'}
                  className="flex max-w-sm flex-1 items-center"
                >
                  <label htmlFor="inp_grades_filter_month" className="sr-only">
                    Mes:
                  </label>
                  <div className="flex-1">
                    <select
                      id="inp_grades_filter_month"
                      name="grades_filter_month"
                      value={filterMonth}
                      onChange={(e) => setFilterMonth(e.target.value)}
                      className="inp-base w-full rounded-full"
                    >
                      <option value="0">Enero</option>
                      <option value="1">Febrero</option>
                      <option value="2">Marzo</option>
                      <option value="3">Abril</option>
                      <option value="4">Mayo</option>
                      <option value="5">Junio</option>
                      <option value="6">Julio</option>
                      <option value="7">Agosto</option>
                      <option value="8">Setiembre</option>
                      <option value="9">Octubre</option>
                      <option value="10">Noviembre</option>
                      <option value="11">Diciembre</option>
                    </select>
                  </div>
                </Transition>
                <Transition
                  show={dateKindFilter === 'range'}
                  className="items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-8"
                >
                  <div className="flex items-center">
                    <label htmlFor="inp_grades_filter_from" className="sr-only">
                      De:
                    </label>
                    <div className="ml-4 flex-1">
                      <input
                        id="inp_grades_filter_from"
                        name="grades_filter_from"
                        type="date"
                        placeholder="De"
                        value={filterFrom}
                        onChange={(e) => setFilterFrom(e.target.value)}
                        className="inp-base"
                      ></input>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label
                      htmlFor="inp_grades_filter_to"
                      className="text-lg text-gray-600"
                    >
                      Hasta:
                    </label>
                    <div className="ml-4 flex-1">
                      <input
                        id="inp_grades_filter_to"
                        name="grades_filter_to"
                        type="date"
                        placeholder="Hasta"
                        value={filterTo}
                        onChange={(e) => setFilterTo(e.target.value)}
                        className="inp-base"
                      ></input>
                    </div>
                  </div>
                </Transition> */}
              </div>
            </div>

            {!loading && (
              <button
                className="group mb-8 flex w-full justify-center gap-4 rounded-2xl border border-dashed border-primary p-8"
                onClick={() => {
                  setShowCreateModal(true);
                }}
              >
                <img src={DateIcon} alt="Date icon" className="h-6" />
                <p className="text-lg text-gray-800 group-hover:underline">
                  Programar asesoría
                </p>
              </button>
            )}

            {loading ? (
              <LoadingSpinner content="Buscando asesorías" color="primary" />
            ) : counselings.length === 0 ? (
              <div className="mt-8 text-center">
                <p className="text-lg">No se encontraron asesorías</p>
                {topicFilter !== '-' ||
                dateKindFilter !== 'monthly' ||
                filterMonth !== new Date().getMonth() ||
                filterFrom ||
                filterTo ? (
                  <SolidButton
                    className="mx-auto mt-8 max-w-xs"
                    onClick={() => {
                      setTopicFilter('-');
                      setDateKindFilter('monthly');
                      setFilterMonth(new Date().getMonth());
                      setFilterFrom(null);
                      setFilterTo(null);
                    }}
                  >
                    Limpiar filtros
                  </SolidButton>
                ) : null}
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-6 px-0 lg:grid-cols-2 xl:gap-12">
                {counselings.map((counseling, index) =>
                  counseling.url ? (
                    <a
                      key={counseling._id}
                      className="relative items-center overflow-hidden rounded-3xl transition-all duration-300 hover:shadow-2xl"
                      href={counseling.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          mathCourses.includes(counseling.topic)
                            ? MathBackground
                            : LettersBackground
                        }
                        className="h-64 w-full bg-no-repeat object-cover"
                        alt="Background"
                      />

                      <div className="absolute bottom-0 flex w-full items-center justify-between bg-gradient-to-b from-transparent to-gray-800 p-6 pt-8">
                        <div>
                          <p className="text-xl text-yellow-light">
                            {course?.name?.name || ''} -{' '}
                            {course?.classroom?.name || ''}
                            {counseling.schedule && counseling.schedule !== '-'
                              ? ` ${counseling.schedule}`
                              : ''}
                          </p>
                          <p className="text-2xl font-bold text-white">
                            {counseling.name ||
                              `Asesoría de ${counseling.topic}`}
                          </p>
                          <p className="mt-2 text-xl text-white">
                            {counseling.date &&
                              formatDate(counseling.date, 'dd/MM/yyyy')}
                          </p>
                        </div>
                      </div>
                    </a>
                  ) : (
                    <div
                      key={counseling._id}
                      className="relative items-center overflow-hidden rounded-3xl transition-all duration-300 hover:shadow-2xl"
                    >
                      <img
                        src={
                          mathCourses.includes(counseling.topic)
                            ? MathBackground
                            : LettersBackground
                        }
                        className="h-64 w-full bg-no-repeat object-cover"
                        alt="Background"
                      />

                      <div className="absolute bottom-0 flex w-full items-center justify-between bg-gradient-to-b from-transparent to-gray-800 p-6 pt-8">
                        <div>
                          <p className="text-xl text-yellow-light">
                            {course?.name?.name || ''} -{' '}
                            {course?.classroom?.name || ''}
                            {counseling.schedule && counseling.schedule !== '-'
                              ? ` ${counseling.schedule}`
                              : ''}
                          </p>
                          <p className="text-2xl font-bold text-white">
                            {counseling.name ||
                              `Asesoría de ${counseling.topic}`}
                          </p>
                          <p className="mt-2 text-xl text-white">
                            {counseling.date &&
                              formatDate(counseling.date, 'dd/MM/yyyy')}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
