import { Link } from 'react-router-dom';

export default function QuestionItem({
  courseId,
  questionId,
  userName,
  date,
  time,
  imageUrl,
  title,
  content,
  answers,
  lastAnswerUserName,
  lastAnswerDate,
  lastAnswerTime,
  contentIsHtml = false,
}) {
  return (
    <div className="space-y-4 py-4 pl-4 pr-8 sm:flex sm:space-y-0 sm:space-x-6">
      <div className="w-64">
        {imageUrl ? (
          <Link
            to={`/forum/${courseId}/question/${questionId}/details`}
            className="cursor-pointer text-lg text-light-blue underline hover:text-light-blue-light"
          >
            <img src={imageUrl} alt={content} className="mx-auto w-full" />
          </Link>
        ) : (
          <svg
            className="mx-auto mt-4 h-12 w-12"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            />
          </svg>
        )}
      </div>
      <div className="flex-1 space-y-2 py-2">
        <p>
          <span className="font-bold">{userName} - </span> {date}
          <span className="ml-4">{time}</span>
        </p>
        {!contentIsHtml || title ? (
          <div className="space-y-2 text-center sm:flex sm:space-y-0 sm:space-x-6">
            <p className="text-xl text-primary-light">
              {title
                ? title.length > 30
                  ? title.substr(0, 30)
                  : title
                : content.length > 30
                ? content.substr(0, 30)
                : content}
            </p>
          </div>
        ) : null}
        {answers > 0 ? (
          <p>
            Última intervención de
            <span className="ml-1 font-bold">{lastAnswerUserName} - </span>{' '}
            {lastAnswerDate}
            <span className="ml-4">{lastAnswerTime}</span>
          </p>
        ) : null}
      </div>
      <div className="flex items-center justify-center space-x-4 sm:flex-col sm:space-x-0 sm:space-y-2 sm:px-8">
        <p className="rounded-md border-2 border-primary bg-gray-200 px-3 pt-1 text-base text-gray-700">
          {answers !== 1 ? `${answers} respuestas` : '1 respuesta'}
        </p>
        <Link
          to={`/forum/${courseId}/question/${questionId}/details`}
          className="cursor-pointer text-lg text-light-blue underline hover:text-light-blue-light"
        >
          Ver más
        </Link>
      </div>
    </div>
  );
}
