import { Transition } from '@headlessui/react';

export default function Modal({ isOpen, size = 'sm', onClose, children }) {
  return (
    <Transition show={isOpen} unmount={false}>
      <div
        className="fixed inset-0 z-20 overflow-y-auto"
        onKeyUp={(e) => {
          if (e.key === 'Escape') onClose();
        }}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          {/*
            Background overlay, show/hide based on modal state.
          */}
          <Transition.Child
            unmount={false}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            onClick={onClose}
            className="fixed inset-0 transition-opacity"
            aria-hidden={isOpen}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          {/*
            Modal panel, show/hide based on modal state.
          */}
          <Transition.Child
            unmount={false}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className={`${
              size === 'sm' ? 'sm:max-w-xl' : 'sm:max-w-3xl'
            } inline-block transform overflow-hidden text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:align-middle`}
            role="dialog"
            aria-hidden={isOpen}
            aria-labelledby="modal-headline"
          >
            {onClose && (
              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  onClick={onClose}
                  className="focus:outline-none focus:ring-primary-500 rounded-full bg-gray-300 p-1 text-gray-800 transition-colors hover:bg-gray-400 hover:text-white focus:ring-2 focus:ring-offset-2"
                >
                  <span className="sr-only">Cerrar</span>
                  {/* Heroicon name: x */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            )}
            {children}
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
}
