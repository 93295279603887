import OutlineButton from './OutlineButton';
import SolidButton from './SolidButton';

export default function ConfirmModalContent({
  onClose,
  onConfirm,
  title,
  content,
  buttonLoading,
}) {
  return (
    <div className="bg-white">
      <div className="px-4 pt-5 pb-4 sm:flex sm:items-start sm:p-6 sm:pb-4">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-12 sm:w-12">
          {/* Heroicon name: exclamation */}
          <svg
            className="h-8 w-8 text-orange-dark"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-3 sm:text-left">
          <h3
            className="text-xl font-medium leading-6 text-gray-900"
            id="modal-headline"
          >
            {title || 'Confirmar acción'}
          </h3>
          <div className="mt-2">
            <p className="text-base text-gray-500">
              {content || '¿Está seguro que desea realizar esta acción?'}
            </p>
          </div>
        </div>
      </div>
      <div className="justify-end bg-gray-50 px-4 py-3 sm:flex sm:space-x-4 sm:px-6">
        <div className="flex max-w-xs items-center">
          <OutlineButton
            size="sm"
            className="px-4"
            color="gray"
            disabled={buttonLoading}
            onClick={onClose}
          >
            CANCELAR
          </OutlineButton>
        </div>
        <div className="flex max-w-xs items-center">
          <SolidButton
            size="sm"
            className="px-4"
            onClick={onConfirm}
            isLoading={buttonLoading}
            color="primary"
          >
            CONFIRMAR
          </SolidButton>
        </div>
      </div>
    </div>
  );
}
