import { formatSchedule } from '../../../utils/functions';
import { constants } from '../../../utils/constants';

import SolidButton from '../../../components/SolidButton';

export default function MyCourseCard({
  course,
  turn,
  status,
  dueIn,
  isBlocked,
}) {
  const {
    name,
    classroom,
    schedule,
    classroom_section,
    classroom_number,
    campus,
    school,
  } = course;
  return (
    <div
      className={`border-${
        constants.SCHOOLS_FORMAT[school.name].borderColor
      } flex flex-col justify-between rounded-2xl border bg-white px-6 py-6 text-center sm:px-8`}
    >
      <div>
        <img
          src={constants.SCHOOLS_FORMAT[school.name].logo}
          className="mx-auto mb-6 h-12 bg-no-repeat sm:h-16"
          alt="Logo"
        />
        <p className="text-2xl font-bold text-primary">{name.name}</p>
        <div className="mt-6 text-left">
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Sede:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {campus?.name || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Ciclo:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {classroom?.name || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Turno:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {turn || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Horario:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {formatSchedule(schedule.name)}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Modalidad:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {classroom_section?.name || '-'}
            </dd>
          </div>
          <div className="grid grid-cols-4 gap-4 py-0.5">
            <dt className="col-span-2 text-lg font-bold text-gray-700">
              Aula:
            </dt>
            <dd className="col-span-2 mt-1 text-lg text-gray-900 sm:mt-0">
              {classroom_number?.name || '-'}
            </dd>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <SolidButton
          disabled={isBlocked}
          href={
            status === 'debt'
              ? `/my_courses/${course._id}/pending_payment`
              : `/my_courses/${course._id}/home`
          }
          color={constants.SCHOOLS_FORMAT[school.name].color}
        >
          INGRESAR
        </SolidButton>
      </div>

      {isBlocked ? (
        <p className={`mt-6 font-medium text-red-500`}>Acceso bloqueado</p>
      ) : status === 'paid' ? null : (
        <p
          className={`mt-6 font-medium ${
            status === 'debt' ? 'text-red-500' : 'text-light-blue'
          }`}
        >
          {status === 'debt'
            ? 'Pago vencido'
            : `Pago por vencer en ${dueIn} ${dueIn > 1 ? 'días' : 'día'}`}
        </p>
      )}
    </div>
  );
}
