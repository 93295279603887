import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';

import GeneralApi from '../../../utils/generalApi';
import StorageService from '../../../utils/storage';
import { constants } from '../../../utils/constants';
import { showErrorToast } from '../../../components/Toast';

import MyCourseCard from './MyCourseCard';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { differenceInCalendarDays } from 'date-fns';
import SolidButton from '../../../components/SolidButton';

export default function MyCourses() {
  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);

  const [kindFilter, setKindFilter] = useState('current');
  const [courses, setCourses] = useState([]);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindFilter]);

  let getCourses = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/student/courses`, {
      student_id: StorageService.get('chosenStudent'),
      kind: kindFilter,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return;
    }
    setCourses(result.data);
    setLoading(false);
  };

  return (
    <div className="mx-4 my-8 rounded-2xl bg-white md:mx-16 md:my-12">
      <div>
        <div className="p-2 sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="focus:outline-none block w-full rounded-xl border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-light focus:ring-primary sm:text-sm"
            value={kindFilter}
            onChange={(e) => setKindFilter(e.target.value)}
          >
            <option value="current">PROGRAMAS ACTUALES</option>
            <option value="finished">HISTORIAL DE PROGRAMAS</option>
          </select>
        </div>
        <div className="hidden border-b border-gray-300 px-16 pt-8 sm:block">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <span
              className={`
                  ${
                    kindFilter === 'current'
                      ? 'border-primary-light text-primary-light'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  }
                  cursor-pointer whitespace-nowrap border-b-4 py-4 px-1 text-xl 2xl:text-2xl font-bold`}
              onClick={() => setKindFilter('current')}
            >
              PROGRAMAS ACTUALES
            </span>
            <span
              className={`
                  ${
                    kindFilter === 'finished'
                      ? 'border-primary-light text-primary-light'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  }
                  cursor-pointer whitespace-nowrap border-b-4 py-4 px-1 text-xl 2xl:text-2xl font-bold`}
              onClick={() => setKindFilter('finished')}
            >
              HISTORIAL DE PROGRAMAS
            </span>
          </nav>
        </div>
      </div>

      <div className="px-4 py-10 md:px-12">
        {loading ? (
          <LoadingSpinner content="Buscando cursos" color="primary" />
        ) : courses.length === 0 ? (
          <div className="text-center">
            <p className="text-lg">No se encontraron cursos</p>
            {kindFilter === 'current' ? (
              <SolidButton className="mx-auto mt-8 max-w-xs" href="/courses">
                BUSCAR CURSOS
              </SolidButton>
            ) : null}
          </div>
        ) : (
          <div className="grid gap-12 sm:grid-cols-2 2xl:grid-cols-3">
            {courses.map((obj) => {
              let unpaidIndex = obj.monthly_payments.findIndex(
                (obj) => !obj.paid && obj.amount > (obj.discount || 0)
              );
              let dueIn =
                unpaidIndex > -1 &&
                obj.monthly_payments[unpaidIndex].expires_at != null
                  ? differenceInCalendarDays(
                      new Date(obj.monthly_payments[unpaidIndex].expires_at),
                      new Date()
                    )
                  : 0;
              return (
                <MyCourseCard
                  key={obj.course._id}
                  course={obj.course}
                  turn={
                    obj.course.classroom_turn
                      ? constants.TURNS[obj.course.classroom_turn]
                      : obj.course.classroom_number.name
                  }
                  status={
                    unpaidIndex === -1 || obj.ignore_debt
                      ? 'paid'
                      : dueIn < 1
                      ? 'debt'
                      : dueIn < 10
                      ? 'due'
                      : 'paid'
                  }
                  dueIn={dueIn}
                  isBlocked={obj.is_blocked}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
