import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import { constants } from '../../../utils/constants';
import GeneralApi from '../../../utils/generalApi';
import { useAuth } from '../../../contexts/Auth';

import OutlineButton from '../../../components/OutlineButton';
import { showErrorToast } from '../../../components/Toast';

import VideosIcon from '../../../resources/img/icons/whiteboard.png';
import ExamsIcon from '../../../resources/img/icons/exam-results.png';
import AssignmentsIcon from '../../../resources/img/icons/list.png';
import ChatIcon from '../../../resources/img/icons/chat.png';
import ResourcesIcon from '../../../resources/img/icons/folder.png';
import ExamResultsIcon from '../../../resources/img/icons/data-analytics.png';
import HomeworksIcon from '../../../resources/img/icons/evidence@2x.png';
import CalendarIcon from '../../../resources/img/icons/calendar.png';
import AttendanceIcon from '../../../resources/img/icons/task.png';

const options = [
  {
    name: 'Clases',
    href: '/videos',
    icon: VideosIcon,
  },
  {
    name: 'Exámenes',
    href: '/exams',
    icon: ExamsIcon,
  },
  {
    name: 'Tareas',
    href: '/assignments',
    icon: AssignmentsIcon,
  },
  {
    name: 'Foro',
    href: '/forum',
    icon: ChatIcon,
  },
  {
    name: 'Recursos',
    href: '/resources',
    icon: ResourcesIcon,
  },
  {
    name: 'Notas',
    href: '/grades',
    icon: ExamResultsIcon,
  },
  {
    name: 'Evidencias',
    href: '/homeworks',
    icon: HomeworksIcon,
  },
  {
    name: 'Calendario',
    href: '/calendar',
    icon: CalendarIcon,
  },
  {
    name: 'Asistencia',
    href: '/attendance',
    icon: AttendanceIcon,
  },
];

export default function CourseHome() {
  let { courseId } = useParams();

  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getCourseData = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/course/details`, {
      course_id: courseId,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/home`);
      return;
    }
    setCourse(result.data.course);
    setLoading(false);
  };

  return (
    <div className="mx-4 my-8 md:mx-16 md:my-12">
      <div className="rounded-2xl bg-white">
        <div className="flex flex-col justify-between gap-4 border-b border-gray-300 p-6 lg:flex-row lg:items-center xl:px-12 xl:py-6">
          {loading && (
            <div className="flex items-center gap-4">
              <div className="h-12 w-12 animate-pulse rounded-full bg-gray-300" />
              <div className="flex flex-col gap-2">
                <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300" />
                <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300" />
              </div>
            </div>
          )}
          {course?.school?.name && (
            <div className="flex items-center gap-3">
              <img
                src={constants.SCHOOLS_FORMAT[course.school.name].logoSmall}
                className="h-12 bg-no-repeat object-contain sm:h-12"
                alt="Logo"
              />
              <p className="text-2xl font-bold text-primary-light">
                {course?.name?.name || ''} - {course?.classroom?.name || ''}
              </p>
            </div>
          )}

          <OutlineButton className="w-min font-light" href="/my_courses">
            Regresar
          </OutlineButton>
        </div>
        <div className="grid grid-cols-1 gap-4 p-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-16 xl:px-12 xl:py-12">
          {options.map((option, index) => (
            <Link
              key={index}
              className="group flex cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl border-2 border-gray-400 px-4 py-8 transition-all hover:bg-light-blue sm:py-16"
              to={
                option.href.includes('forum')
                  ? `/forum/${courseId}`
                  : `/my_courses/${courseId}${option.href}`
              }
            >
              <img
                src={option.icon}
                className="img-white h-16 bg-no-repeat transition-all sm:h-24"
                alt="Logo"
              />
              <p className="text-2xl font-bold uppercase text-primary-light group-hover:text-white">
                {option.name}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
