import { addMinutes, format } from 'date-fns';
import { es } from 'date-fns/esm/locale';
import { constants } from './constants';

export function formatDate(
  dateString,
  formatString = `do 'de' MMMM 'del' yyyy`,
  isUTC = true
) {
  if (!dateString) return '-';
  const date = new Date(dateString || null);
  return format(
    isUTC ? addMinutes(date, date.getTimezoneOffset()) : date,
    formatString,
    {
      locale: es,
    }
  );
}

export function formatSchedule(schedule) {
  // "Lun-Mar-Mie-Jue-Vie-Sab-Dom"
  const scheduleDays = schedule.split('-');
  const ranges = [];
  for (let i = 0; i < scheduleDays.length; i++) {
    const scheduleDay = scheduleDays[i];
    // ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom']
    const dayIndex = constants.SCHEDULE_DAYS.indexOf(scheduleDay);
    let daysInRange = 0;

    if (i === scheduleDays.length - 1) {
      ranges.push({
        start: scheduleDay,
      });
    }
    // Calculate ranges
    for (let j = i + 1; j < scheduleDays.length; j++) {
      const elm = scheduleDays[j];
      if (dayIndex + j - i > 6) break;
      const rangeIndex = ranges.findIndex((obj) => obj.start === scheduleDay);
      if (elm === constants.SCHEDULE_DAYS[dayIndex + j - i]) {
        daysInRange++;
        if (rangeIndex === -1) {
          ranges.push({
            start: scheduleDay,
            end: elm,
          });
        } else {
          ranges[rangeIndex].end = elm;
        }
      } else {
        if (rangeIndex === -1) {
          ranges.push({
            start: scheduleDay,
          });
        }
      }
    }
    i += daysInRange;
  }
  return ranges
    .map((obj) => (obj.end ? `${obj.start} a ${obj.end}` : obj.start))
    .join(', ');
}

const switch60To00 = (value) => {
  return value === '60' ? '00' : value;
};

export const secondsToTime = (secs) => {
  let hours = Math.floor(secs / (60 * 60))
    .toString()
    .padStart(2, '0');

  let divisor_for_minutes = Math.round(secs % (60 * 60));
  let minutes = Math.floor(divisor_for_minutes / 60)
    .toString()
    .padStart(2, '0');

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds).toString().padStart(2, '0');

  return `${hours}:${switch60To00(minutes)}:${switch60To00(seconds)}`;
};

export function scrollToTop() {
  document.querySelector('#scroller').scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export const buildCourseName = (course) =>
  `${course?.school?.name || '-'} / ${course?.classroom?.name || '-'} / ${
    course.classroom_turn
      ? constants.TURNS[course.classroom_turn]
      : course.classroom_number?.name || '-'
  } / ${formatSchedule(course?.schedule?.name) || '-'} / ${
    course?.classroom_number?.name || '-'
  }`;

export const submitLog = (api, data) => {
  api.post(`/logs/add`, data);
};
