import { Link, useLocation } from 'react-router-dom';

export default function SidebarItem({
  path,
  icon,
  selectedIcon,
  children,
  ...rest
}) {
  const location = useLocation();

  return (
    <Link
      to={path}
      className={`${
        location.pathname.includes(path)
          ? 'bg-yellow-light font-medium text-primary-light'
          : 'font-light text-white hover:bg-sidebar-light hover:text-white'
      } group flex items-center py-4 px-8 text-xl`}
      {...rest}
    >
      <img
        className="mr-4"
        src={location.pathname.includes(path) ? selectedIcon : icon}
        alt=""
      />
      {children}
    </Link>
  );
}
