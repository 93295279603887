import { createRef, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridWeek from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useAuth } from '../../../contexts/Auth';

import StorageService from '../../../utils/storage';
import GeneralApi from '../../../utils/generalApi';

import OutlineButton from '../../../components/OutlineButton';
import { showErrorToast } from '../../../components/Toast';

import AttendanceIcon from '../../../resources/img/icons/task.png';
import { isAfter, isBefore, isSameDay, setHours, setMinutes } from 'date-fns';
import { constants } from '../../../utils/constants';

export default function CourseCalendar() {
  let { courseId } = useParams();
  const calendarRef = createRef();
  const miniCalendarRef = createRef();

  const history = useHistory();
  const auth = useAuth();

  const [courseLoading, setCourseLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [events, setEvents] = useState([]);
  const [kindFilters, setKindFilters] = useState({
    seminar: true,
    recovery: true,
    chat: true,
    cultural_visit: true,
    exam: true,
    workshop: true,
  });

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindFilters]);

  let getEvents = async () => {
    const selectedKindFilters = Object.keys(kindFilters).filter(
      (key) => kindFilters[key]
    );
    const result = await generalApi.post(`/classroom/events/list`, {
      student_id: StorageService.get('chosenStudent'),
      course_id: courseId,
      kinds:
        selectedKindFilters.length < Object.keys(kindFilters).length
          ? selectedKindFilters
          : '-',
    });
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setEvents(result.data);
  };

  let getCourseData = async () => {
    setCourseLoading(true);
    const result = await generalApi.post(`/classroom/course/details`, {
      course_id: courseId,
    });
    if (!result.success) {
      setCourseLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/home`);
      return;
    }
    setCourse(result.data.course);
    setCourseLoading(false);
  };

  return (
    <div className="my-8 md:mx-16 md:my-12">
      <div className="bg-white md:rounded-2xl">
        <div className="flex flex-col justify-between gap-4 border-b border-gray-300 p-6 lg:flex-row lg:items-center xl:px-12 xl:py-6">
          {courseLoading && (
            <div className="flex items-center gap-4">
              <div className="h-12 w-12 animate-pulse rounded-full bg-gray-300" />
              <div className="flex flex-col gap-2">
                <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300" />
                <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300" />
              </div>
            </div>
          )}

          {course?.school?.name && (
            <div className="flex flex-col items-center gap-3 lg:flex-row">
              <div className="flex items-center gap-4">
                <img
                  src={AttendanceIcon}
                  className="h-12 bg-no-repeat sm:h-12"
                  alt="Logo"
                />
                <p className="text-2xl text-primary-light">
                  {course?.name?.name || ''} - {course?.classroom?.name || ''}
                </p>
              </div>
              <p className="text-2xl font-bold text-primary-light">
                / Asistencia
              </p>
            </div>
          )}

          <OutlineButton
            className="w-min font-light"
            href={`/my_courses/${courseId}/home`}
          >
            Regresar
          </OutlineButton>
        </div>

        <div className="mt-4 space-y-6 rounded-b-2xl sm:flex sm:space-y-0 sm:space-x-6">
          <div className="max-w-xs rounded-b-2xl pl-8">
            <div className="mini-calendar">
              <FullCalendar
                ref={miniCalendarRef}
                locale={esLocale}
                plugins={[dayGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'title',
                  center: '',
                  right: 'prev,next',
                }}
                titleFormat={{
                  month: 'long',
                  year: 'numeric',
                }}
                height="auto"
                datesSet={(info) => {
                  if (
                    calendarRef.current?.calendar?.currentData
                      ?.currentViewType !== 'timeGridWeek'
                  ) {
                    calendarRef.current
                      ?.getApi()
                      .gotoDate(info.view?.currentStart);
                  }
                }}
                dateClick={(info) => {
                  calendarRef.current?.getApi().gotoDate(info.date);
                }}
              />
            </div>

            <p className="mt-8 text-lg font-bold">ACTIVIDADES</p>

            <div className="flex flex-row flex-wrap gap-x-6 gap-y-2 xl:flex-col xl:gap-2">
              {Object.keys(constants.EVENT_KINDS).map((key) => (
                <div className="flex items-center gap-2" key={key}>
                  <input
                    id={key}
                    name={key}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-white focus:ring-primary"
                    style={{
                      backgroundColor: constants.EVENT_KINDS[key].color,
                    }}
                    checked={kindFilters[key]}
                    onChange={(e) =>
                      setKindFilters({
                        ...kindFilters,
                        [key]: e.target.checked,
                      })
                    }
                  />
                  <label
                    htmlFor={key}
                    className="block text-base text-gray-900"
                  >
                    {constants.EVENT_KINDS[key].name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 xl:p-8">
            <FullCalendar
              ref={calendarRef}
              locale={esLocale}
              plugins={[timeGridWeek, dayGridPlugin]}
              headerToolbar={{
                left: 'prev,title,next',
                center: '',
                right: 'dayGridMonth,timeGridWeek',
              }}
              titleFormat={{
                month: 'long',
              }}
              displayEventTime
              eventBorderColor="transparent"
              events={events.map((obj) => {
                const date = new Date(obj.date);
                const startDate = setHours(date, new Date(obj.time).getHours());
                return {
                  title: obj.name,
                  start: setMinutes(startDate, new Date(obj.time).getMinutes()),
                  end: setMinutes(
                    startDate,
                    new Date(obj.time).getMinutes() + obj.duration
                  ),
                  backgroundColor: constants.EVENT_KINDS[obj.kind].color,
                };
              })}
              eventContent={(event) => (
                <div
                  className="h-full w-full rounded-md px-1 text-white"
                  style={{ backgroundColor: event.backgroundColor }}
                >
                  {event.event.title}
                </div>
              )}
              datesSet={(info) => {
                if (info.view?.type === 'timeGridWeek') {
                  if (
                    isBefore(
                      info.view?.currentStart,
                      miniCalendarRef.current?.calendar?.currentData?.viewApi
                        ?.currentStart
                    ) ||
                    isSameDay(
                      info.view?.currentStart,
                      miniCalendarRef.current?.calendar?.currentData?.viewApi
                        ?.currentStart
                    )
                  ) {
                    miniCalendarRef.current
                      ?.getApi()
                      .gotoDate(info.view?.currentStart);
                  } else if (
                    isAfter(
                      info.view?.currentStart,
                      miniCalendarRef.current?.calendar?.currentData?.viewApi
                        ?.currentEnd
                    ) ||
                    isSameDay(
                      info.view?.currentStart,
                      miniCalendarRef.current?.calendar?.currentData?.viewApi
                        ?.currentEnd
                    )
                  ) {
                    miniCalendarRef.current
                      ?.getApi()
                      .gotoDate(info.view?.currentEnd);
                  }
                } else if (
                  isBefore(
                    info.view?.currentStart,
                    miniCalendarRef.current?.calendar?.currentData?.viewApi
                      ?.currentStart
                  ) ||
                  isAfter(
                    info.view?.currentStart,
                    miniCalendarRef.current?.calendar?.currentData?.viewApi
                      ?.currentEnd
                  ) ||
                  isSameDay(
                    info.view?.currentStart,
                    miniCalendarRef.current?.calendar?.currentData?.viewApi
                      ?.currentEnd
                  )
                ) {
                  miniCalendarRef.current
                    ?.getApi()
                    .gotoDate(info.view?.currentStart);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
