import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import GeneralApi from '../../../utils/generalApi';
import { useAuth } from '../../../contexts/Auth';
import { constants } from '../../../utils/constants';
import StorageService from '../../../utils/storage';
import { formatDate } from '../../../utils/functions';

import LoadingSpinner from '../../../components/LoadingSpinner';
import OutlineButton from '../../../components/OutlineButton';
import { showErrorToast } from '../../../components/Toast';
import Modal from '../../../components/Modal';

import VideosIcon from '../../../resources/img/icons/whiteboard.png';
import FilterIcon from '../../../resources/img/icons/filter.png';
import PlayIcon from '../../../resources/img/icons/play.png';
import { Transition } from '@headlessui/react';
import SolidButton from '../../../components/SolidButton';

export default function VideosList() {
  let { courseId, kind } = useParams();
  const videoRef = useRef(null);

  const history = useHistory();
  const auth = useAuth();

  const [topicFilter, setTopicFilter] = useState('-');
  const [dateKindFilter, setDateKindFilter] = useState('monthly');
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth());
  const [filterFrom, setFilterFrom] = useState(null);
  const [filterTo, setFilterTo] = useState(null);

  const [loading, setLoading] = useState(true);
  const [courseLoading, setCourseLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateKindFilter, topicFilter, filterMonth, filterFrom, filterTo]);

  let getVideos = async () => {
    if (dateKindFilter === 'range' && (!filterFrom || !filterTo)) return;
    setLoading(true);
    const result = await generalApi.post(`/classroom/videos/list`, {
      student_id: StorageService.get('chosenStudent'),
      course_id: courseId,
      kind,
      topic: topicFilter,
      date_kind: dateKindFilter,
      filter_month: filterMonth,
      filter_from: filterFrom,
      filter_to: filterTo,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    setLoading(false);
    setVideos(result.data);
  };

  let getCourseData = async () => {
    setCourseLoading(true);
    const result = await generalApi.post(`/classroom/course/details`, {
      course_id: courseId,
    });
    if (!result.success) {
      setCourseLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/home`);
      return;
    }
    setCourse(result.data.course);
    setCourseLoading(false);
  };

  return (
    <div>
      <Modal
        isOpen={currentVideo !== null}
        size="lg"
        onClose={() => {
          setCurrentVideo(null);
        }}
      >
        {currentVideo && (
          <div className="bg-white pt-5">
            <p className="mb-5 ml-4 text-xl">{currentVideo.name}</p>
            <video
              ref={videoRef}
              className="w-full"
              src={currentVideo.url}
              controls
              autoPlay
            />
          </div>
        )}
      </Modal>

      <div className="mx-4 my-8 md:mx-16 md:my-12">
        <div className="rounded-2xl bg-white">
          <div className="flex flex-col justify-between gap-4 border-b border-gray-300 p-6 lg:flex-row lg:items-center xl:px-12 xl:py-6">
            {courseLoading && (
              <div className="flex items-center gap-4">
                <div className="h-12 w-12 animate-pulse rounded-full bg-gray-300" />
                <div className="flex flex-col gap-2">
                  <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300" />
                  <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300" />
                </div>
              </div>
            )}
            {course?.school?.name && (
              <div className="flex items-center gap-3">
                <img
                  src={VideosIcon}
                  className="h-12 bg-no-repeat sm:h-12"
                  alt="Logo"
                />
                <p className="text-2xl text-primary-light">
                  {course?.name?.name || ''} - {course?.classroom?.name || ''} /
                  Clases
                </p>
                <p className="text-2xl font-bold text-primary-light">
                  / {constants.VIDEO_KINDS[kind]}
                </p>
              </div>
            )}

            <OutlineButton
              className="w-min font-light"
              href={`/my_courses/${courseId}/videos`}
            >
              Regresar
            </OutlineButton>
          </div>

          <div className="p-8 xl:px-12">
            <div className="mb-8 flex flex-col items-center gap-4 xl:flex-row xl:gap-8">
              <label
                htmlFor="inp_kind"
                className="flex items-center gap-2 text-xl font-bold text-gray-800"
              >
                <img className="h-4 w-4" src={FilterIcon} alt="Filtro" />
                FILTRAR
              </label>

              <div className="hidden h-16 w-0.5 bg-gray-300 xl:block" />

              <div className="flex flex-wrap gap-4 lg:flex-row lg:items-center">
                <div>
                  <label htmlFor="inp_topic" className="sr-only">
                    Tipo
                  </label>
                  <select
                    id="inp_topic"
                    name="topic"
                    value={topicFilter}
                    onChange={(e) => setTopicFilter(e.target.value)}
                    className="inp-base w-64 rounded-full px-6"
                  >
                    <option value="-">Todos los cursos</option>
                    <option>Aritmética</option>
                    <option>Álgebra</option>
                    <option>Geometría</option>
                    <option>Trigonometría</option>
                    <option>Física</option>
                    <option>Química</option>
                    <option>Biología</option>
                    <option>Raz. Matemático</option>
                    <option>Raz. Verbal</option>
                    <option>Historia</option>
                    <option>Psicología</option>
                    <option>Cívica</option>
                    <option>Economía</option>
                    <option>Lenguaje</option>
                    <option>Literatura</option>
                    <option>Filosofía</option>
                    <option>Geografía</option>
                    <option>Inglés</option>
                    <option>Historia Universal</option>
                    <option>Historia del Perú</option>
                    <option>Anatomía</option>
                    <option>Hab. Verbal</option>
                    <option>Hab. Lógico Matemático</option>
                    <option>Estadística</option>
                    <option>Números y Operaciones</option>
                    <option>Vocabulario</option>
                    <option>Lectura Comprensiva e Interpretativa</option>
                    <option>Lectura Critica</option>
                    <option>Ortografía y Puntuación</option>
                    <option>Matemáticas</option>
                    <option>Ciencias</option>
                    <option>Humanidades</option>
                  </select>
                </div>

                <div className="flex max-w-sm items-center">
                  <label htmlFor="inp_grades_topic_filter" className="sr-only">
                    Tipo de Filtro:
                  </label>
                  <div className="w-36 flex-1">
                    <select
                      id="inp_grades_kind_filter"
                      name="grades_kind_filter"
                      value={dateKindFilter}
                      onChange={(e) => setDateKindFilter(e.target.value)}
                      className="inp-base w-full rounded-full"
                    >
                      <option value="monthly">Mes</option>
                      <option value="range">Rango</option>
                    </select>
                  </div>
                </div>
                <Transition
                  show={dateKindFilter === 'monthly'}
                  className="flex max-w-sm flex-1 items-center"
                >
                  <label htmlFor="inp_grades_filter_month" className="sr-only">
                    Mes:
                  </label>
                  <div className="flex-1">
                    <select
                      id="inp_grades_filter_month"
                      name="grades_filter_month"
                      value={filterMonth}
                      onChange={(e) => setFilterMonth(e.target.value)}
                      className="inp-base w-full rounded-full"
                    >
                      <option value="0">Enero</option>
                      <option value="1">Febrero</option>
                      <option value="2">Marzo</option>
                      <option value="3">Abril</option>
                      <option value="4">Mayo</option>
                      <option value="5">Junio</option>
                      <option value="6">Julio</option>
                      <option value="7">Agosto</option>
                      <option value="8">Setiembre</option>
                      <option value="9">Octubre</option>
                      <option value="10">Noviembre</option>
                      <option value="11">Diciembre</option>
                    </select>
                  </div>
                </Transition>
                <Transition
                  show={dateKindFilter === 'range'}
                  className="items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-8"
                >
                  <div className="flex items-center">
                    <label htmlFor="inp_grades_filter_from" className="sr-only">
                      De:
                    </label>
                    <div className="ml-4 flex-1">
                      <input
                        id="inp_grades_filter_from"
                        name="grades_filter_from"
                        type="date"
                        placeholder="De"
                        value={filterFrom}
                        onChange={(e) => setFilterFrom(e.target.value)}
                        className="inp-base"
                      ></input>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label
                      htmlFor="inp_grades_filter_to"
                      className="text-lg text-gray-600"
                    >
                      Hasta:
                    </label>
                    <div className="ml-4 flex-1">
                      <input
                        id="inp_grades_filter_to"
                        name="grades_filter_to"
                        type="date"
                        placeholder="Hasta"
                        value={filterTo}
                        onChange={(e) => setFilterTo(e.target.value)}
                        className="inp-base"
                      ></input>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>

            {loading ? (
              <LoadingSpinner content="Buscando videos" color="primary" />
            ) : videos.length === 0 ? (
              <div className="mt-8 text-center">
                <p className="text-lg">No se encontraron videos</p>
                {topicFilter !== '-' ||
                dateKindFilter !== 'monthly' ||
                filterMonth !== new Date().getMonth() ||
                filterFrom ||
                filterTo ? (
                  <SolidButton
                    className="mx-auto mt-8 max-w-xs"
                    onClick={() => {
                      setTopicFilter('-');
                      setDateKindFilter('monthly');
                      setFilterMonth(new Date().getMonth());
                      setFilterFrom(null);
                      setFilterTo(null);
                    }}
                  >
                    Limpiar filtros
                  </SolidButton>
                ) : null}
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-6 px-0 lg:grid-cols-2 xl:gap-12 xl:px-6">
                {videos.map((video, index) => (
                  <div
                    key={video.url}
                    className="relative cursor-pointer items-center overflow-hidden rounded-3xl transition-all duration-300 hover:shadow-2xl"
                    onClick={() => {
                      setCurrentVideo(video);
                      if (videoRef.current) {
                        videoRef.current.load();
                      }
                    }}
                  >
                    <video muted src={video.url} />
                    <div className="absolute bottom-0 flex w-full items-center justify-between bg-gradient-to-b from-transparent to-gray-800 p-6 pt-8">
                      <div>
                        <p className="text-xl text-yellow-light">
                          {video.topic || ''}
                        </p>
                        <p className="text-2xl font-bold text-white">
                          {video.name || ''}
                        </p>
                        <p className="mt-2 text-xl text-white">
                          {video.published_at &&
                            formatDate(video.published_at, 'dd/MM/yyyy')}
                        </p>
                      </div>
                      <img
                        src={PlayIcon}
                        className="h-12 bg-no-repeat"
                        alt="Play video icon"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
