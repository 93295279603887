import { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router-dom';

import { constants } from '../../../../utils/constants';
import { checkFileType, validateFile } from '../../../../utils/fileUtils';
import GeneralApi from '../../../../utils/generalApi';
import { useAuth } from '../../../../contexts/Auth';

import OutlineButton from '../../../../components/OutlineButton';
import { showErrorToast } from '../../../../components/Toast';

export default function OpenQuestionItem({
  questionId,
  content,
  imageUrl,
  chosenAnswer,
  onAnswerChange,
  chosenImage,
  onImageChange,
}) {
  const history = useHistory();
  const auth = useAuth();
  const editorRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const generalApi = new GeneralApi(auth, history);

  const checkImage = async (event) => {
    const isValid = validateFile(event.target, 'all');
    if (!isValid) {
      onImageChange(null);
      setImagePreview(null);
      showErrorToast('Formato de archivo no válido');
      return;
    }
    const savedImage = await saveFile(event.target.files[0]);
    onImageChange(savedImage);
    if (checkFileType(event.target.value) === 'IMAGEN')
      setImagePreview(savedImage);
  };

  const saveFile = async (file) => {
    const fileData = new FormData();
    fileData.append('image', file);

    setLoading(true);
    const result = await generalApi.post(`/saveFile`, fileData);
    setLoading(false);
    if (!result.success) {
      showErrorToast(result.message);
      return false;
    }
    return result.data?.url;
  };

  useEffect(() => {
    if (editorRef.current) editorRef.current.setContent(chosenAnswer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, editorRef.current]);

  useEffect(() => {
    if (checkFileType(chosenImage) === 'IMAGEN') setImagePreview(chosenImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenImage]);

  return (
    <div className="flex flex-col sm:pb-9">
      <p
        className="mt-2 mb-8 rounded-xl bg-gray-200 px-4 py-2 text-xl"
        dangerouslySetInnerHTML={{ __html: content || '' }}
      />
      {imageUrl ? (
        <img
          src={imageUrl}
          className="mx-auto mt-2 mb-8 rounded-xl"
          alt="Imagen de la pregunta"
        />
      ) : null}
      {/* <textarea
        className="inp-base mt-8"
        rows="4"
        placeholder="Ingrese su respuesta"
        value={chosenAnswer}
        onChange={(e) => onAnswerChange(e.target.value)}
      ></textarea> */}

      <div className="mb-6 flex flex-col items-end space-y-2">
        <div className="flex items-center gap-3">
          <OutlineButton
            isLoading={loading}
            color="light-blue"
            htmlFor="image_file"
            className="cursor-pointer"
          >
            <svg
              className="text-blue mr-2 h-7 w-7 group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            {chosenImage ? 'CAMBIAR ARCHIVO' : 'SUBIR ARCHIVO'}
          </OutlineButton>
          <input
            id="image_file"
            className="hidden"
            type="file"
            name="image"
            onChange={checkImage}
          />
          {chosenImage && (
            <OutlineButton
              disabled={loading}
              color="red"
              className="flex-1 cursor-pointer"
              onClick={() => {
                onImageChange(null);
              }}
            >
              BORRAR
            </OutlineButton>
          )}
        </div>
        {checkFileType(chosenImage) === 'IMAGEN' ? (
          <img
            src={imagePreview}
            className="h-24 max-w-full rounded-lg border-2 border-light-blue sm:h-36 xl:max-w-lg"
            alt="Imagen de la pregunta"
          ></img>
        ) : checkFileType(chosenImage) === 'PDF' ? (
          <Document
            file={chosenImage}
            className="border-primary-200 max-h-40 overflow-hidden rounded-xl border-2 p-1"
            renderMode="svg"
          >
            <Page pageNumber={1} width={150} className="mx-auto" />
          </Document>
        ) : null}
      </div>

      <Editor
        apiKey="8com3pmw9v05b20vk3t04cgt6hxoi372hxj01v155q05grdk"
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={constants.TINY_INIT}
        onKeyUp={(e) => {
          onAnswerChange(editorRef.current.getContent());
        }}
      />
    </div>
  );
}
