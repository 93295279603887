import logoEdtlab from '../resources/img/logos/logo_edtlab-1.png';

import facebook from '../resources/img/icons/facebook.png';
import tiktok from '../resources/img/icons/tiktok.png';
import youtube from '../resources/img/icons/youtube.png';
import whatsapp from '../resources/img/icons/whatsapp.png';
import instagram from '../resources/img/icons/instagram.png';
import { Link } from 'react-router-dom';

export default function Footer({ isSidebarOpen = false }) {
  return (
    <footer className="body-font">
      <div className="flex w-full flex-col flex-wrap px-8 py-8 md:flex-row md:flex-nowrap md:items-center lg:items-start">
        <div
          className={`${
            isSidebarOpen ? 'px-8 xl:w-80' : 'px-16 xl:w-96'
          } mt-10 w-full flex-shrink-0 text-center md:mx-0 md:mt-0 md:w-72 md:text-left`}
        >
          <Link
            to="/"
            className="title-font mx-auto flex w-auto items-center justify-center font-medium"
          >
            <img className="block h-20" src={logoEdtlab} alt="Workflow"></img>
          </Link>
          <p className="mt-4 text-right text-sm font-light">
            Copyright © 2022 Edtlab Todos los derechos reservados.
          </p>
        </div>

        <div className="order-first flex flex-grow flex-wrap text-center md:text-left">
          <div
            className={`${
              isSidebarOpen ? 'px-6' : 'px-12'
            } w-full md:w-1/2 lg:w-1/3`}
          >
            <h2 className="title-font mb-3 text-xl font-bold">Síguenos</h2>
            <nav className="flex justify-center space-x-2 md:justify-start">
              <a
                href="https://www.facebook.com/academia.logical"
                target="_blank"
                rel="noreferrer"
                className="flex h-8 w-8 items-center justify-center rounded-full border border-primary transition-colors hover:bg-white"
              >
                <img className="block h-4" src={facebook} alt="Workflow"></img>
              </a>
              <a
                href="https://www.instagram.com/academia_logical/?hl=es"
                target="_blank"
                rel="noreferrer"
                className="flex h-8 w-8 items-center justify-center rounded-full border border-primary transition-colors hover:bg-white"
              >
                <img className="block h-4" src={instagram} alt="Workflow"></img>
              </a>
              <a
                href="https://www.youtube.com/channel/UCiW4Z5DX2zuZlfyJ2Mn4Liw"
                target="_blank"
                rel="noreferrer"
                className="flex h-8 w-8 items-center justify-center rounded-full border border-primary transition-colors hover:bg-white"
              >
                <img className="block h-3" src={youtube} alt="Workflow"></img>
              </a>
              <a
                href="https://www.tiktok.com/@logicaloficial?"
                target="_blank"
                rel="noreferrer"
                className="flex h-8 w-8 items-center justify-center rounded-full border border-primary transition-colors hover:bg-white"
              >
                <img className="block h-4" src={tiktok} alt="Workflow"></img>
              </a>
              <a
                href="http://bit.ly/InformesLogicalWhatsApp"
                target="_blank"
                rel="noreferrer"
                className="flex h-8 w-8 items-center justify-center rounded-full border border-primary transition-colors hover:bg-white"
              >
                <img className="block h-4" src={whatsapp} alt="Workflow"></img>
              </a>
            </nav>
          </div>
          <div
            className={`${
              isSidebarOpen ? 'px-6' : 'px-12'
            } mt-8 w-full md:mt-0 md:w-1/2 lg:w-1/3`}
          >
            <h2 className="title-font mb-3 text-xl font-bold">Información</h2>
            <nav className="mb-10 list-none">
              <li>
                <a
                  href="mailto:informes@logical.edu.pe"
                  className="inline text-center text-lg font-light hover:text-gray-200 hover:underline md:text-left"
                >
                  <span>informes@logical.edu.pe</span>
                </a>
              </li>
              <li>
                <a
                  href="tel:017229010"
                  className="inline text-center text-lg font-light hover:text-gray-200 hover:underline md:text-left"
                >
                  <span className="pt-0.5">017229010</span>
                </a>
              </li>
              <li>
                <a
                  href="tel:992761337"
                  className="inline text-center text-lg font-light hover:text-gray-200 hover:underline md:text-left"
                >
                  <span className="pt-0.5">992761337</span>
                </a>
              </li>
            </nav>
          </div>
          <div
            className={`${
              isSidebarOpen ? 'px-6' : 'px-12'
            } w-full md:w-1/2 lg:w-1/3`}
          >
            <h2 className="title-font mb-3 text-xl font-bold">Legales</h2>
            <nav className="mb-10 list-none">
              <li>
                <a
                  href="/"
                  className="text-lg font-light hover:text-gray-200 hover:underline"
                >
                  Términos y condiciones
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-lg font-light hover:text-gray-200 hover:underline"
                >
                  Política de privacidad
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-lg font-light hover:text-gray-200 hover:underline"
                >
                  Política de cookies
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-lg font-light hover:text-gray-200 hover:underline"
                >
                  Notificaciones de Copyright
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}
