import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import GeneralApi from '../../../utils/generalApi';
import { useAuth } from '../../../contexts/Auth';

import logo from '../../../resources/img/logo.png';

import OutlineButton from '../../../components/OutlineButton';
import { showErrorToast } from '../../../components/Toast';
import SolidButton from '../../../components/SolidButton';
import Footer from '../../../components/Footer';
import Stepper from './Stepper';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { BadgeCheckIcon } from '@heroicons/react/solid';

export default function InscriptionPage() {
  const history = useHistory();
  const auth = useAuth();

  const generalApi = new GeneralApi(auth, history);

  const {
    watch,
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
  });

  const {
    fields: tutors,
    append: appendTutor,
    remove: removeTutor,
  } = useFieldArray({ control, name: 'tutors' });

  const {
    fields: students,
    append: appendStudent,
    remove: removeStudent,
  } = useFieldArray({ control, name: 'students' });

  const isDelegation = watch('isDelegation', false);
  const watchDepartment = watch('department', '');
  const watchProvince = watch('province', '');

  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const steps = [
    {
      id: '1',
      name: 'Institución educativa',
      content: (
        <Step1
          register={register}
          errors={errors}
          departments={departments}
          provinces={provinces}
          districts={districts}
        />
      ),
      validation: async () => {
        return await trigger([
          'name',
          'code',
          'kind',
          'email',
          'phone',
          'address',
          'department',
          'province',
          'district',
        ]);
      },
    },
    {
      id: '2',
      name: isDelegation ? 'Docente Asesor' : 'Apoderado',
      content: (
        <Step2
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
          errors={errors}
          tutors={tutors}
          append={appendTutor}
          remove={removeTutor}
        />
      ),
      validation: async () => {
        const result = await trigger(
          tutors.reduce(
            (a, b, index) => [
              ...a,
              `tutors.${index}.docType`,
              `tutors.${index}.docNumber`,
              `tutors.${index}.lastNameP`,
              `tutors.${index}.lastNameM`,
              `tutors.${index}.name`,
              `tutors.${index}.email`,
              `tutors.${index}.cellphone`,
              `tutors.${index}.specialty`,
              `tutors.${index}.relation`,
              `tutors.${index}.level1`,
              `tutors.${index}.level2`,
              `tutors.${index}.level3`,
            ],
            []
          )
        );
        return result;
      },
    },
    {
      id: '3',
      name: isDelegation ? 'Estudiantes' : 'Estudiante',
      content: (
        <Step3
          register={register}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          students={students}
          append={appendStudent}
          remove={removeStudent}
        />
      ),
      validation: async () => {
        const result = await trigger(
          students.reduce(
            (a, b, index) => [
              ...a,
              `students.${index}.docType`,
              `students.${index}.docNumber`,
              `students.${index}.lastNameP`,
              `students.${index}.lastNameM`,
              `students.${index}.name`,
              `students.${index}.genre`,
              `students.${index}.age`,
              `students.${index}.level`,
              `students.${index}.grade`,
            ],
            []
          )
        );
        const missingImageIndex = students.findIndex(
          (obj, index) => !getValues(`students.${index}.image`)
        );
        if (missingImageIndex > -1) {
          showErrorToast(
            isDelegation
              ? 'La imagen de DNI de cada alumno es obligatoria'
              : 'La imagen de DNI del alumno es obligatoria'
          );
        }
        return result && missingImageIndex === -1;
      },
    },
    {
      id: '4',
      name: 'Confirmar datos',
      content: (
        <Step4
          getValues={getValues}
          setValue={setValue}
          setCurrentStep={setCurrentStep}
          finished={finished}
        />
      ),
      validation: () => {
        if (
          !getValues('voucherImage') ||
          getValues('voucherImage')?.length <= 0
        ) {
          showErrorToast('La imagen de Voucher es obligatoria');
        }
        return getValues('voucherImage')?.length > 0;
      },
    },
  ];

  const onSubmit = async (data) => {
    if (!steps[currentStep].validation()) return;
    setLoading(true);
    const result = await generalApi.post(`/classroom/inscription`, {
      ...data,
      contestName: 'OLIMPIADA MATEMÁTICA LOGICAL',
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    sendImages(data, result.data.inscription);
  };

  const sendImages = async (data, inscriptionId) => {
    let imagesData = new FormData();

    imagesData.append('inscriptionId', inscriptionId);
    if (data.voucherImage?.length > 0)
      imagesData.append('voucherImage', data.voucherImage[0]);
    for (let i = 0; i < data.students.length; i++) {
      if (data.students[i].image?.length > 0)
        imagesData.append(`studentImage${i}`, data.students[i].image[0]);
    }

    const result = await generalApi.post(
      `/classroom/inscription/files`,
      imagesData
    );
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    generatePdf(inscriptionId);
  };

  const generatePdf = async (inscriptionId) => {
    const result = await generalApi.get(
      `/classroom/inscription/pdf/${inscriptionId}`
    );
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    const link = document.createElement('a');
    link.href = result.data.pdf;
    link.setAttribute('download', 'Inscripcion.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
    setFinished(true);
  };

  useEffect(() => {
    getDeparments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (getValues('department') && getValues('department') !== '-')
      getProvinces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDepartment]);
  useEffect(() => {
    if (
      getValues('department') &&
      getValues('province') &&
      getValues('department') !== '-' &&
      getValues('province') !== '-'
    )
      getDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProvince]);

  let getDeparments = async () => {
    const result = await generalApi.get(`/departments`);
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setDepartments(result.data);
  };
  let getProvinces = async () => {
    const result = await generalApi.get(
      `/provinces/${getValues('department').split(['|'])[0]}`
    );
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setProvinces(result.data);
  };
  let getDistricts = async () => {
    const result = await generalApi.get(
      `/districts/${getValues('department').split(['|'])[0]}/${
        getValues('province').split(['|'])[0]
      }`
    );
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setDistricts(result.data);
  };

  return (
    <div className="font-gotham">
      <header className="bg-white shadow-sm lg:static lg:overflow-y-visible">
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
          <div className="relative flex justify-between">
            <div className="flex md:inset-y-0 md:left-0 lg:static xl:col-span-2">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/" className="flex items-center">
                  <img
                    className="block h-12 w-auto"
                    src={logo}
                    alt="Workflow"
                  ></img>
                  <span className="ml-4 text-3xl text-primary">EDT.LAB</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="body-font bg-gray-body text-gray-700">
        <div className="container mx-auto items-center px-5 py-12">
          <p className="mb-8 text-center text-4xl">
            Inscripción Olimpiada Matemática Logical
          </p>
          <Stepper steps={steps} currentStep={currentStep} />
          <form className="mx-auto mt-4 rounded-md bg-white p-8 shadow lg:px-12 xl:px-24">
            {steps[currentStep].content}
            {finished ? (
              <div className="mx-auto mt-12 mb-4 max-w-4xl rounded-lg border-2 border-primary bg-gray-100 px-8 py-4">
                <p className="flex items-center justify-center text-center text-3xl">
                  <BadgeCheckIcon className="mr-2 h-8 w-8 text-primary" />{' '}
                  Inscripción Completada
                </p>
                <p className="mt-6">
                  Se descargará su Ficha de Inscripción automáticamente.
                </p>
                <p>
                  Cuando se valide su inscripción enviaremos los datos de acceso
                  a su correo para la realización del concurso.
                </p>
              </div>
            ) : (
              <div className="mt-10 flex justify-end">
                <div className="flex w-full items-center space-x-2 sm:w-1/2 sm:space-x-4 lg:w-1/3">
                  {
                    <OutlineButton
                      size="sm"
                      disabled={currentStep === 0}
                      onClick={() => {
                        if (currentStep === 0) return;
                        setCurrentStep(currentStep - 1);
                      }}
                    >
                      Regresar
                    </OutlineButton>
                  }
                  <SolidButton
                    size="sm"
                    type="button"
                    isLoading={loading}
                    onClick={
                      currentStep >= steps.length - 1
                        ? handleSubmit(onSubmit)
                        : async () => {
                            if (!(await steps[currentStep].validation()))
                              return;
                            setCurrentStep(currentStep + 1);
                          }
                    }
                  >
                    {currentStep >= steps.length - 1
                      ? 'Finalizar'
                      : 'Siguiente'}
                  </SolidButton>
                </div>
              </div>
            )}
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
}
