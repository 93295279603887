import axiosInstance from './axiosConfig';
import { constants } from './constants';

export default class PaginationApi {
  constructor(apiPath, amountPerPage, auth, history) {
    this.apiPath = `${constants.API_URL}${apiPath}`;
    this.amountPerPage = amountPerPage;
    this.auth = auth;
    this.history = history;
  }

  async getContent(currentPage, filter) {
    try {
      const result = await axiosInstance.post(this.apiPath, {
        pagination: {
          page: currentPage,
          amount: this.amountPerPage,
        },
        filter,
      });
      return {
        success: true,
        message: 'Información obtenida',
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      if (error.response.status === 440) {
        this.auth.signout(() => this.history.push('/'));
        return {
          success: false,
          message: 'Sesión finalizada',
        };
      }
      return {
        success: false,
        message:
          error.response != null
            ? error.response.data.message
            : 'Ocurrió un error',
      };
    }
  }
}
