import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { useAuth } from '../../../contexts/Auth';

import { constants } from '../../../utils/constants';
import StorageService from '../../../utils/storage';
import GeneralApi from '../../../utils/generalApi';

import OutlineButton from '../../../components/OutlineButton';
import { showErrorToast } from '../../../components/Toast';
import Calendar from '../../../components/Calendar';

import AttendanceIcon from '../../../resources/img/icons/task.png';
import AbsenceIcon from '../../../resources/img/icons/absence.png';
import LateIcon from '../../../resources/img/icons/late.png';

const AttendanceChart = ({ data }) => (
  <ResponsiveRadialBar
    data={data}
    endAngle={360}
    innerRadius={0.5}
    borderWidth={0}
    borderColor="transparent"
    colors={['rgb(103,22,144)', 'rgba(219, 219, 219)']}
    valueFormat=">-.2%"
    radialAxisStart={null}
    circularAxisOuter={null}
  />
);

export default function AttendanceReport() {
  let { courseId } = useParams();

  const history = useHistory();
  const auth = useAuth();

  const [courseLoading, setCourseLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [attendance, setAttendance] = useState([]);

  const [monthEvents, setMonthEvents] = useState([]);

  const generalApi = new GeneralApi(auth, history);

  const calendarComponent = useMemo(
    () => (
      <Calendar
        events={attendance
          .filter((obj) => [0, 1, 2].includes(obj.status))
          .map((obj) => {
            return {
              date: obj.date,
              status: obj.status,
              color: constants.ATTENDANCE_COLORS[obj.status],
              textColor: 'white',
            };
          })}
        onMonthEventsChange={(events) => {
          console.log(events);
          setMonthEvents([...events]);
        }}
      />
    ),
    [attendance, setMonthEvents]
  );

  const { total, attended, absent, late } = useMemo(() => {
    let total = 0,
      attended = 0,
      absent = 0,
      late = 0;
    monthEvents.forEach((obj) => {
      if ([0, 1, 2].includes(obj.status)) total++;
      if (obj.status === 0) absent++;
      else if (obj.status === 1) attended++;
      else if (obj.status === 2) late++;
    });
    return { total, attended, absent, late };
  }, [monthEvents]);

  useEffect(() => {
    getCourseData();
    getAttendance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getAttendance = async () => {
    const result = await generalApi.post(`/classroom/student/attendance`, {
      student_id: StorageService.get('chosenStudent'),
      course_id: courseId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setAttendance(result.data);
  };

  let getCourseData = async () => {
    setCourseLoading(true);
    const result = await generalApi.post(`/classroom/course/details`, {
      course_id: courseId,
    });
    if (!result.success) {
      setCourseLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/home`);
      return;
    }
    setCourse(result.data.course);
    setCourseLoading(false);
  };

  return (
    <div className="mx-4 my-8 md:mx-16 md:my-12">
      <div className="rounded-2xl bg-white">
        <div className="flex flex-col justify-between gap-4 border-b border-gray-300 p-6 lg:flex-row lg:items-center xl:px-12 xl:py-6">
          {courseLoading && (
            <div className="flex items-center gap-4">
              <div className="h-12 w-12 animate-pulse rounded-full bg-gray-300" />
              <div className="flex flex-col gap-2">
                <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300" />
                <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300" />
              </div>
            </div>
          )}

          {course?.school?.name && (
            <div className="flex flex-col items-center gap-3 lg:flex-row">
              <div className="flex items-center gap-4">
                <img
                  src={AttendanceIcon}
                  className="h-12 bg-no-repeat sm:h-12"
                  alt="Logo"
                />
                <p className="text-2xl text-primary-light">
                  {course?.name?.name || ''} - {course?.classroom?.name || ''}
                </p>
              </div>
              <p className="text-2xl font-bold text-primary-light">
                / Asistencia
              </p>
            </div>
          )}

          <OutlineButton
            className="w-min font-light"
            href={`/my_courses/${courseId}/home`}
          >
            Regresar
          </OutlineButton>
        </div>

        <div className="mt-4 space-y-6 rounded-b-2xl sm:flex sm:space-y-0 sm:space-x-6">
          <div className="flex max-w-xs flex-col items-center rounded-b-2xl px-12 lg:pt-16">
            <div className="flex items-center justify-center gap-4 py-4">
              {/* <div className="relative h-12 w-12 rounded-full bg-gray-300">
                <div className="relative ml-2 mt-2 h-8 w-8 rounded-full bg-white" />
              </div> */}

              <div className="h-20 w-20">
                <AttendanceChart
                  data={[
                    {
                      id: '',
                      data: [
                        {
                          x: 'Asistencias',
                          y: attended / total,
                        },
                        {
                          x: 'Faltas',
                          y: absent / total,
                        },
                      ],
                    },
                  ]}
                />
              </div>
              {monthEvents ? (
                <div>
                  <p className="text-4xl font-normal">
                    {monthEvents.length > 0
                      ? ((attended / total) * 100).toFixed()
                      : 0}
                    %
                  </p>
                  <p className="font-normal">de asistencia</p>
                </div>
              ) : null}
            </div>
            <div className="flex w-min flex-col gap-6 p-6 text-lg">
              <div className="flex items-center gap-6">
                <img src={AbsenceIcon} alt="Absence icon" className="h-8 w-8" />
                <div className="font-normal">
                  <p className="text-sm">Faltas</p>
                  <p className="-mt-2 text-lg">{absent}</p>
                </div>
              </div>
              <div className="flex items-center gap-6">
                <img src={LateIcon} alt="Late icon" className="h-8 w-8" />
                <div className="font-normal">
                  <p className="text-sm">Tardanza</p>
                  <p className="-mt-2 text-lg">{late}</p>
                </div>
              </div>
            </div>
            <p className="text-xs text-gray-400">
              Los datos mostrados son recopilados de la información del mes
              actual.
            </p>
          </div>
          <div className="flex-1 rounded-b-2xl">{calendarComponent}</div>
        </div>
      </div>
    </div>
  );
}
