import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';

import GeneralApi from '../../../utils/generalApi';
import { constants } from '../../../utils/constants';

import { showErrorToast } from '../../../components/Toast';
import LoadingSpinner from '../../../components/LoadingSpinner';
import SolidButton from '../../../components/SolidButton';
import CourseCard from './CourseCard';

import FilterIcon from '../../../resources/img/icons/filter.png';

export default function MyCourses() {
  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);

  const [courses, setCourses] = useState([]);

  const [kindFilter, setKindFilter] = useState('school');
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterValue, setFilterValue] = useState('-');
  const [searchFilter, setSearchFilter] = useState('');

  const filteredCourses = useMemo(() => {
    if (searchFilter !== '') {
      return courses.filter((course) =>
        course.name.name.toLowerCase().includes(searchFilter.toLowerCase())
      );
    }
    return courses;
  }, [courses, searchFilter]);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  useEffect(() => {
    getFilterValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindFilter]);

  let getCourses = async () => {
    setLoading(true);
    let filter = {};
    if (filterValue !== '-') filter[kindFilter] = filterValue;
    const result = await generalApi.post(`/classroom/courses/list`, filter);    
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return;
    }
    const data =  result.data.filter(item => item._id !== '654554e3a3db416b1d89842c')
    console.log(data)
    //setCourses(result.data);
    setCourses(data);
    setLoading(false);
  };

  let getFilterValues = async () => {
    const result = await generalApi.get(`/classroom/${kindFilter}/list`);
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setFilterOptions(result.data);
  };

  return (
    <div className="mx-4 my-8 rounded-2xl bg-white md:mx-16 md:my-12">
      <div className="justify-between px-6 py-10 sm:flex sm:px-12">
        <div className="relative mt-1 w-full rounded-md shadow-sm">
          <input
            id="inp_search_filter"
            name="search_filter"
            type="search"
            placeholder="Buscar programa"
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e.target.value);
            }}
            className="inp-base pr-10"
          ></input>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <svg
              className="h-6 w-6 text-primary"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="px-4 pb-8 md:px-12">
        <div className="flex items-center gap-8">
          <label
            htmlFor="inp_kind_filter"
            className="flex items-center gap-2 text-xl font-bold text-gray-800"
          >
            <img className="h-4 w-4" src={FilterIcon} alt="Filtro" />
            FILTRAR
          </label>

          <div className="h-16 w-0.5 bg-gray-300" />

          <div className="flex items-center gap-4">
            <div>
              <label htmlFor="inp_kind_filter" className="sr-only">
                Filtro
              </label>
              <select
                id="inp_kind_filter"
                name="kind_filter"
                value={kindFilter}
                onChange={(e) => setKindFilter(e.target.value)}
                className="inp-base w-64 rounded-full px-6"
              >
                <option value="school">Proyecto Educativo</option>
                <option value="campus">Sede</option>
                <option value="course_name">Programa</option>
                <option value="classroom">Ciclo</option>
              </select>
            </div>
            <div className="">
              <label htmlFor="inp_filter_value" className="sr-only">
                Valor de filtro
              </label>
              <div>
                <select
                  id="inp_filter_value"
                  name="filter_value"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  className="inp-base w-64 rounded-full px-6"
                >
                  <option value="-">Seleccionar</option>
                  {filterOptions.map((obj) => (
                    <option key={obj._id} value={obj._id}>
                      {obj.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <LoadingSpinner content="Buscando cursos" color="primary" />
        ) : filteredCourses.length === 0 ? (
          <div className="mt-8 text-center">
            <p className="text-lg">No se encontraron cursos</p>
            <SolidButton
              className="mx-auto mt-8 max-w-xs"
              onClick={() => {
                setFilterValue('-');
                setSearchFilter('');
              }}
            >
              MOSTRAR TODOS
            </SolidButton>
          </div>
        ) : (
          <div className="mt-8 grid gap-12 sm:grid-cols-2 2xl:grid-cols-3">
            {filteredCourses.map((obj) => {
              return (
                <CourseCard
                  key={obj._id}
                  id={obj._id}
                  course={obj}
                  turn={
                    obj.classroom_turn
                      ? constants.TURNS[obj.classroom_turn]
                      : obj.classroom_number
                  }
                  api={generalApi}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
