import { Disclosure, Transition } from '@headlessui/react';
import SolidButton from '../../../../components/SolidButton';
import { formatDate } from '../../../../utils/functions';

export default function ExamItem({
  id,
  date,
  description,
  mode,
  kind,
  completed,
  graded,
  result,
  onResultOpen,
}) {
  return (
    <Disclosure as="div" className="mb-4 shadow-md">
      {({ open }) => (
        <>
          <div
            className={`${
              open ? 'rounded-t-xl bg-primary text-white' : 'rounded-xl'
            } items-center px-6 py-3 text-xl text-white transition-all sm:grid sm:grid-cols-4 sm:gap-4`}
          >
            <dt className="text-lg text-white sm:col-span-1">
              FECHA DE EXAMEN
            </dt>
            <dd className="text-lg text-white sm:col-span-2 sm:mt-0">
              {formatDate(date, 'dd/MM/yyyy')}
            </dd>
            <div className="mt-2 flex items-center space-x-4 justify-self-end sm:mt-0">
              <span
                className={`${
                  graded
                    ? 'border-green bg-green-dark'
                    : completed
                    ? 'border-orange bg-orange-dark'
                    : 'border-red-600 bg-red-500'
                } rounded-full border-2 px-4 py-2 text-lg`}
              >
                {graded
                  ? 'COMPLETADO'
                  : completed
                  ? 'POR CALIFICAR'
                  : 'PENDIENTE'}
              </span>
              <Disclosure.Button className="cursor-pointer rounded-full p-1 text-white hover:bg-gray-200 hover:text-light-blue">
                <svg
                  className="h-7 w-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {open ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 15l7-7 7 7"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  )}
                </svg>
              </Disclosure.Button>
            </div>
          </div>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="bg-white text-xl" static>
              <div className="border-b-2 border-gray-300 px-6 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-8">
                <dt className="text-lg text-gray-800">DESCRIPCIÓN</dt>
                <dd className="mt-1 text-lg text-gray-900 sm:col-span-3 sm:mt-0">
                  {description}
                </dd>
              </div>
              <div className="border-b-2 border-gray-300 px-6 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-8">
                <dt className="text-lg text-gray-800">MODALIDAD</dt>
                <dd className="mt-1 text-lg text-gray-900 sm:col-span-3 sm:mt-0">
                  {mode}
                  {mode === 'Alternativas' && completed ? (
                    <span
                      className="mt-1 ml-3 cursor-pointer text-lg text-light-blue underline hover:text-light-blue-light sm:col-span-3 sm:mt-0"
                      onClick={onResultOpen}
                    >
                      Ver respuestas del examen
                    </span>
                  ) : null}
                </dd>
              </div>
              <div className="px-6 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-8">
                <dt className="text-lg text-gray-800">TIPO</dt>
                <dd className="mt-1 text-lg text-gray-900 sm:col-span-3 sm:mt-0">
                  {kind}
                </dd>
              </div>
              {!completed ? (
                <div className="px-6 pb-6">
                  <div className="mx-auto max-w-xs flex-1">
                    <SolidButton href={`/exam/legacy/${id}`}>
                      EMPEZAR EXAMEN
                    </SolidButton>
                  </div>
                </div>
              ) : null}
              {graded && mode === 'Alternativas' ? (
                <div>
                  <dl className="grid grid-cols-1 divide-y-2 divide-gray-300 overflow-hidden border-t-2 border-gray-300 md:grid-cols-4 md:divide-y-0 md:divide-x-2">
                    <div className="px-6 py-5 sm:p-6">
                      <dt className="text-base text-gray-900">CORRECTAS</dt>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-primary">
                          {result.correct}
                        </div>
                      </dd>
                    </div>
                    <div className="px-6 py-5 sm:p-6">
                      <dt className="text-base text-gray-900">INCORRECTAS</dt>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-primary">
                          {result.incorrect}
                        </div>
                      </dd>
                    </div>
                    <div className="px-6 py-5 sm:p-6">
                      <dt className="text-base text-gray-900">SIN RESPONDER</dt>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-primary">
                          {result.empty}
                        </div>
                      </dd>
                    </div>
                    <div className="px-6 py-5 sm:p-6">
                      <dt className="text-base text-gray-900">PUNTAJE TOTAL</dt>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-primary">
                          {result.total} DE{' '}
                          {result.questions.reduce((a, b) => (a += b.value), 0)}
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
              ) : null}
              {graded && mode === 'Desarrollado' ? (
                <div className="border-t-2 border-gray-300 px-6 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-7 ">
                  <dt className="text-lg text-gray-800">NOTA</dt>
                  <dd className="mt-1 text-2xl font-semibold text-primary sm:col-span-3 sm:mt-0">
                    {result.total}
                  </dd>
                </div>
              ) : null}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
