import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from '../../../contexts/Auth';

import { buildCourseName } from '../../../utils/functions';
import StorageService from '../../../utils/storage';
import GeneralApi from '../../../utils/generalApi';

import LoadingSpinner from '../../../components/LoadingSpinner';
import SolidButton from '../../../components/SolidButton';
import { showErrorToast } from '../../../components/Toast';

import pagoEfectivoImg from '../../../resources/img/logos/pago_efectivo.png';
import cardsImg from '../../../resources/img/logos/cards.png';
import bcpImg from '../../../resources/img/logos/bcp.png';

export default function PendingPayment() {
  const history = useHistory();
  const auth = useAuth();

  let { courseId } = useParams();

  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [amount, setAmount] = useState(0);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getPendingPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getPendingPayment = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/course/pending_payments`, {
      course_id: courseId,
      student_id: StorageService.get('chosenStudent'),
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    if (result.data.amount <= 0) {
      history.replace(`/my_courses/${courseId}/report`);
    }
    setAmount(result.data.amount);
    setCourse(result.data.course);
    setLoading(false);
  };

  return (
    <div>
      <div className="border-b-2 border-gray-300 px-6 py-6 sm:px-12">
        <div className="flex items-center">
          <svg
            className="mr-2 h-12 w-12 text-primary group-hover:text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <span className="text-2xl font-light text-primary">
            {!course ? 'Programas' : buildCourseName(course)}
          </span>
        </div>
      </div>
      <div className="max-w-4xl px-4 py-10 md:px-12">
        <div className="border border-red-500 bg-white p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <svg
                className="h-8 w-8 text-red-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
            </div>
            <div className="ml-3 flex-1 pt-2">
              <p className="text-lg leading-5 text-red-500">PAGO PENDIENTE</p>
            </div>
          </div>
          <p className="mt-3">Tiene un pago pendiente de S/ {amount}</p>
        </div>
        <p className="mt-8 text-lg leading-5">FORMAS DE PAGO:</p>
        <div className="mt-4 border bg-white px-6 py-8 shadow-md">
          <p className="text-lg font-bold">Tarjetas de crédito y débito</p>
          <img
            className="mt-2 block w-auto sm:h-16"
            src={cardsImg}
            alt="Tarjetas de Crédito"
          ></img>
        </div>
        <div className="mt-4 border bg-white px-6 py-8 shadow-md">
          <p className="text-lg font-bold">
            Pago en Agencias y Banca por Internet
          </p>
          <div className="mt-2 items-center space-x-4 space-y-4 sm:flex sm:space-y-0">
            <img
              className="block h-16 w-auto"
              src={bcpImg}
              alt="Tarjetas de Crédito"
            ></img>
            <img
              className="block h-12 w-auto"
              src={pagoEfectivoImg}
              alt="Tarjetas de Crédito"
            ></img>
          </div>
          <p className="mt-4 text-xl">Cuenta corriente BCP: 191 2664764 006</p>
          <p className="mt-1 text-xl">
            Cuenta corriente interbancaria BCP: 00219100 266476400654
          </p>
          <p className="mt-1 text-xl">Yape: 992 7613 37</p>
        </div>

        <div className="my-6 flex justify-end space-x-4">
          <div className="w-full sm:w-1/2">
            {loading || amount <= 0 ? (
              <LoadingSpinner color="primary" />
            ) : (
              <SolidButton href={`/shopping_cart/${courseId}`}>
                CONTINUAR
              </SolidButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
