import axios from 'axios';
import { constants } from './constants';
import StorageService from './storage';

const instance = axios.create();

instance.interceptors.request.use(
  function (config) {
    if (StorageService.get('accessToken') != null)
      config.headers.common['authorization'] = `Bearer ${
        StorageService.get('accessToken') || ''
      }`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 403) {
      if (!StorageService.get('isAuthenticated')) return Promise.reject(error);
      try {
        const result = await axios.post(`${constants.API_URL}/users/refresh`, {
          _id: StorageService.get('userData')?._id,
          refreshToken: StorageService.get('refreshToken'),
        });
        StorageService.set('accessToken', result.data.accessToken);
        StorageService.set('refreshToken', result.data.refreshToken);
        if (error.config.data)
          error.config.data = JSON.parse(error.config.data);
        error.config.headers[
          'authorization'
        ] = `Bearer ${result.data.accessToken}`;
        return instance.request(error.config);
      } catch (err) {
        console.log('Refresh token error:', err);
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
