import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';

import GeneralApi from '../../../utils/generalApi';
import { showErrorToast } from '../../../components/Toast';

import AttendanceReport from './AttendanceReport';
import CourseContent from './CourseContent';
import GradesReport from './GradesReport';

export default function CourseReport() {
  let { courseId } = useParams();

  const history = useHistory();
  const auth = useAuth();

  const [reportKindFilter, setReportKindFilter] = useState('0');
  const [courseName, setCourseName] = useState('-');

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourseName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getCourseName = async () => {
    const result = await generalApi.post(`/classroom/course/name`, {
      course: courseId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setCourseName(result.data.name);
  };

  return (
    <div>
      <div className="justify-between space-y-4 border-b-2 border-gray-300 px-6 py-6 sm:flex sm:space-y-0 sm:px-12">
        <div className="flex items-center">
          <svg
            className="mr-2 h-12 w-12 text-primary group-hover:text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 14l9-5-9-5-9 5 9 5z" />
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            />
          </svg>
          <span className="text-2xl font-light text-primary">
            Información académica
          </span>
        </div>
        <div className="flex max-w-sm flex-1 items-center">
          <label
            htmlFor="inp_report_kind_filter"
            className="text-lg text-gray-600"
          >
            Selecciona:
          </label>
          <div className="ml-4 flex-1">
            <select
              id="inp_report_kind_filter"
              name="report_kind_filter"
              value={reportKindFilter}
              onChange={(e) => setReportKindFilter(e.target.value)}
              className="inp-base w-full"
            >
              <option value="0">Notas</option>
              <option value="1">Asistencia</option>
              <option value="2">Recursos Académicos</option>
            </select>
          </div>
        </div>
      </div>
      {reportKindFilter === '0' ? (
        <GradesReport course={courseId} courseName={courseName}></GradesReport>
      ) : reportKindFilter === '1' ? (
        <AttendanceReport
          course={courseId}
          courseName={courseName}
        ></AttendanceReport>
      ) : (
        <CourseContent
          course={courseId}
          courseName={courseName}
        ></CourseContent>
      )}
    </div>
  );
}
