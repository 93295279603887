import { useHistory } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Editor } from '@tinymce/tinymce-react';
import { useAuth } from '../../contexts/Auth';

import { validateFile } from '../../utils/fileUtils';
import { constants } from '../../utils/constants';
import StorageService from '../../utils/storage';
import GeneralApi from '../../utils/generalApi';

import { showErrorToast, showSuccessToast } from '../../components/Toast';
import OutlineButton from '../../components/OutlineButton';
import SolidButton from '../../components/SolidButton';

export default function CreateQuestion() {
  const history = useHistory();
  const auth = useAuth();
  const editorRef = useRef(null);

  let { courseId } = useParams();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [topic, setTopic] = useState('-');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const generalApi = new GeneralApi(auth, history);

  let checkImage = async (event) => {
    let isValid = validateFile(event.target, 'img');
    if (!isValid) {
      setImage(null);
      setImagePreview(null);
      showErrorToast(
        'Formato de imagen no válido, debe ser un archivo .png, .jpg o .jpeg'
      );
      return;
    }
    setImage(event.target.files[0]);
    var reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    await reader.readAsDataURL(event.target.files[0]);
  };

  let validateQuestion = () => {
    if (topic === '-') return 'Seleccione el curso de su pregunta';
  };

  let submitQuestion = async () => {
    let error = validateQuestion();
    if (error !== undefined) return showErrorToast(error);

    let questionData = new FormData();

    questionData.append('student_id', StorageService.get('chosenStudent'));
    questionData.append('course_id', courseId);
    questionData.append('content', editorRef.current.getContent());
    questionData.append('topic', topic);
    if (image !== null) questionData.append('image', image);

    setLoading(true);
    const result = await generalApi.post(`/classroom/question`, questionData);
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    showSuccessToast('Pregunta creada!');
    history.replace(
      `/forum/${courseId}/question/${result.data.question}/details`
    );
  };

  return (
    <div>
      <div className="top-0 justify-between space-y-4 border-b-2 border-gray-300 bg-gray-body px-6 py-6 sm:sticky sm:flex sm:space-y-0 sm:px-12">
        <div className="flex items-center">
          <svg
            className="mr-2 h-12 w-12 text-primary group-hover:text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            />
          </svg>
          <span className="text-2xl font-light text-primary">Foro</span>
        </div>
        <div className="flex max-w-xs items-center">
          <SolidButton href={`/forum/${courseId}`}>FORO ACADÉMICO</SolidButton>
        </div>
      </div>
      <div className="px-4 py-10 md:px-12">
        <p className="text-2xl">Crear Pregunta</p>
        <div className="mt-4 bg-white shadow-lg">
          <div className="border-b-2 border-gray-300 px-8 py-6">
            <div className="mb-6 items-start space-y-4 sm:flex sm:justify-between sm:space-y-0 sm:space-x-12">
              <div className="flex max-w-sm flex-1 items-center">
                <label
                  htmlFor="inp_report_kind_filter"
                  className="text-lg text-gray-600"
                >
                  Selecciona:
                </label>
                <div className="ml-4 w-40 flex-1">
                  <select
                    id="inp_report_kind_filter"
                    name="report_kind_filter"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    className="inp-base w-full"
                  >
                    <option value="-">Elegir curso</option>
                    <option>Aritmética</option>
                    <option>Álgebra</option>
                    <option>Geometría</option>
                    <option>Trigonometría</option>
                    <option>Física</option>
                    <option>Química</option>
                    <option>Biología</option>
                    <option>Raz. Matemático</option>
                    <option>Raz. Verbal</option>
                    <option>Historia</option>
                    <option>Psicología</option>
                    <option>Cívica</option>
                    <option>Economía</option>
                    <option>Lenguaje</option>
                    <option>Literatura</option>
                    <option>Filosofía</option>
                    <option>Geografía</option>
                    <option>Inglés</option>
                    <option>Historia Universal</option>
                    <option>Historia del Perú</option>
                    <option>Anatomía</option>
                    <option>Hab. Verbal</option>
                    <option>Hab. Lógico Matemático</option>
                    <option>Estadística</option>
                    <option>Números y Operaciones</option>
                    <option>Vocabulario</option>
                    <option>Lectura Comprensiva e Interpretativa</option>
                    <option>Lectura Critica</option>
                    <option>Ortografía y Puntuación</option>
                    <option>Matemáticas</option>
                    <option>Ciencias</option>
                    <option>Humanidades</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col items-end space-y-2">
                <div className="flex max-w-xs items-center">
                  <OutlineButton
                    color="light-blue"
                    htmlFor="image_file"
                    className="cursor-pointer"
                  >
                    <svg
                      className="text-blue mr-2 h-7 w-7 group-hover:text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    {image ? 'CAMBIAR IMAGEN' : 'SUBIR IMAGEN'}
                  </OutlineButton>
                  <input
                    id="image_file"
                    className="hidden"
                    type="file"
                    name="image"
                    accept=".png, .jpg, .jpeg"
                    onChange={checkImage}
                  />
                </div>
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    className="h-24 max-w-full rounded-lg border-2 border-light-blue sm:h-36 xl:max-w-lg"
                    alt="Imagen de la pregunta"
                  ></img>
                ) : null}
              </div>
            </div>
            <div>
              <label htmlFor="inp_title" className="sr-only">
                Título
              </label>
              <input
                id="inp_title"
                name="title"
                type="text"
                placeholder="Título"
                value={title}
                autoFocus
                onChange={(e) => setTitle(e.target.value)}
                className="inp-base mb-6 max-w-sm"
              ></input>
            </div>
            <Editor
              apiKey="8com3pmw9v05b20vk3t04cgt6hxoi372hxj01v155q05grdk"
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={constants.TINY_INIT}
            />
            <div className="my-6 justify-end space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <div className="flex max-w-xs items-center">
                <SolidButton
                  className="px-12"
                  onClick={submitQuestion}
                  isLoading={loading}
                >
                  CONFIRMAR
                </SolidButton>
              </div>
              <div className="flex max-w-xs items-center">
                <OutlineButton
                  className="px-12"
                  color="gray"
                  disabled={loading}
                  href={`/forum/${courseId}`}
                >
                  CANCELAR
                </OutlineButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
