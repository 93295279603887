import { Disclosure, Transition } from '@headlessui/react';
import SolidButton from '../../../../components/SolidButton';
import { formatDate } from '../../../../utils/functions';

import DateIcon from '../../../../resources/img/icons/date.png';
import TimeIcon from '../../../../resources/img/icons/time.png';
import SchoolIcon from '../../../../resources/img/icons/school.png';
import KindIcon from '../../../../resources/img/icons/kind.png';
import DocsIcon from '../../../../resources/img/icons/docs.png';
import BookIcon from '../../../../resources/img/icons/book.png';

export default function ExamResultItem({ id, exam, course, defaultOpen }) {
  return (
    <Disclosure
      as="div"
      className="mt-4 rounded-xl border border-gray-300"
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <div
            className={`${
              open ? 'rounded-t-xl bg-primary text-white' : 'rounded-xl'
            } flex flex-col items-center justify-between px-6 py-3 text-xl transition-all sm:gap-4 lg:flex-row`}
          >
            <div className="flex items-center gap-4">
              <div className="col-span-3 flex-1 text-center text-xl font-normal">
                {exam.title}
              </div>
              <span className="rounded-full border-2 border-light-blue bg-light-blue px-8 py-1 text-lg font-normal text-white">
                Detalle
              </span>
            </div>
            <div className="mt-2 flex items-center space-x-4 justify-self-end sm:mt-0">
              <p>{formatDate(exam.exam_date, 'dd/MM/yyyy')}</p>
              <Disclosure.Button className="cursor-pointer rounded-full p-1 hover:bg-gray-200 hover:text-light-blue">
                <svg
                  className="h-7 w-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {open ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 15l7-7 7 7"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  )}
                </svg>
              </Disclosure.Button>
            </div>
          </div>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="rounded-b-xl bg-white" static>
              <div className="flex flex-wrap items-center gap-8 px-6 py-6">
                <div className="flex flex-1 flex-wrap justify-around gap-8">
                  <div>
                    <img src={DateIcon} alt="Date icon" className="h-6" />
                    <p className="mt-1 font-normal">Fecha</p>
                    <p>{formatDate(exam.exam_date, 'dd/MM/yyyy')}</p>
                  </div>
                  <div>
                    <img src={TimeIcon} alt="Time icon" className="h-6" />
                    <p className="mt-1 font-normal">Horario</p>
                    <p>{course?.schedule?.name}</p>
                  </div>
                  <div>
                    <img src={SchoolIcon} alt="School icon" className="h-6" />
                    <p className="mt-1 font-normal">Proyecto</p>
                    <p>{course?.school?.name}</p>
                  </div>
                  <div>
                    <img src={KindIcon} alt="Kind icon" className="h-6" />
                    <p className="mt-1 font-normal">Evaluación</p>
                    <p>{exam.kind}</p>
                  </div>
                  <div>
                    <img src={DocsIcon} alt="Docs icon" className="h-6" />
                    <p className="mt-1 font-normal">Programa</p>
                    <p>{course?.name?.name}</p>
                  </div>
                  <div>
                    <img src={BookIcon} alt="Book icon" className="h-6" />
                    <p className="mt-1 font-normal">Ciclo</p>
                    <p>{course?.classroom?.name}</p>
                  </div>
                </div>

                <div>
                  <SolidButton
                    className="rounded-full px-8"
                    href={`/my_courses/${exam.course}/grades/${id}`}
                  >
                    ESTADÍSTICA
                  </SolidButton>
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
