import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth } from '../../contexts/Auth';

import background from '../../resources/img/backgrounds/signup.png';
import loginShape from '../../resources/img/backgrounds/login_shape.svg';
import loginShape2 from '../../resources/img/backgrounds/login_shape_2.svg';

import SolidButton from '../../components/SolidButton';
import { showErrorToast, showSuccessToast } from '../../components/Toast';
import GeneralApi from '../../utils/generalApi';

export default function RecoverPassword() {
  let { userId, code } = useParams();

  let history = useHistory();
  let auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const generalApi = new GeneralApi(auth, history);

  let submitRecoverPassword = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/recover/password`, {
      new_password: password,
      user_id: userId,
      password_code: code,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    showSuccessToast('Contraseña cambiada! Inicie sesión por favor.');
    history.replace('/login/');
  };

  return (
    <div
      className="justify-top relative flex min-h-screen flex-col bg-cover"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div
        className="absolute top-0 left-0 z-0 hidden h-full w-full max-w-2xl bg-no-repeat md:block lg:max-w-3xl xl:max-w-5xl"
        style={{ backgroundImage: `url(${loginShape})` }}
      />
      <div
        className="absolute bottom-0 right-0 z-0 hidden h-full w-full max-w-xl bg-right bg-no-repeat md:block"
        style={{ backgroundImage: `url(${loginShape2})` }}
      />
      <div className="z-10 py-20 sm:mx-auto sm:w-full sm:max-w-lg sm:px-2">
        <div className="bg-white px-4 py-11 shadow sm:rounded-2xl sm:px-12">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-2xl font-light text-primary">
              CAMBIAR CONTRASEÑA
            </h2>
          </div>

          <div className="mt-8 text-center">
            <span className="justify-center text-center text-xl font-light">
              Ingrese su nueva contraseña para recuperar el acceso a su cuenta.
            </span>
          </div>
          <div className="mt-8">
            <div>
              <label htmlFor="inp_password" className="sr-only">
                Nueva Contraseña
              </label>
              <div className="mt-1">
                <input
                  id="inp_password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Nueva Contraseña"
                  value={password}
                  autoFocus
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') submitRecoverPassword();
                  }}
                  className="inp-base"
                ></input>
              </div>
            </div>

            <div className="mt-8">
              <SolidButton onClick={submitRecoverPassword} isLoading={loading}>
                CONFIRMAR
              </SolidButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
