import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import GeneralApi from '../../../../utils/generalApi';
import { useAuth } from '../../../../contexts/Auth';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import OutlineButton from '../../../../components/OutlineButton';
import { showErrorToast } from '../../../../components/Toast';
import ContentCard from './ContentCard';

import ResourcesIcon from '../../../../resources/img/icons/folder.png';

export default function CourseContent() {
  let { courseId } = useParams();

  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [courseLoading, setCourseLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [resources, setResources] = useState([]);

  // const [weekFilter, setWeekFilter] = useState('1');
  // const [dateFilter, setDateFilter] = useState('');

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getCourseData();
    getResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getCourseData = async () => {
    setCourseLoading(true);
    const result = await generalApi.post(`/classroom/course/details`, {
      course_id: courseId,
    });
    if (!result.success) {
      setCourseLoading(false);
      showErrorToast(result.message);
      if (result?.data?.redirect)
        history.replace(`/my_courses/${courseId}/home`);
      return;
    }
    setCourse(result.data.course);
    setCourseLoading(false);
  };

  let getResources = async () => {
    setLoading(true);
    const result = await generalApi.post(`/classroom/resources/list`, {
      course: courseId,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return;
    }
    setLoading(false);
    setResources(result.data);
  };

  return (
    <div className="mx-4 my-8 md:mx-16 md:my-12">
      <div className="rounded-2xl bg-white">
        <div className="flex flex-col justify-between gap-4 border-b border-gray-300 p-6 lg:flex-row lg:items-center xl:px-12 xl:py-6">
          {courseLoading && (
            <div className="flex items-center gap-4">
              <div className="h-12 w-12 animate-pulse rounded-full bg-gray-300" />
              <div className="flex flex-col gap-2">
                <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300" />
                <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300" />
              </div>
            </div>
          )}

          {course?.school?.name && (
            <div className="flex flex-col items-center gap-3 lg:flex-row">
              <div className="flex items-center gap-4">
                <img
                  src={ResourcesIcon}
                  className="h-12 bg-no-repeat sm:h-12"
                  alt="Logo"
                />
                <p className="text-2xl text-primary-light">
                  {course?.name?.name || ''} - {course?.classroom?.name || ''}
                </p>
              </div>
              <p className="text-2xl font-bold text-primary-light">
                / Recursos académicos
              </p>
            </div>
          )}

          <OutlineButton
            className="w-min font-light"
            href={`/my_courses/${courseId}/home`}
          >
            Regresar
          </OutlineButton>
        </div>

        <div className="p-8 xl:px-12">
          {/* <div className="mb-8 flex items-center gap-8">
            <label
              htmlFor="inp_week"
              className="flex items-center gap-2 text-xl font-bold text-gray-800"
            >
              <img className="h-4 w-4" src={FilterIcon} alt="Filtro" />
              FILTRAR
            </label>

            <div className="h-16 w-0.5 bg-gray-300" />

            <div className="flex items-center gap-4">
              <div>
                <label htmlFor="inp_week" className="sr-only">
                  Semanas
                </label>
                <select
                  id="inp_week"
                  name="week"
                  value={weekFilter}
                  onChange={(e) => setWeekFilter(e.target.value)}
                  className="inp-base w-64 rounded-full px-6"
                >
                  <option value="1">Semana 1</option>
                  <option value="2">Semana 2</option>
                </select>
              </div>
              <div className="">
                <label htmlFor="inp_date" className="sr-only">
                  Fecha
                </label>
                <div>
                  <input
                    id="inp_date"
                    name="date"
                    type="date"
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                    className="inp-base w-64 rounded-full px-6"
                  />
                </div>
              </div>
            </div>
          </div> */}

          {loading ? (
            <LoadingSpinner content="Buscando recursos" color="primary" />
          ) : resources.length === 0 ? (
            <p>No se encontraron recursos</p>
          ) : (
            resources.map((obj, index) => (
              <ContentCard
                key={obj._id}
                openAtStart={index === 0}
                courseDate={course?.start_date}
                date={obj.date}
                description={obj.description}
                attachments={[
                  obj.attachment_url
                    ? {
                        name:
                          'Archivo 1.' +
                          obj.attachment_url.split('.')[
                            obj.attachment_url.split('.').length - 1
                          ],
                        url: obj.attachment_url,
                      }
                    : null,
                  ...obj.attachments,
                ].filter((obj) => obj !== null && obj.name)}
                links={obj.links.filter((obj) => obj !== '')}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
